import React, {Component} from "react";
import {connect} from 'react-redux';
import Carousel from 'nuka-carousel';
import {Link, withRouter} from 'react-router-dom'

import {TwoImagesWithLabel} from "components";
import {contentConstants, defaultConstants} from "_constants";
import {contentActions} from "../../_actions";

class TwoImagesWithLabelCarousel extends Component {
    componentDidMount() {
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if (!componentContentReady) {
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent, collectionsIndexes, indexesItems, pages, currentLanguage} = this.props;

        const defaultSpeed = 600;

        const options = {
            //Margin Between Slides. Converts to 'px'
            cellSpacing: 20,
            // Enable Prev and Next Slide to be shown
            frameOverflow: 'hidden',
            // Sets width of Center Slide
            width: '86%',
            // Transition Speed:
            speed: componentContent && Number.isInteger(componentContent.speed) ? componentContent.speed : defaultSpeed,
            // Transition between slides. Choose from here - https://github.com/d3/d3-ease
            easing: 'easeLinear',
            // First slide to show
            // slideIndex: 1,
            // Infinite Loop through slides
            wrapAround: false,
            autoplay: false,
        };

        let linkedCollectionItems = [], linkedCollectionItemsContent = [];

        if (
            componentContent &&
            componentContent.collection_indexes &&
            Array.isArray(componentContent.collection_indexes) &&
            indexesItems) {
            const collectionIndexes = componentContent.collection_indexes.map(collectionIndex => (
                collectionsIndexes[collectionIndex.collection.id]
            ));

            for (const collectionIndexContent of collectionIndexes) {
                if (collectionIndexContent && collectionIndexContent[contentConstants.SORT_TYPE_ITEMS]) {
                    linkedCollectionItems = [
                        ...linkedCollectionItems,
                        ...collectionIndexContent[contentConstants.SORT_TYPE_ITEMS]
                    ]
                }
            }

            linkedCollectionItemsContent = Array.isArray(linkedCollectionItems) &&
                linkedCollectionItems.map(item => {
                    return (
                        indexesItems[item.id]
                    )
                })
        }

        return (
            (componentContentReady && componentContent && pages[componentContent.link.id]) ?
                <section className={`two-images-with-label-carousel-section wide`}
                         style={{
                             backgroundColor: componentContent.background_color
                         }}>
                    {componentContent.background_color ?
                        <div className={'two-images-with-label-carousel-hide-background'}/> : ''}
                    <h3 className={'section-title'}>{componentContent.headline}</h3>

                    <Carousel {...options}
                              className={'two-images-with-label-carousel'}
                        /* Disable Paging Dots on Slide Bottom */
                              renderBottomCenterControls={() => null}
                        /* Change to custom arrow buttons */
                              renderCenterLeftControls={({previousSlide, currentSlide}) => (
                                  currentSlide !== 0 ?
                                      <i onClick={previousSlide} className={'arrow left'}/>
                                      : ''
                              )}
                              renderCenterRightControls={({nextSlide, slideCount, currentSlide}) => (
                                  currentSlide !== slideCount - 1 ?
                                      <i onClick={nextSlide} className={'arrow right'}/>
                                      : ''
                              )}>
                        {
                            linkedCollectionItemsContent
                                .filter(slide => (
                                    slide &&
                                    slide.id &&
                                    Array.isArray(slide.two_images_with_label) &&
                                    slide.two_images_with_label.length > 0))
                                .map(slide => {
                                    return (
                                        <TwoImagesWithLabel content={slide}
                                                            key={slide.id}
                                                            parentComponent={'carousel'}
                                                            parentPageLink={pages[componentContent.link.id].meta.slug}
                                        />
                                    )
                                })}
                    </Carousel>

                    {componentContent.link &&
                    <div
                        style={{marginBottom: !componentContent.background_color && 0}}
                        className={`two-images-with-label-link-container`}>
                        <Link
                            className={`two-images-with-label-link ${componentContent.background_color ? 'text-light' : 'text-color'}`}
                            to={`${defaultConstants.defaultStartPath}${currentLanguage}/${pages[componentContent.link.id].meta.slug}`}>{componentContent.link_label}</Link>
                    </div>}
                </section>
                : ''
        )
    }
}

function mapStateToProps({content, settings}) {
    const {collectionsIndexes, indexesItems, pages} = content;
    const {locale} = settings;
    return {
        collectionsIndexes,
        indexesItems,
        pages,
        currentLanguage: locale
    }
}

const connectedPage = withRouter(connect(mapStateToProps)(TwoImagesWithLabelCarousel));
export {connectedPage as TwoImagesWithLabelCarousel};
