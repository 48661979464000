let domain;

if (process.env.NODE_ENV === 'development') {
    domain = process.env.REACT_APP_THEWOM_API_ENDPOINT;
} else {
    domain = process.env.REACT_APP_THEWOM_API_ENDPOINT
}

export const generalConstants = {
    API_ENDPOINT: `${domain}/api/`,
    API_IMAGES_ENDPOINT: `${domain}/api/images/`,
    API_PAGES_POSTFIX: 'pages/',
    DOMAIN: domain,
    MEDIA_ENDPOINT: `${domain}`,
    STATIC_ENDPOINT: `${domain}`,
    DEFAULT_LANGUAGE: 'en',
    USER_AGENT:  'THE_WOM_MOBILE_APP'
};
