import React, {Component} from "react";
import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";

import {converter} from "_helpers";
import {contentActions} from "../../_actions";

class WOMs extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    getLinkPageSlug(){
        const {pages, componentContent} = this.props;

        const linkPageId = componentContent && 
                    componentContent['link_page'] && 
                    componentContent['link_page']['id'];

        return pages && pages[linkPageId] && pages[linkPageId]['meta']['slug'];
    }

    render() {
        const {componentContentReady, componentContent, match} = this.props;

        return (
            <section className={'woms wide d-flex flex-wrap justify-content-start align-items-center'}>
                {componentContentReady && componentContent ?
                    <div className={'woms-content d-flex flex-wrap justify-content-center'}>
                        <div className={'hide-background'}/>
                        <div className={'woms-headline'}>
                            <h2 className={'section-title m-0'}>{componentContent.headline_h1}</h2>
                        </div>
                        <div className={'woms-section left'}
                             style={{backgroundColor: componentContent.background_color}}>
                            <div className={'woms-explanation d-flex flex-column align-items-start'}>
                                <div className={'ordered-list'}>
                                    {
                                        Array.isArray(componentContent.woms_list_item) &&
                                        componentContent.woms_list_item.map((item, index) => {
                                        return (
                                            <div
                                                className={'single-number-text d-flex justify-content-start align-items-baseline'}
                                                key={index}>
                                                <div className={'number-text-number'}>{item.number}</div>
                                                <div className={'number-text-text'}>{item.text}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className={'public-spaces'}>
                                    <p className={'public-title'}>{componentContent.headline_h2}</p>
                                    <p className={'public-text'}>{componentContent.paragraph}</p>
                                </div>
                                <Link
                                                        className={'learn-more-link'}
                                                        to={`${match.url}/${this.getLinkPageSlug()}`}>
                                                        {componentContent.link_text}
                                </Link>
                            </div>
                        </div>
                        <div className={'woms-section right d-flex'}
                             style={{
                                 backgroundImage: `url(${converter.getImageDownloadURL(componentContent.background_image)})`
                             }}>
                            {/*<div className={'image-container d-flex justify-content-end align-items-center'}>*/}
                                <div className={'image'}
                                     style={{backgroundImage: `url(${converter.getImageDownloadURL(componentContent.woms_image)})`}}/>
                            {/*</div>*/}
                        </div>
                    </div>
                    :
                    ''
                }
            </section>
        );
    }
}

function mapStateToProps({content}) {
    return {
        pages: content && content['pages']
    }
}

const connectedPage = withRouter(connect(mapStateToProps)(WOMs));
export {connectedPage as WOMs};
