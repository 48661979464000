// /*global google*/

import React from 'react';
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";

export const GoogleMapsContainer = withScriptjs(withGoogleMap(props => {
    const {hotelMarker, centerOffset} = props;

    return (
        <GoogleMap
            defaultZoom={20}
            defaultCenter={
                {
                lng: hotelMarker.location.lng + centerOffset,
                lat: hotelMarker.location.lat
                }
            }
            defaultClickableIcons={false}
            clickableIcons={false}

            defaultOptions={{
                disableDefaultUI: true, // disable default map UI
                draggable: false, // make map draggable
                keyboardShortcuts: false, // disable keyboard shortcuts
                scaleControl: false, // allow scale controle
                scrollwheel: false, // allow scroll wheel
                clickableIcons: false,
                styles: [{
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {visability: 'off'}
                    ]
                }] // change default map styles
            }}
        >
            <Marker position={hotelMarker.location}
                    // defaultIcon={{url: hotelMarker.iconURL, scaledSize: new google.maps.Size(40, 61.5)}}
            />
        </GoogleMap>
    )
}));