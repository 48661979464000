import React, {Component} from "react";
import {connect} from 'react-redux';
import {contentActions} from "../../_actions";

class FAQ extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        return componentContentReady && componentContent ? (
                <section className={'the-wom-faq align-items-start d-flex flex-column'}>
                    {Array.isArray(componentContent.faq_items) && componentContent.faq_items.map(faqItem => (
                        <div className={'single-faq'}>
                            <div className={'faq-question-container d-flex flex-wrap'}>
                                <input id={`faq-arrow-${faqItem.id}`} type={'checkbox'} className={'faq-arrow-input'}/>
                                <label htmlFor={`faq-arrow-${faqItem.id}`} className={'faq-label d-flex align-items-center'}>
                                    <div className={'question section-subtitle'}>{faqItem.question}</div>
                                    <div className={'faq-arrow'}/>
                                </label>
                                <div className={'faq-answer paragraph'}>
                                    {faqItem.answer}
                                </div>
                            </div>

                        </div>
                    ))}
                </section>
            )
            : ''
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = connect(mapStateToProps)(FAQ);
export {connectedPage as FAQ};
