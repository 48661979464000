export const defaultConstants = {
    defaultMenuType: 'menu-sub-hidden', //'menu-default', 'menu-hidden'
    defaultStartPath: '/',
    defaultHomeSlug: 'home',
    subHiddenBreakpoint: 440,
    menuHiddenBreakpoint: 768,
    defaultLocale: 'en',
};


export const localeOptions = [
    {id: 'en', name: 'English'},
    // {id: 'es', name: 'Español'},
];

