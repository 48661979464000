import {settingsConstants} from "../_constants";

export const settingsActions = {
    changeLocale,
}

function changeLocale(languageCode) {
    return {
        type: settingsConstants.CHANGE_LOCALE,
        languageCode
    }
}