export const tempConstants = {
    INSTAGRAM_API_ENDPOINT: 'https://api.instagram.com/v1/users/self/media/recent',
    INSTAGRAM_NUM_TO_FETCH: 6,

    GET_INSTAGRAM_PHOTOS_REQUEST: 'GET_INSTAGRAM_PHOTOS_REQUEST',
    GET_INSTAGRAM_PHOTOS_SUCCESS: 'GET_INSTAGRAM_PHOTOS_SUCCESS',
    GET_INSTAGRAM_PHOTOS_FAILURE: 'GET_INSTAGRAM_PHOTOS_FAILURE',

    CLEAR_INSTAGRAM_PHOTOS_PER_KEY: 'CLEAR_INSTAGRAM_PHOTOS_PER_KEY'
};
