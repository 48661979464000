import React, {Component} from "react";
import {connect} from 'react-redux';

class ArticleParagraph extends Component {
    render() {
        const {content} = this.props;

        return (
            content ?
                <section className='article-paragraph collection-item-center'>
                    <div dangerouslySetInnerHTML={{__html: content}} className='article-paragraph-content paragraph'/>
                </section>
                : ''
        );
    }
}

function mapStateToProps({content}) {
    const {components, collectionsIndexes, indexesItems} = content;
    return {
        components,
        collectionsIndexes,
        indexesItems,
    }
}

const connectedPage = connect(mapStateToProps)(ArticleParagraph);
export {connectedPage as ArticleParagraph};




