import React, {Component} from "react";
import {connect} from 'react-redux';

import {CollectionGalleryItem} from "./CollectionGalleryItem";
import {contentConstants} from "../../_constants";
import {generalHelpers} from "../../_helpers";

class CollectionGallery extends Component {
    render() {
        const {collectionsIndexes, indexesItems, componentContent, navAnchor} = this.props;

        let collectionItems = [];

        if (componentContent) {
            if (collectionsIndexes[componentContent.collection.id]) {
                collectionItems = collectionsIndexes[componentContent.collection.id][contentConstants.SORT_TYPE_ITEMS];
            }
        }

        return (
            componentContent && collectionsIndexes[componentContent.collection.id] && collectionItems ?
                <section
                    // id={`page-item-${componentContent.collection.slug || componentContent.collection.meta.slug || componentContent.collection.id}`}
                    id={`page-item-${navAnchor}`}
                    className={'collection-gallery-section center'}>
                    {componentContent.gallery_headline ?
                        <h3 className={'collection-gallery-headline section-title'}>
                            {componentContent.gallery_headline}
                        </h3>
                        :
                        ''
                    }
                    <div className={'collection-gallery d-flex flex-wrap justify-content-center'}>
                        {collectionItems.map(item => {
                            generalHelpers.print("CollectionGallery item", item);
                            return (
                                <CollectionGalleryItem
                                    componentId={item.id}
                                    componentContent={indexesItems[item.id]}
                                    key={item.id}
                                    componentType={
                                        indexesItems[item.id] &&
                                        indexesItems[item.id][contentConstants.CMS_ITEM_TYPE]
                                    }
                                    componentContentReady={
                                        indexesItems[item.id] &&
                                        indexesItems[item.id][contentConstants.COMPONENT_CONTENT_READY]
                                    }
                                />
                            )
                        })}
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({content}) {
    const {components, collectionsIndexes, indexesItems} = content;
    return {
        components,
        collectionsIndexes,
        indexesItems,
    }
}

const connectedPage = connect(mapStateToProps)(CollectionGallery);
export {connectedPage as CollectionGallery};




