import React, {Component} from "react";
import {connect} from 'react-redux';

import {converter as converters} from '_helpers'

class DetailsHover extends Component {
    render() {
        const {content} = this.props;

        return (
            content ?
            <div className={'details-hover'}
                 style={{backgroundImage: `url(${converters.getImageDownloadURL(content.background_image)})`}}>
                <div className={'label'}>
                    <h4 className={'label-title'}>{content.headline}</h4>
                </div>
                <div className={'details'}>
                    <h4 className={'details-title label-title'}>{content.headline}</h4>
                    <div className={'details-paragraph paragraph'}>{content.details_text}</div>
                </div>
            </div>
                : ''
        );
    }
}

function mapStateToProps() {
    return {}
}

const connectedPage = connect(mapStateToProps)(DetailsHover);
export {connectedPage as DetailsHover};
