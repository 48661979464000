import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';

import {converter as converters} from "_helpers";

import {BackgroundSlider} from "./BackgroundSlider";
import {SimplexContainer} from "../SimplexContainer";
import {contentActions} from "../../_actions";
import {VideoPlayer} from '../VideoPlayer'


class HeaderHome extends Component {
    componentDidMount() {
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if (!componentContentReady) {
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent, componentId} = this.props;

        return (
            <header className={'header-home'} id={`header-home-container-${componentId}`}>
                {componentContentReady && componentContent
                    ?
                    <Fragment>
                        <div className='background-container'>
                            {
                                componentContent.background_video_link
                                    ?
                                    // This weird nested divs are for background video cover
                                    <div className="video-bg cover">
                                        <div className="video-fg">
                                            <VideoPlayer url={componentContent.background_video_link}/>
                                            <div style={{
                                                width: "100%",
                                                height: "100%",
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                            }}/>
                                        </div>
                                    </div>
                                    :
                                    Array.isArray(componentContent.header_home_media) &&
                                    componentContent.header_home_media.length > 0
                                        ? <div className={'background-slider'}>
                                            <BackgroundSlider
                                                transitionTimeout={componentContent.transition_timeout_in_seconds}
                                                content={componentContent.header_home_media}/>
                                        </div>
                                        : <div className={'background-image'}
                                               style={{
                                                   backgroundImage:
                                                       `url(${converters.getImageDownloadURL(componentContent.background)})`
                                               }}/>
                            }
                            <div className='logo-and-subtitle d-flex flex-column align-items-center'>
                                <div className={'logo'}
                                     style={{
                                         backgroundImage:
                                             `url(${converters.getImageDownloadURL(componentContent.main_logo)})`
                                     }}/>
                                <h4 className={'subtitle'}>{componentContent.sub_logo}</h4>
                            </div>
                        </div>
                        <SimplexContainer id={"simplex-1"}
                                          className='header-home__simplex-container'
                                          simplex_language={componentContent.simplex_language}
                                          simplex_is_rtl={componentContent.simplex_is_rtl}/>
                    </Fragment>
                    : ''
                }
            </header>
        );
    }
}

function mapStateToProps({}) {
    return {}
}

const connectedPage = connect(mapStateToProps)(HeaderHome);
export {connectedPage as HeaderHome};




