import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';

import {contentService} from '_services';

import {
    CollectionDetails,
    CollectionItemHeader,
    HeadlineWithText,
    ParticipateForm,
    SingleImageCenter,
    SingleImageWide
} from 'components'

class CollectionItem extends Component {
    constructor(props){
        super(props)

        this.state = {
            componentContent: {},
        }
    }
    componentDidMount(){
        const {componentId} = this.props;

        if(componentId){
            contentService.getIndexItemDetailedContent(componentId)
                .then(content => {
                    this.setState({componentContent: content})
                })
                .catch(error => {
                })
        }
    }

    render() {
        const {content, backButtonText} = this.props;
        const {componentContent} = this.state;

        const renderContent = content || componentContent

        return (
            renderContent
                ? <Fragment>
                    <CollectionItemHeader componentContent={renderContent} backButtonText={backButtonText}/>
                    <CollectionDetails componentContent={renderContent}/>
                    <SingleImageCenter image={renderContent.image_center}/>
                    {renderContent.participate_description && <HeadlineWithText content={renderContent.participate_description[0]}/>}
                    <SingleImageWide image={renderContent.image_wide}/>
                    {renderContent.participate_form && <ParticipateForm content={renderContent}/>}
                </Fragment>
                : ''
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = connect(mapStateToProps)(CollectionItem);
export {connectedPage as CollectionItem};




