import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {Navbar, NavbarBrand} from "reactstrap";

import {converter, uiHelpers} from '_helpers';
import {defaultConstants} from "_constants";
import {contentActions, uiActions} from "_actions";

import israelFlag from 'assets/img/israel_flag.svg';
import usaFlag from 'assets/img/usa_flag.svg';

class TopNav extends Component {
    componentDidMount() {
        const {dispatch, permanentId, componentType, componentContentReady, location, currentLanguage} = this.props;

        if (!componentContentReady) {
            dispatch(contentActions.getItemDetailedContent(componentType, permanentId))
        }

        window.onscroll = () => (uiHelpers.scrollNavBarChange(location, currentLanguage));
        // Apply the Home Page Style at first site load
        uiHelpers.scrollNavBarChange(location, currentLanguage);
    }

    scrollNavBarChange = () => {
        const {location, currentLanguage, pages, currentHomePage} = this.props;

        const navContainer = document.querySelector('#thewom-top-nav');

        const isInHomePage = (location.pathname === `${defaultConstants.defaultStartPath}${currentLanguage}`)
            || (location.pathname === `${defaultConstants.defaultStartPath}${currentLanguage}/`)
            || (location.pathname === `${defaultConstants.defaultStartPath}${currentLanguage}/${pages[currentHomePage.id].meta.slug}`);
        const pastScrollBreakPoint = window.pageYOffset / window.innerHeight >= 0.355;

        if (navContainer) {
            (isInHomePage && !pastScrollBreakPoint)
                ? navContainer.className = ''
                : navContainer.className = 'regular-nav';
        }
    };

    handleLogoClick = () => {
        const {history, match} = this.props;
        history.push(`${defaultConstants.defaultStartPath}${match.params.lang}`);
    };

    handleShowMenu = () => {
        this.props.dispatch(uiActions.showMenu())
    };

    render() {
        const {navContent, componentContentReady, pages, match, locale} = this.props;

        console.log("locale", locale)
        return (
            componentContentReady && navContent ?
                <Fragment>
                    <Navbar id='thewom-top-nav'>
                        <div className='nav-main d-flex justify-content-between'>
                            <div className='nav-section left d-flex align-items-center'>
                                <div id='top-nav-hamburger-icon'
                                     className='hamburger-icon d-flex flex-column justify-content-between'
                                     onClick={this.handleShowMenu}
                                     style={{backgroundImage: `url(${converter.getImageDownloadURL(navContent.hamburger_icon)})`}}
                                />

                                {/* Change Language button */}
                                {
                                    locale === 'en' && <div className={'thewom-language-icon-container d-flex'}>
                                        <a href={'https://www.staywom.com/he'}>
                                            <img src={israelFlag} alt={"Israel Flag"}/>
                                        </a>
                                    </div>
                                }
                                {
                                    locale === 'he' && <div className={'thewom-language-icon-container d-flex'}>
                                        <a href={'https://www.staywom.com/en'}>
                                            <img src={usaFlag} alt={"Israel Flag"}/>
                                        </a>
                                    </div>
                                }

                            </div>
                            <NavbarBrand onClick={this.handleLogoClick} className='nav-logo' style={{
                                backgroundImage: `url(${converter.getImageDownloadURL(navContent.logo)})`,
                            }}>

                            </NavbarBrand>
                            <div className='nav-section right d-flex flex-row-reverse'>
                                {
                                    (navContent.button_link && pages[navContent.button_link.id])
                                    &&
                                    <Link className='btn-link'
                                          to={`${match.url}/${pages[navContent.button_link.id].meta.slug}`}>
                                        {
                                            navContent.button_label.split(" ").map((word, index) => {
                                                return (
                                                    <div key={index}>{word}</div>
                                                )
                                            })

                                        }
                                    </Link>
                                }
                            </div>
                        </div>
                    </Navbar>
                </Fragment>
                : ''
        )
    }
}

const mapStateToProps = ({ui, content, settings, router}) => {
    const {logoStyleHeaderHome, logoStyle, topNavStyle, topNavTextColorHome, topNavTextColorPages, pastScrollBreakPoint} = ui;
    const {pages} = content;
    const {locale} = settings;
    const currentLanguage = settings.locale;
    const {homePage} = router;
    return {
        logoStyleHeaderHome,
        logoStyle,
        topNavStyle,
        topNavTextColorHome,
        topNavTextColorPages,
        currentLanguage,
        pastScrollBreakPoint,
        pages,
        locale,
        currentHomePage: (!!homePage && homePage[locale]) || defaultConstants.defaultHomeSlug,
    };
};

const connectedPage = withRouter(connect(mapStateToProps)(TopNav));
export {connectedPage as TopNav};
