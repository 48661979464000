import React, {Component} from "react";
import {connect} from 'react-redux';

import {SimplexContainer} from "components";
import {contentActions} from "../../_actions";

class BookAWom extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        return (
            componentContentReady && componentContent ?
                <section className='book-a-wom-section center header'>
                    <h2 className={'section-title'}>{componentContent.headline}</h2>
                    <SimplexContainer id={"simplex-1"}
                                      className={'book-a-wom__simplex-container'}
                                      simplex_language={componentContent.simplex_language}
                                      simplex_is_rtl={componentContent.simplex_is_rtl}/>
                </section>
                : ''
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = connect(mapStateToProps)(BookAWom);
export {connectedPage as BookAWom};
