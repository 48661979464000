import React, {Component} from 'react';

class TheWomLoader extends Component {
    render() {
        return (
            <div className={'the-wom-loader'}>
                <div className="the-wom-spin-container">
                    <div className="the-wom-spin"/>
                </div>
            </div>
        );
    }
}

const connectedPage = TheWomLoader;
export {connectedPage as TheWomLoader};
