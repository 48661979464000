import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';

import {HeaderWOMs} from 'components'
import {WOMDescription} from 'components'

class RoomsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            HeaderHome: {
                type: 'HeaderHome',
                order: 1,
                content: {
                    title: 'WOM',
                    subtitle: 'A new accommodation concept filtered to its most essential & pure parts.',
                    backgroundImage: 'https://picsum.photos/1068/500/?random'
                }
            },
        };
    }

    render() {
        const WOMs = [
            {
                backgroundColorAsRGB: '#f9c0b7',
                introImage: 'url(https://picsum.photos/1060/500/?random)',
                featuresList: [
                    `This is a good WOM For you`,
                    `This is a good WOM for everybody!`,
                    `This is a good WOM`,
                    `This is a good WOM... specially for me`,
                    `This is a good WOM`,
                    `This is a good WOM for everybody!`,
                    `This is a good WOM... specially for me`,
                ],
                headline: 'Single WOM',
                type: 'Single'
            },
            {
                backgroundColorAsRGB: '#e6e5e5',
                introImage: 'url(https://picsum.photos/1061/500/?random)',
                featuresList: [
                    `This is a good WOM For you`,
                    `This is a good WOM for everybody!`,
                    `This is a good WOM`,
                    `This is a good WOM... specially for me`,
                    `This is a good WOM`,
                    `This is a good WOM for everybody!`,
                    `This is a good WOM... specially for me`,
                ],
                headline: 'Twin WOM',
                type: 'Twin'
            },
            {
                backgroundColorAsRGB: '#f9e15d',
                introImage: 'url(https://picsum.photos/1062/500/?random)',
                featuresList: [
                    `This is a good WOM For you`,
                    `This is a good WOM for everybody!`,
                    `This is a good WOM`,
                    `This is a good WOM... specially for me`,
                    `This is a good WOM`,
                    `This is a good WOM for everybody!`,
                    `This is a good WOM... specially for me`,
                ],
                headline: 'Queen WOM',
                type: 'Queen'
            },
        ];

        return (
            <Fragment>
                <HeaderWOMs/>
                {WOMs.map(content => (
                    <WOMDescription content={content}/>
                ))}

            </Fragment>
        );
    }
}

function mapStateToProps() {
    return {}
}

const connectedPage = connect(mapStateToProps)(RoomsPage);
export {connectedPage as RoomsPage};
