import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';

import {DetailsHover} from "components";
import {contentActions} from "../../_actions";

class DetailsHoverGrid extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        return (
            <section className={'details-hover-grid-section center'}>
                {componentContentReady && componentContent ?
                    <Fragment>
                        <h1 className={'section-title'}>{componentContent.headline}</h1>
                        <div className={'details-hover-grid d-flex flex-wrap justify-content-between'}>
                            {Array.isArray(componentContent.details_by_hover) &&
                            componentContent.details_by_hover.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <DetailsHover
                                            content={item}
                                            key={index}>
                                        </DetailsHover>
                                    </Fragment>
                                )
                            })}
                        </div>
                    </Fragment>
                    : ''
                }
            </section>
        );
    }
}

function mapStateToProps({}) {
    return {

    }
}

const connectedPage = connect(mapStateToProps)(DetailsHoverGrid);
export {connectedPage as DetailsHoverGrid};
