import {tempConstants} from "_constants";

const initial_state = {};

export const instagram = (state = initial_state, action) => {
    switch (action.type) {
        case tempConstants.GET_INSTAGRAM_PHOTOS_REQUEST:
            return {
                ...state,
                [action.accessToken]: {
                    // Obtain accessToken as a key on first request to avoid multiple requests on re-render
                    getRequestFlag: true
                }
            };
        case tempConstants.GET_INSTAGRAM_PHOTOS_SUCCESS:
            return {
                ...state,
                [action.accessToken]: {
                    ...state[action.accessToken],
                    ...action.instagramPhotos
                }
            };
        case tempConstants.CLEAR_INSTAGRAM_PHOTOS_PER_KEY:
            return {
                ...state
            };
        default:
            return state;
    }
};