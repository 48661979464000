import React, {Component} from "react";
import {connect} from 'react-redux';
import {generalHelpers} from "_helpers";
import {Link, withRouter} from "react-router-dom";

import {SingleWOMCarousel} from "components";
import {defaultConstants} from "_constants";


class WOMDescription extends Component {
    render() {
        const {content, navAnchor, locale, pages} = this.props;

        const room_essentials = content.room_essentials[0];

        const {room_images} = content;

        generalHelpers.print('WOMDescription', content);

        return (
            <section
                id={`page-item-${navAnchor}`}
                className={'woms-description  d-flex flex-column justify-content-start align-items-center center'}>
                <div className={'woms-description-content d-flex flex-wrap justify-content-center align-items-start'}
                     style={{
                         backgroundColor: room_essentials.background_color
                     }}>
                    <div className={'woms-description-hide-background'}/>

                    <div className={'woms-description-headline'}>
                        <div className={'section-title'}>
                            {room_essentials.headline}
                        </div>
                    </div>

                    <div className={'wom-features-container'}>
                        <div className={'wom-features-headline'}>
                            <div className={'section-subtitle'}>
                                {room_essentials.sub_headline}
                            </div>
                        </div>
                        <div className={'wom-features-list'}
                             dangerouslySetInnerHTML={{__html: room_essentials.details_left}}/>
                    </div>
                    <div className={'wom-image-container'}>
                        {/*<div className={'wom-image'}*/}
                        {/*     style={{backgroundImage: `url(${converter.getImageDownloadURL(content.image)})`}}/>*/}
                        <SingleWOMCarousel
                            imagesArray={(room_images && room_images.length > 0) ? room_images : [room_essentials.image]}/>
                    </div>

                    <div className={'wom-description-btn d-flex justify-content-center'}>
                        {
                            (pages && room_essentials.button_link && pages[room_essentials.button_link.id])
                            && <Link
                                to={`${defaultConstants.defaultStartPath}${locale}/${pages[room_essentials.button_link.id].meta.slug}`}
                                className={'the-wom-btn dark-button'}>
                                {room_essentials.button_text}
                            </Link>
                        }
                    </div>

                </div>
            </section>
        );
    }
}

function mapStateToProps({settings, content}) {
    const {locale} = settings;
    const {pages} = content;
    return {
        locale,
        pages,
    }
}

const connectedPage = withRouter(connect(mapStateToProps)(WOMDescription));
export {connectedPage as WOMDescription};
