import React, {Component} from "react";
import {connect} from 'react-redux';

import {MapsContainer} from "components";
import {uiActions} from "_actions";
import {converter} from "_helpers";
import {uiConstants} from "_constants";

class SingleContactHotel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            centerOffset: -0.00010
        };
    }

    componentDidMount() {
        this.setCenterOffset();
    }

    handleShowModal = () => {
        const {content} = this.props;

        const sendToMail = content.email_address;

        this.props.dispatch(uiActions.showContactModal(sendToMail));
    };

    setCenterOffset = () => {
        let screenWidth = window.outerWidth;

        switch (true) {
            case screenWidth < uiConstants.BREAKPOINT_XXS:
                this.setState({centerOffset: 0.00012});
                break;
            case screenWidth < uiConstants.BREAKPOINT_XS:
                this.setState({centerOffset: 0.00018});
                break;
            case screenWidth < uiConstants.BREAKPOINT_SM:
                this.setState({centerOffset: 0.00028});
                break;
            default:
                this.setState({centerOffset: -0.00010});
                break;
        }
    };

    render() {
        const {content, last} = this.props;

        return (
            content ?
                <div className={`single-contact-hotel d-flex justify-content-center align-items-center ${last && 'mb-0'}`}>
                    <div className={'image-container'}>
                        <div className={'image'}
                             style={{backgroundImage: `url(${converter.getImageDownloadURL(content.background_image)})`}}/>
                    </div>
                    <div className={'map-container'}>
                        <MapsContainer
                            className={'map'}
                            iconURL={content.iconURL}
                            centerOffset={this.state.centerOffset}
                            location={{latitude: content.lat, longitude: content.lng}}/>
                    </div>
                    <div className={'label-container align-self-center'}>
                        <div className={'label d-flex flex-column justify-content-around align-items-center'}>
                            <h4 className={'label-title'}>{content.headline}</h4>
                            {
                                content.address_link
                                    ? <a className={'address'} href={content.address_link}
                                         target={'_blank'}
                                         dangerouslySetInnerHTML={{__html: content.address}}/>
                                    : <div className={'address'} dangerouslySetInnerHTML={{__html: content.address}}/>
                            }

                            <div className={'phone-mail'}>
                                <p><a href={`tel:${content.phone_number}`}>{content.phone_number}</a></p>
                                <p><a href={`mailto: ${content.email_address}`}>{content.email_address}</a></p>
                            </div>
                            <div className='the-wom-btn dark-button'
                                 onClick={this.handleShowModal}>{content.button_text}</div>
                        </div>
                    </div>
                </div>
                : ''
        );
    }
}

function mapStateToProps() {
    return {}
}

const connectedPage = connect(mapStateToProps)(SingleContactHotel);
export {connectedPage as SingleContactHotel};
