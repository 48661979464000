import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';

import {converter, generalHelpers} from "_helpers";
import {componentsConstants, defaultConstants} from "_constants";

import locationIcon from "assets/img/location-icon.png";

class TwoImagesWithLabel extends Component {
    handleClick = (event) => {
        const {parentComponent, match, history, parentSlug, locationSlug} = this.props;

        if (parentComponent === 'locations') {
            history.push(`${match.path}/${parentSlug}/${locationSlug}`)
        } else {
            event.preventDefault();
        }
    };

    linkHandler = event => {
        const link = event.target.href;
        window.open(link, "_top");
    };

    render() {
        const {content, parentComponent, currentLanguage, parentPageLink} = this.props;
        let componentContent;

        if (content[componentsConstants.TWO_IMAGES_WITH_LABEL])
            componentContent = content[componentsConstants.TWO_IMAGES_WITH_LABEL][0];
        else {
            return '';
        }

        return (
            componentContent ?
                <Fragment>
                    <div className={`two-images-with-label ${parentComponent} d-flex justify-content-center`}
                         onClick={this.handleClick}>
                        <div className={'single-image-container left'}>
                            <div className={'single-image'}
                                 style={{backgroundImage: `url(${converter.getImageDownloadURL(componentContent.image_left)})`}}/>
                        </div>
                        <div className={'single-image-container right'}>
                            <div className={'single-image'}
                                 style={{backgroundImage: `url(${converter.getImageDownloadURL(componentContent.image_right)})`}}/>
                        </div>
                        <div
                            className={'label-container d-flex justify-content-center align-items-center align-self-center'}>
                            {parentComponent === 'locations'
                                ?
                                <div
                                    className={'label label-title d-flex justify-content-center align-items-center'}>
                                    {componentContent.label}
                                </div>
                                :
                                <div
                                    className={'carousel-label-content d-flex flex-column justify-content-between align-items-start'}>
                                    <div className={'carousel-label-title'}>
                                        <div className={'item-title section-subtitle'}>
                                            {generalHelpers.checkLabelTitleLength(componentContent.label)}
                                        </div>
                                    </div>
                                    <div className={'carousel-label-footer'}>
                                        {componentContent.location_label ?
                                            <div className={'item-location d-flex align-item-center'}>
                                                <div className={'item-location-icon'}
                                                     style={componentContent.location_icon ?
                                                         {backgroundImage: `url(${converter.getImageDownloadURL(componentContent.location_icon)}`}
                                                         :
                                                         {backgroundImage: `url(${locationIcon}`}
                                                     }/>
                                                <div
                                                    className={'item-location-label'}>{componentContent.location_label}</div>
                                            </div>
                                            : ''
                                        }
                                        <div className={'carousel-label-link'}>
                                            {
                                                content.link_to_external_link
                                                    ? <a
                                                        className={'item-link'}
                                                        href={content.external_link}
                                                        onClick={this.linkHandler}

                                                        // This will open a new tab for this link
                                                        // target='_blank'
                                                        // rel='noopener noreferrer'
                                                    >
                                                        {componentContent.link_label || "DISCOVER"}
                                                    </a>
                                                    : <Link
                                                        className={'item-link'}
                                                        to={`${defaultConstants.defaultStartPath}${currentLanguage}/${parentPageLink}/${content.meta.slug || componentContent.meta.slug}`}>
                                                        {componentContent.link_label || "DISCOVER"}
                                                    </Link>
                                            }
                                    </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </Fragment>
                :
                ''

        );
    }
}

function mapStateToProps({settings}) {
    const {locale} = settings;
    return {
        currentLanguage: locale
    }
}

const connectedPage = withRouter(connect(mapStateToProps)(TwoImagesWithLabel));
export {connectedPage as TwoImagesWithLabel};
