import {generalService} from "../_services";
import {contentConstants, uiConstants} from "../_constants";
import {uiActions} from "./ui.actions";

export const generalActions = {
    sendMail,
    sendContactUsMail
};


function sendMail(headline, to, phone_number, email, user_message, user_name, hotel, date, participants) {
    return dispatch => {
        dispatch(request(headline, to, phone_number, email, user_message, user_name, hotel, date, participants));
        dispatch(uiActions.alertLoader());

        generalService.sendMail({
            headline,
            to,
            phone_number,
            email,
            user_message,
            user_name,
            hotel,
            date,
            participants,
        })
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(uiActions.alertSuccess());
                }
            ).catch(
            error => {
                dispatch(failure(error))
            }
        )
    };


    function request(headline, to, phone_number, email, user_message, user_name, hotel, date, participants) {
        return {
            type: contentConstants.SEND_EMAIL_REQUEST,
            headline, to, phone_number, email, user_message, user_name, hotel, date, participants
        }
    }

    function success(response) {
        return {type: contentConstants.SEND_EMAIL_SUCCESS, response}
    }

    function failure(error) {
        return {type: contentConstants.SEND_EMAIL_FAILURE, error}
    }
}

function sendContactUsMail(name, email, message, sendToMail, uiId) {
    return dispatch => {
        dispatch(request());

        generalService.sendContactUsMail({
            name: name,
            email: email,
            message: message,
            send_to: sendToMail
        })
            .then(
                response => {
                    if(response.code === "SUCCESS"){
                        dispatch(uiActions.hideContactModal());
                        dispatch(uiActions.showElement(uiConstants.CONTACT_US_SUCCESS))
                    }

                    if(response.code === "ERROR"){
                        dispatch(uiActions.showElement(uiConstants.CONTACT_US_FAILURE))
                    }

                    dispatch(success(response));
                    dispatch(uiActions.hideElement(uiId));
                }
            ).catch(
            error => {
                dispatch(failure(error));
                dispatch(uiActions.hideElement(uiId));
                dispatch(uiActions.showElement(uiConstants.CONTACT_US_FAILURE))
            }
        )
    };


    function request(headline, to, phone_number, email, user_message, user_name, hotel, date, participants) {
        return {
            type: contentConstants.SEND_EMAIL_REQUEST,
            headline, to, phone_number, email, user_message, user_name, hotel, date, participants
        }
    }

    function success(response) {
        return {type: contentConstants.SEND_EMAIL_SUCCESS, response}
    }

    function failure(error) {
        return {type: contentConstants.SEND_EMAIL_FAILURE, error}
    }
}
