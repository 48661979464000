import axios from 'axios';
import {generalConstants} from "_constants";

export const generalService = {
    requestSender,
    requestExternalAPISender,
    saveStateToLocalStorage,
    handleAxiosResponse,
    // createRequestParams

    sendMail,
    sendContactUsMail
};

function handleAxiosResponse(response) {
    if (response.status < 200 && response.status >= 300) {
        const error = response.statusText;
        return Promise.reject(error);
    } else {
        return response.data
    }
}

function requestSender(params = {}, post_fix = '') {
    const requestOptions = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        params: params
    };

    return axios(
        `${generalConstants.API_ENDPOINT}${post_fix}`,
        requestOptions
    )
        .then(handleAxiosResponse)
        .then(response => {
            return response;
        });
}

function requestExternalAPISender(apiUrl, params = {}) {
    const requestOptions = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        params: params
    };

    return axios(
        `${apiUrl}`,
        requestOptions
    )
        .then(handleAxiosResponse)
        .then(response => {
            return response;
        });
}

function saveStateToLocalStorage() {
    // localStorage.setItem('state', JSON.stringify(store.getState()))
}

// function createRequestParams(params) {
//     let params_string;
//
//     for (let key in params) {
//         if (languages.hasOwnProperty(key)) {
//             params
//         }
//     }
// }


function sendMail(mailData) {
    return axios(`${generalConstants.DOMAIN}/api/utils/send-mail`, {
        method: "POST",
        data: mailData
    }).then(handleAxiosResponse)
}

function sendContactUsMail(mailData) {

    return axios(`${generalConstants.DOMAIN}/api/utils/contact-us`, {
        method: "POST",
        data: mailData
    }).then(handleAxiosResponse)
}
