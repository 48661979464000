import React, {Component} from "react";
import {connect} from 'react-redux';

import {converter as converters} from "_helpers";
import {contentActions} from "../../_actions";
import {generalConstants} from "../../_constants";

class HeaderBackgroundImageWithHeadlines extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        const userAgent = navigator.userAgent;
        const isMobileAppAgent = userAgent === generalConstants.USER_AGENT;

        return (
            componentContentReady && componentContent ?
                <section className={'header-background-image-with-headlines header-wide header'}
                         style={{
                             backgroundImage:
                                 `linear-gradient(
                                     rgba(0, 0, 0, 0.2) 0%,
                                     rgba(0, 0, 0, 0.2) 100%),
                                     url(${converters.getImageDownloadURL(componentContent.image)})`,
                             marginTop: isMobileAppAgent && 0
                         }}>
                    <div className={'headline-and-sub'}>
                        <h2 className={'section-title'}>{componentContent.headline}</h2>
                        <h4 className={'section-subtitle'}>{componentContent.sub_headline}</h4>
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = connect(mapStateToProps)(HeaderBackgroundImageWithHeadlines);
export {connectedPage as HeaderBackgroundImageWithHeadlines};
