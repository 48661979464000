import React, {Component, Fragment} from "react";
import {generalHelpers} from "_helpers";
import {connect} from "react-redux";

class SimplexContainer extends Component {
    constructor(props) {
        super(props);

        this.languagesMap = {
            'en': 'eng',
            'eng': 'eng',
            'english': 'eng',
        }
    }

    componentDidMount() {
        const {id} = this.props;

        // Removes an element from the document
        const element = document.querySelector('#ui-datepicker-div');
        if (element) {
            element.parentNode.removeChild(element);
        }

        generalHelpers.loadScript("https://cdn.simplebooking.it/search-box-script.axd?IDA=7441", {
            id: "SBSyncroBox"
        });
   
    }

    render() {
        const {id, className, dataParams, locale, simplex_language, simplex_is_rtl} = this.props;

        return (
            <Fragment>
                <div className={`simplex-container ${className}`} id={'sb-container'} data-params={dataParams || `?lang=${simplex_language || this.languagesMap[locale]}&chainid=195&hotel=10717`}/>
            </Fragment>
        );
    }
}

function mapStateToProps({settings}) {
    const {locale} = settings;
    return {
        locale
    }
}

const connectedPage = connect(mapStateToProps)(SimplexContainer);
export {connectedPage as SimplexContainer};
