import React, {Component} from "react";
import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import {converter, generalHelpers} from "_helpers";

import locationIcon from 'assets/img/location-icon.png';
import {contentActions} from "../../../_actions";

class CollectionGalleryItem extends Component {
    componentDidMount() {
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if (!componentContentReady) {
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    linkSwitcher = (itemLabel, componentContent) => {
        const {match} = this.props;

        // This switched between Internal to External links
        if (componentContent.link_to_external_link) {
            return (<a
                href={componentContent.external_link}
            >
                {itemLabel}
            </a>)
        } else {
            return (
                <Link
                    to={`${match.url}/${componentContent.meta.slug || componentContent.gallery_view[0].meta.slug}`}>
                    {itemLabel}
                </Link>
            )
        }
    };


    render() {
        const {componentContent, componentContentReady, match} = this.props;

        let galleryView;

        if (componentContent && componentContentReady) {
            galleryView = componentContent.gallery_view[0] || {
                image: componentContent.header_image,
                label: componentContent.headline,
                link_label: 'DISCOVER',
                location_label: "Unavailable"
            };
        }

        return (
            componentContentReady && componentContent && galleryView ?
                <div className={'collection-gallery-item-container'}>
                    <div
                        className={'collection-gallery-item d-flex flex-wrap justify-content-between align-items-start'}>
                        <div className={'gallery-item-image-container'}>
                            <div className={'gallery-item-image'}
                                 style={{
                                     backgroundImage:
                                         `linear-gradient(
                                     rgba(255, 255, 255, 0) 0%,
                                     rgba(255, 255, 255, 0.3) 95%,
                                     rgba(255, 255, 255, 0.3) 100%
                                 ),
                                 url(${converter.getImageDownloadURL(galleryView.image)})`
                                 }}/>
                        </div>
                        {
                            this.linkSwitcher(
                                <div className={'gallery-item-label'}>
                                    <div className={'gallery-item-label-background'}/>
                                    <div
                                        className={'gallery-item-label-content d-flex flex-column justify-content-between'}>
                                        <div className={'gallery-item-label-title'}>
                                            <div className={'item-title section-subtitle'}>
                                                {generalHelpers.checkLabelTitleLength(galleryView.label)}
                                            </div>
                                        </div>
                                        <div className={'gallery-item-label-footer'}>
                                            {
                                                galleryView.location_label 
                                                ?
                                                    <div className={'item-location d-flex align-item-center'}>
                                                        <div className={'item-location-icon'}
                                                            style={galleryView.location_icon ?
                                                                {backgroundImage: `url(${converter.getImageDownloadURL(galleryView.location_icon)}`}
                                                                :
                                                                {backgroundImage: `url(${locationIcon}`}
                                                            }/>
                                                        <div
                                                            className={'item-location-label'}>{galleryView.location_label}</div>
                                                    </div>
                                                : ''
                                            }
                                            <div className={'gallery-item-label-link'}>
                                                {
                                                    componentContent.link_to_external_link
                                                        ? <a
                                                            className={'item-link'}
                                                            href={componentContent.external_link}

                                                            // This will open a new tab for this link
                                                            // target='_blank'
                                                            // rel='noopener noreferrer'
                                                        >
                                                            {galleryView.link_label}
                                                        </a>
                                                        : <Link
                                                            className={'item-link'}
                                                            to={`${match.url}/${componentContent.meta.slug || componentContent.meta.slug}`}>
                                                            {galleryView.link_label}
                                                        </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                , componentContent
                            )}
                    </div>
                </div>
                : '');
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = withRouter(connect(mapStateToProps)(CollectionGalleryItem));
export {connectedPage as CollectionGalleryItem};




