import {
    BackgroundWithButton,
    ContactHotels,
    DetailsHoverGrid,
    ImageCarousel,
    TwoImagesWithLabelCarousel,
    HeaderHome,
    HeaderBackgroundImageWithHeadlines,
    HeadlineWithText,
    HeaderTwoHeadlinesWithBackgroundImage,
    HeaderTwoParagraphs,
    HeaderTwoParagraphsTwoImages,
    HotelPublicPlacesCarousel,
    InstagramPhotoGrid,
    Locations,
    Paragraph,
    Subscribe,
    HotelDetails,
    WOMs,
    CollectionGallery,
    HotelRooms,
    MapWithLabelWide,
    HeaderWOMs,
    PageNavigationPanel,
    RoomsGallery,
    IFrameSection,
    CollectionGalleryRouter,
    FAQ,
    HeaderCenterText,
    TermsAndConditions,
    BookAWom
    // CollectionItemHeader,
    // SingleImageCenter,
    // OneImageSlide,
    // GalleryCarousel,
    // HeaderOneImage,
    // MapsContainer,
    // TwoImagesWithLabel,
    // DetailsHover,
} from "components";

import {HeadlineBig, HeadlineSmall, ArticleImage, ArticleParagraph} from 'components/BlogArticle/comps'


export const componentsConstants = {
    COMPONENTS_LIBRARY: {
        //Collection Page
        'thewom.CollectionPage': CollectionGallery,

        // Cross Page Components and Data
        'thewom.HotelsIndex': '',
        'thewom.Hotel': '',
        'thewom.RoomsHotelsSummary': '',
        'thewom.MapWithLabel': '',
        'thewom.SimplexComp': BookAWom,


        // Headers
        'thewom.HeaderHome': HeaderHome,
        'thewom.HeaderTwoParagraphsTwoImages': HeaderTwoParagraphsTwoImages,
        'thewom.HeaderTwoHeadlinesWithBackgroundImage': HeaderTwoHeadlinesWithBackgroundImage,
        'thewom.HeaderHeadlinesWithImage': HeaderBackgroundImageWithHeadlines,
        'thewom.HeaderTwoParagraphs': HeaderTwoParagraphs,
        'thewom.HeaderOneImageWithParagraph': HeaderWOMs,
        'thewom.HeaderCenterText': HeaderCenterText,

        // Headlines & Text
        'thewom.HeadlineWithText': HeadlineWithText,
        'thewom.TextParagraph': Paragraph,
        'thewom.BackgroundWithButton': BackgroundWithButton,

        // Galleries
        'thewom.DetailsByHoverContainer': DetailsHoverGrid,
        'thewom.Locations': Locations,
        'thewom.ImageCarousel': ImageCarousel,
        'thewom.Instagram': InstagramPhotoGrid,
        'thewom.TwoImagesWithLabelCarousel': TwoImagesWithLabelCarousel,
        'collection.HotelPublicPlacesCarousel': HotelPublicPlacesCarousel,

        // Interactive
        'thewom.EmailSubscribe': Subscribe,

        // Special The Wom
        'thewom.WOMs': WOMs,
        'thewom.IFrameSection': IFrameSection,
        'thewom.FAQ': FAQ,
        'thewom.TermsAndConditions': TermsAndConditions,

        // Collections
        'collection.HotelsGallery': ContactHotels,
        'collection.HotelDetails': HotelDetails,
        'collection.HotelRooms': HotelRooms,
        'collection.HotelLocation': MapWithLabelWide,
        'collection.RoomsNavigation': PageNavigationPanel,
        'collection.RoomsGallery': RoomsGallery,
        'collection.ExperiencesGallery': CollectionGalleryRouter,
        'collection.EventsGallery': CollectionGalleryRouter,
        'collection.BlogGallery': CollectionGalleryRouter,
        // 'collection.BlogGallery': CollectionGallery,
        'thewom.WOMsContainer': '',


        // BlogArticle comps:
        'heading_big': HeadlineBig,
        'heading_small': HeadlineSmall,
        'image': ArticleImage,
        'paragraph': ArticleParagraph,
    },

    // Sub Components List:
    TWO_IMAGES_WITH_LABEL: 'two_images_with_label',

    NAVIGATION: 'containers.Navigation',
};