import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';

import {Locations} from 'components'
import {HeaderHome} from 'components/HeaderHome'
import {HeadlineWithText} from 'components/HeadlineWithText'
import {Paragraph} from 'components/Paragraph'
import {DetailsHoverGrid} from 'components/DetailsHoverGrid'
import {GalleryCarousel} from "components/GalleryCarousel";
import {WOMs} from "components/WOMs";
import {Subscribe} from "components/Subscribe";
import {InstagramPhotoGrid} from "components/InstagramPhotoGrid";

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            HeaderHome: {
                type: 'HeaderHome',
                order: 1,
                content: {
                    title: 'WOM',
                    subtitle: 'A new accommodation concept filtered to its most essential & pure parts.',
                    backgroundImage: 'https://picsum.photos/1068/500/?random'
                }
            },


            HeadlineWithText: {
                type: 'HeadlineWithText',
                order: 2,
                content: {
                    headline: 'All you need is WOM',
                    text: 'A new affordable accomodation for the global contemporary traveler to experience the city in a sustainable, digital & social way'
                }
            },
            Locations: {
                type: 'Locations',
                order: 3,
                content: {
                    title: 'Locations',
                    locations: [
                        {
                            label: 'Allenby',
                            left_img: 'https://picsum.photos/612/408/?random',
                            right_img: 'https://picsum.photos/611/407/?random',
                            link: ''
                        },
                        {
                            label: 'Allenby',
                            left_img: 'https://picsum.photos/610/406/?random',
                            right_img: 'https://picsum.photos/609/405/?random',
                            link: ''
                        },
                        {
                            label: 'Allenby',
                            left_img: 'https://picsum.photos/608/404/?random',
                            right_img: 'https://picsum.photos/607/403/?random',
                            link: ''
                        },
                    ]
                }
            },
            Paragraph: {
                type: 'Paragraph',
                order: 4,
                content: {
                    text: 'Disrupting the traditional hotel & hostel industry by creating a digital hybrid' +
                        'for the contemporary traveler who enjoys the luxury of a safe, private, well' +
                        'designed hotel rooms in central urban locations, for the price of a shared' +
                        'hostel bed.'
                }
            },
            DetailsHoverGrid: {
                type: 'DetailsHoverGrid',
                order: 5,
                content: {
                    title: 'How it Works',
                    DetailsByHover: [
                        {
                            headline: 'Wash - in',
                            details_text: 'All private luxurious bathrooms units (with toilet & shower) are used' +
                                'separately by each guest. The bathrooms are designed to be shred' +
                                'with 1-4 guests giving you the feeling of a hotel privacy in a hostel price.',
                            image: 'https://picsum.photos/647/315/?random'
                        },
                        {
                            headline: 'Wash - in',
                            details_text: 'All private luxurious bathrooms units (with toilet & shower) are used' +
                                'separately by each guest. The bathrooms are designed to be shred' +
                                'with 1-4 guests giving you the feeling of a hotel privacy in a hostel price.',
                            image: 'https://picsum.photos/645/313/?random'
                        },
                        {
                            headline: 'Wash - in',
                            details_text: 'All private luxurious bathrooms units (with toilet & shower) are used' +
                                'separately by each guest. The bathrooms are designed to be shred' +
                                'with 1-4 guests giving you the feeling of a hotel privacy in a hostel price.',
                            image: 'https://picsum.photos/643/310/?random'
                        },
                        {
                            headline: 'Wash - in',
                            details_text: 'All private luxurious bathrooms units (with toilet & shower) are used' +
                                'separately by each guest. The bathrooms are designed to be shred' +
                                'with 1-4 guests giving you the feeling of a hotel privacy in a hostel price.',
                            image: 'https://picsum.photos/640/307/?random'
                        }
                    ]
                }
            },

            WOMs: {
                type: 'WOMs',
                order: 6,
                content: {
                    headline_h1: 'WOMs',
                    image: 'https://picsum.photos/g/900/600/?random',
                    woms_list_item: [
                        'Queen - double bed for couples',
                        'Queen - double bed for couples',
                        'Queen - double bed for couples',
                        'Queen - double bed for couples',
                    ],
                    headline_h2: 'Public Spaces',
                    paragraph: 'Amenities, Storage are available for all hotel guest. all units' +
                        ' & public areas are designed with the sustainable materials'
                }

            },

            ImagesCarousel: {
                type: 'GalleryCarousel',
                order: 7,
                content: {
                    title: 'The WOM Vibe',
                    type: 'multi-slide',
                    backgroundColor: null,
                    titleType: 'section-title',
                    slides: [
                        'https://picsum.photos/800/600/?random',
                        'https://picsum.photos/801/601/?random',
                        'https://picsum.photos/802/602/?random',
                    ],
                    speed: 500,
                }
            },

            ExperiencesCarousel: {
                type: 'GalleryCarousel',
                order: 8,
                content: {
                    title: 'Experiences',
                    type: 'single-slide',
                    speed: 500,
                    backgroundColor: null,
                    titleType: 'section-title',
                    linkText: 'SEE EXPERIENCES',
                    link: '#',
                    slides: [
                        {
                            label: 'Cooking class with Chef Avi Biton',
                            left_img: 'https://picsum.photos/611/407/?random',
                            right_img: 'https://picsum.photos/612/408/?random',
                            link: ''
                        },
                        {
                            label: 'Cooking class with Chef Avi Biton',
                            left_img: 'https://picsum.photos/614/405/?random',
                            right_img: 'https://picsum.photos/610/406/?random',
                            link: ''
                        },
                        {
                            label: 'Cooking class with Chef Avi Biton',
                            left_img: 'https://picsum.photos/613/402/?random',
                            right_img: 'https://picsum.photos/615/401/?random',
                            link: ''
                        },
                    ],
                }
            },
            EventsCarousel: {
                type: 'GalleryCarousel',
                order: 10,
                content: {
                    title: 'Events',
                    type: 'single-slide',
                    speed: 500,
                    backgroundColor: '#f8c0b7',
                    titleType: 'section-title-overlap',
                    linkText: 'EXPLORE EVENTS',
                    link: '',
                    slides: [
                        {
                            label: 'Cooking class with Chef Avi Biton',
                            left_img: 'https://picsum.photos/615/410/?random',
                            right_img: 'https://picsum.photos/614/410/?random',
                            link: ''
                        },
                        {
                            label: 'Cooking class with Chef Avi Biton',
                            left_img: 'https://picsum.photos/612/406/?random',
                            right_img: 'https://picsum.photos/611/408/?random',
                            link: ''
                        },
                        {
                            label: 'Cooking class with Chef Avi Biton',
                            left_img: 'https://picsum.photos/613/408/?random',
                            right_img: 'https://picsum.photos/616/408/?random',
                            link: ''
                        },
                    ],
                }
            },

            EmailSubscribe: {
                type: 'Subscribe',
                order: 11,
                content: {
                    title: 'Befriend Wom',
                    subtitle: 'Sign up for the latest news, events and best rates',
                    btnText: 'SUBSCRIBE'
                }
            },

            Instagram: {
                type: 'InstagramPhotoGrid',
                order: 9,
                content: {
                    title: 'Instagram',
                    btnText: 'DISCOVER',
                    photos: [
                        'https://picsum.photos/409/409/?random',
                        'https://picsum.photos/411/411/?random',
                        'https://picsum.photos/g/408/408/?random',
                        'https://picsum.photos/412/412/?random',
                        'https://picsum.photos/g/413/413/?random',
                        'https://picsum.photos/414/414/?random',
                    ]
                }
            }
        }
    }

    render() {
        return (
            <Fragment>
                <HeaderHome content={this.state.HeaderHome.content}/>
                <HeadlineWithText content={this.state.HeadlineWithText.content}/>
                <Locations content={this.state.Locations.content}/>
                <Paragraph content={this.state.Paragraph.content}/>
                <DetailsHoverGrid content={this.state.DetailsHoverGrid.content}/>
                <WOMs content={this.state.WOMs.content}/>
                <GalleryCarousel content={this.state.ImagesCarousel.content}/>
                <GalleryCarousel content={this.state.ExperiencesCarousel.content}/>
                <InstagramPhotoGrid content={this.state.Instagram.content}/>
                <GalleryCarousel content={this.state.EventsCarousel.content}/>
                <Subscribe content={this.state.EmailSubscribe.content}/>
            </Fragment>
        );
    }
}

function mapStateToProps() {
    return {}
}

const connectedPage = connect(mapStateToProps)(HomePage);
export {connectedPage as HomePage};
