import {uiConstants} from "_constants";

const initial_state = {
    containerClassnames: '',
    isMenuOpen: false,
    pastScrollBreakPoint: false,

    logoStyle: {
        top: `${uiConstants.LOGO_MIN_MARGIN}%`,
        height: `${uiConstants.LOGO_MIN_HEIGHT}%`,
        width:  `${uiConstants.LOGO_MIN_WIDTH}%`,
    },

    topNavTextColorPages: `rgb(0,0,0)`
};

export const ui = (state = initial_state, action) => {
    switch (action.type) {
        case uiConstants.CHANGE_CLASS_NAMES:
            return {
                containerClassnames: action.className,
            };
        case uiConstants.SHOW_MENU:
            return {
                ...state,
                isMenuOpen: true
            };
        case uiConstants.HIDE_MENU:
            return {
                ...state,
                isMenuOpen: false
            };
        case uiConstants.UPDATE_LOGO_STYLE:
            return {
                ...state,
                logoStyleHeaderHome: {...action.logoStyleHeaderHome}

            };
        case uiConstants.UPDATE_TOP_NAV_STYLE:
            return {
                ...state,
                topNavStyle: {...action.topNavStyle}

            };
        case uiConstants.UPDATE_TOP_NAV_TEXT_COLOR:
            return {
                ...state,
                topNavTextColorHome: action.rgb

            };
        case uiConstants.SHOW_CONTACT_MODAL:
            return {
                ...state,
                isContactModalOpen: true,
                [uiConstants.SEND_TO_MAIL]: action.sendToMail
            };
        case uiConstants.HIDE_CONTACT_MODAL:
            return {
                ...state,
                isContactModalOpen: false,
                [uiConstants.SEND_TO_MAIL]: ''
            };
        case uiConstants.ALERT_SUCCESS:
            return {
                ...state,
                alertSuccess: true
            };
        case uiConstants.ERROR:
            return {
                ...state,
                alert: {
                    type: 'danger',
                    message: action.message
                }
            };
        case uiConstants.ALERT_CLEAR:
            return {
                ...state,
                alertLoader: false,
                alertSuccess: false
            };
        case uiConstants.ALERT_LOADER:
            return {
                ...state,
                alertLoader: true
            };
        case uiConstants.PAST_SCROLL_BREAK_POINT:
            return {
                ...state,
                pastScrollBreakPoint: true
            };
        case uiConstants.BEFORE_SCROLL_BREAK_POINT:
            return {
                ...state,
                pastScrollBreakPoint: false
            };
        case uiConstants.SHOW_ELEMENT:
            return {
                ...state,
                [action.elementId]: true
            };
        case uiConstants.HIDE_ELEMENT:
            return {
                ...state,
                [action.elementId]: false
            };
        default:
            return state;
    }
};
