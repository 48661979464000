import React, {Component, Fragment} from "react";
import {Route, Switch} from "react-router-dom";
import {connect} from 'react-redux';
import parse from 'html-react-parser';

import {contentConstants, defaultConstants, generalConstants} from "_constants";
import {routerActions} from "_actions";
import {uiHelpers} from "_helpers";

import {Footer, Page} from "components";
import {Menu, TopNav} from "containers";
import Helmet from "react-helmet/es/Helmet";
import {settingsActions} from "../../_actions/settings.actions";

class Thewom extends Component {
    componentDidMount() {
        const {dispatch, history, match, defaultLanguage, location, currentLanguage, availableLanguages} = this.props;

        const languageParam = match.params.lang;

        // Refresh the Nav Bar style (HomePage style or Regular)
        uiHelpers.scrollNavBarChange(location, currentLanguage);

        // Redirect to the default language if the URI is not recognized.
        if (!availableLanguages[languageParam]) {
            history.push(`${defaultConstants.defaultStartPath}${defaultLanguage}`);
        }

        dispatch(settingsActions.changeLocale(languageParam))

        // Scroll to top on app start
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        // Listen to Routes Change
        if (this.props.location.pathname !== prevProps.location.pathname) {
            const {location, currentLanguage} = this.props;
            // Get Exact route and not all path
            let splitPath = this.props.location.pathname.split("/");

            if (splitPath) {
                this.props.dispatch(routerActions.updateCurrentPage(splitPath[splitPath.length - 1]));
            }

            window.onscroll = () => (uiHelpers.scrollNavBarChange(location, currentLanguage));
            uiHelpers.scrollNavBarChange(location, currentLanguage);
        }
    }

    render() {
        let {currentHomePage} = this.props;
        const {match, languages, availableLanguages, permanents, pages, homePage, siteRoot} = this.props;

        const userAgent = navigator.userAgent;
        const isMobileAppAgent = userAgent === generalConstants.USER_AGENT;
        const selectedLanguage = match.params.lang,
            selectedLanguageId = availableLanguages[selectedLanguage];
        let navigation, navigation_pages;
        let sitePages = [];


        if (selectedLanguageId && languages[selectedLanguageId].permanents) {
            navigation = languages[selectedLanguageId].permanents.filter(permanent => (permanent.type === contentConstants.CMS_MENU_TYPE))[0];

            if (pages && permanents[navigation.id] && permanents[navigation.id]['navigation_links']) {
                navigation_pages = permanents[navigation.id]['navigation_links'].map(link => {
                    return pages[link.link.id]
                })
            }
        }

        if (languages[selectedLanguageId]) {
            if (languages[selectedLanguageId].pages) {
                sitePages = [
                    ...languages[selectedLanguageId].pages,
                ];
            }
        }
        if (navigation_pages) {
            sitePages = [
                ...sitePages,
                ...navigation_pages
            ];
        }

        currentHomePage = currentHomePage || homePage[selectedLanguage];

        // Load the script from the CMS as string. Convert the string into a script element.
        // This function loads the script and adds it to the head.
        const html = siteRoot.poptin_script_tag || " ";
        const script_tag_element = parse(html, {
          replace: function(domNode) {
            if (domNode.type === 'script') {
              const script = document.createElement('script');
              script.src = domNode.attribs.src;
              script.onload = function() {

              };
              document.head.appendChild(script);
            }
          }
        });

        return (
            <Fragment>
                {(languages[selectedLanguageId] && languages[selectedLanguageId].permanents) && languages[selectedLanguageId].permanents.map((permanent) => {
                    // Don't return top nav, menu and footer when the user agent is equal to mobile app user agent
                    if (isMobileAppAgent) {
                        return '';
                    }
                    // Otherwise return top nav, menu and footer
                    switch (permanent.type) {
                        case contentConstants.CMS_TOP_NAV_TYPE:
                            return (
                                <TopNav
                                    permanentId={permanent.id}
                                    key={permanent.id}
                                    navContent={permanents[permanent.id]}
                                    componentType={
                                        permanents[permanent.id] &&
                                        permanents[permanent.id][contentConstants.CMS_ITEM_TYPE]
                                    }
                                    componentContentReady={
                                        permanents[permanent.id] &&
                                        permanents[permanent.id][contentConstants.COMPONENT_CONTENT_READY]
                                    }
                                />
                            );
                        case contentConstants.CMS_MENU_TYPE:
                            return (
                                <Menu
                                    permanentId={permanent.id}
                                    key={permanent.id}
                                    menuContent={permanents[permanent.id]}
                                    componentType={
                                        permanents[permanent.id] &&
                                        permanents[permanent.id][contentConstants.CMS_ITEM_TYPE]
                                    }
                                    componentContentReady={
                                        permanents[permanent.id] &&
                                        permanents[permanent.id][contentConstants.COMPONENT_CONTENT_READY]
                                    }
                                />
                            );
                        case contentConstants.CMS_FOOTER_TYPE:
                            return (
                                <Footer
                                    permanentId={permanent.id}
                                    key={permanent.id}
                                    footerContent={permanents[permanent.id]}
                                    componentType={
                                        permanents[permanent.id] &&
                                        permanents[permanent.id][contentConstants.CMS_ITEM_TYPE]
                                    }
                                    componentContentReady={
                                        permanents[permanent.id] &&
                                        permanents[permanent.id][contentConstants.COMPONENT_CONTENT_READY]
                                    }
                                />
                            );
                        default:
                            return '';
                    }
                })}
                <Helmet>
                    {
                        !isMobileAppAgent &&
                        <script src="https://cdn.equalweb.com/accessibility.js" defer data-cfasync="false"/>
                    }
                    {/* Facebook Pixel Code */}
                    <script>
                        {
                            `!function(f, b, e, v, n, t, s){
                                if (f.fbq) return;
                                n = f.fbq = function () {
                                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                                };
                                if (!f._fbq) f._fbq = n;
                                n.push = n;
                                n.loaded = !0;
                                n.version = '2.0';
                                n.queue = [];
                                t = b.createElement(e);
                                t.async = !0;
                                t.src = v;
                                s = b.getElementsByTagName(e)[0];
                                s.parentNode.insertBefore(t, s);
                            }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
                            fbq('init', '463538264339907')
                            fbq('track', 'PageView')`
                        }
                    </script>
                    <noscript>
                        {
                            `<img
                                height="1"
                                width="1"
                                src="https://www.facebook.com/tr?id=463538264339907&ev=PageView&noscript=1"
                            />`
                        }
                    </noscript>
                    {/* /*End Facebook Pixel Code  */}

                    <script>
                        {`(function (w, d, s, id, aid) {
                            w.cbaid = aid;
                            var js, fjs = d.getElementsByTagName(s)[0];
                            if (d.getElementById(id)) {
                                return;
                            }
                            js = d.createElement(s);
                            js.id = id;
                            js.async = true;
                            js.src = "//aff.cashback.im/track/go.min.js?aid=" + aid;
                            fjs.parentNode.insertBefore(js, fjs);
                        }(window, document, 'script', 'cbo-tracker', 64386));`}
                    </script>
                </Helmet>
                <main style={{margin: isMobileAppAgent && '0 auto'}}>
                    <Switch>
                        {sitePages.map((page) => {
                            if (page && pages[currentHomePage.id]) {
                                return (
                                    page.slug === pages[currentHomePage.id].meta.slug || page.slug === defaultConstants.defaultHomeSlug
                                        ? <Route key={page.id} exact path={`${match.url}`}
                                                 render={
                                                     () => <Page pageId={page.id} key={page.id}/>
                                                 }/>
                                        : <Route key={page.id} path={`${match.url}/${page.slug || page.meta.slug}`}
                                                 render={
                                                     () => <Page pageId={page.id} key={page.id}/>
                                                 }/>
                                )
                            } else {
                                return ''
                            }

                        })}
                    </Switch>
                    {script_tag_element}
                </main>
            </Fragment>
        );
    }
}

function mapStateToProps({content, settings, router}) {
    const {languages, permanents, pages, siteRoot} = content;
    const {defaultLanguage, locale} = settings;
    const {homePage} = router;
    const availableLanguages = languages[contentConstants.CMS_AVAILABLE_LANGUAGES];


    return {
        languages,
        availableLanguages,
        defaultLanguage,
        permanents,
        pages,
        homePage,
        siteRoot,
        currentLanguage: locale,
        currentHomePage: (!!homePage && homePage[locale]),
    }
}


export default connect(mapStateToProps)(Thewom);
