import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import TagManager from 'react-gtm-module';
import {Route, Switch, withRouter} from "react-router-dom";

import {componentsConstants, contentConstants} from '_constants';

import {TheWomLoader} from "components";
import {generalHelpers, uiHelpers} from "_helpers";
import {Helmet} from "react-helmet";

class Page extends Component {
    componentDidMount() {
        const {location, currentLanguage} = this.props;
        uiHelpers.scrollNavBarChange(location, currentLanguage);

        const tagManagerArgs = {
            dataLayer: {
                userId: '001',
                userProject: 'Stay Wom',
                page: location.pathname
            },
            dataLayerName: 'PageDataLayer'
        }
        TagManager.dataLayer(tagManagerArgs);
    }

    getComponentsInOrder = (pageComponents, pageSlug) => (
        pageComponents && pageComponents.map((component, index) => {
            const {components} = this.props;
            const RenderComp = componentsConstants.COMPONENTS_LIBRARY[component.type] || TheWomLoader;

            return (
                componentsConstants.COMPONENTS_LIBRARY.hasOwnProperty(component.type) ?
                    <RenderComp
                        id={`${pageSlug}-${component.type}-${index}`}
                        componentId={component.id}
                        componentContent={components[component.id]}
                        componentType={
                            components[component.id] &&
                            components[component.id][contentConstants.CMS_ITEM_TYPE]
                        }
                        componentContentReady={
                            components[component.id] &&
                            components[component.id][contentConstants.COMPONENT_CONTENT_READY]
                        }
                        key={index}/>
                    :
                    ''
            )
        })
    );


    pageBuilder = (pageComponents, pageSlug) => {
        return this.getComponentsInOrder(pageComponents, pageSlug);
    };

    render() {
        const {pages, pageId, collectionsIndexes, indexesItems, match} = this.props;

        if (!pages || !pages[pageId]) {
            return '';
        }

        const pageContent = pages[pageId];
        const pageComponents = pageContent.components;
        let pageSlug = pageContent.meta.slug;
        let pagesCollection;


        // This nested object is available only if all the content have been arrived
        // So we have a lot of checks to prevent crash.
        if (pages[pageId].hasOwnProperty('pages_index')) {
            generalHelpers.print("pages pages_index", pages[pageId]);
            if (collectionsIndexes[pages[pageId].pages_index.id]) {
                if (collectionsIndexes[pages[pageId].pages_index.id][contentConstants.SORT_TYPE_ITEMS]) {
                    pagesCollection = collectionsIndexes[pages[pageId].pages_index.id][contentConstants.SORT_TYPE_ITEMS].map(pageItem => {
                        return indexesItems[pageItem.id]
                    });
                }
            }
        }

        return (
            <Fragment>
                <Helmet>
                    <title>{pageContent.page_title || "Stay Wom"}</title>
                    {pageContent.description && <meta name="description" content={pageContent.description}/>}
                    {pageContent.keywords && <meta name="keywords" content={pageContent.keywords}/>}
                </Helmet>
                <Switch>
                    {/*
                        What we do here is taking care of nested pages to load if the route
                        is not exactly matches
                    */}

                    {
                        pagesCollection && pagesCollection.map(page => {
                            // index_item_page is
                            if (!page || !page.index_item_page) return '';

                            return (
                                <Route key={page.slug || page.meta.slug}
                                       exact path={`${match.url}/${page.slug || page.meta.slug}`}
                                       render={() => {
                                           const pageContent = pages[page.index_item_page.id];
                                           if (pageContent) {
                                               return this.pageBuilder(pageContent.components, page.slug || page.meta.slug);
                                           } else {
                                               return 'Page not found (404)'
                                           }

                                       }}/>
                            )
                        })
                    }
                    <Route path={match.url} render={() => {
                        return this.pageBuilder(pageComponents, pageSlug)
                    }}/>
                </Switch>
            </Fragment>
        )
    }
}

function mapStateToProps({content, settings}) {
    const {pages, collectionsIndexes, indexesItems, components} = content;
    const {locale} = settings;
    return {
        pages,
        collectionsIndexes,
        indexesItems,
        components,
        currentLanguage: locale,
    }
}

const connectedPage = withRouter(connect(mapStateToProps)(Page));
export {connectedPage as Page};
