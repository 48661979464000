import {generalService} from "_services";
import {contentConstants, generalConstants} from "_constants";
import axios from "axios";

// Public Function
export const contentService = {
    getDescendant,

    getAllSitePages,

    sendLead,
    // getContent,
    getContentRoot,
    getLanguageDetails,
    // getLanguageContent,
    getIndexItemDetailedContent,

    getContentByLanguage,
};

function getAllSitePages() {
    return generalService.requestSender(
        {
            type: 'thewom.TheWomPage',
        }
    )
}

function sendLead(email) {
    const requestOptions = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        data: {
            email
        }
    };

    return axios(
        `${generalConstants.DOMAIN}/api/utils/leads`,
        requestOptions
    )
        .then(generalService.handleAxiosResponse)
        .then(response => {
            return response;
        });
}


// Refactor from TheWomApp

function getContentByLanguage(language){
    return generalService.requestSender(
        {
            lang: language,
            limit: 1000
        }, contentConstants.CONTENT_BY_LANGUAGE_POSTFIX
    )
}

function getContentRoot() {
    return getRoot();
}

function getLanguageDetails(languageId) {
    const permanentsParams = {
        // fields: '-home_page'
    };

    return getDetailedContent(languageId, permanentsParams);
}

// function getLanguageContent(languageId) {
//     const requestParams = {
//         limit: 1000,
//         // type: [
//         //     contentConstants.CMS_PERMANENT_TYPE,
//         //     contentConstants.CMS_PAGE_TYPE,
//         //     contentConstants.CMS_COMPONENT_TYPE,
//         //     contentConstants.CMS_COLLECTION_TYPE,
//         //     contentConstants.CMS_COLLECTION_INDEX_TYPE,
//         //     contentConstants.CMS_COLLECTION_ITEM_TYPE,
//         //     // ...contentConstants.CMS_COLLECTION_TYPES,
//         //     // ...contentConstants.CMS_PAGE_TYPES,
//         //     // ...contentConstants.CMS_INDEX_TYPES,
//         //     // ...contentConstants.CMS_ITEM_TYPES,
//         // ].join(',')
//     };

//     return getDescendant(languageId, requestParams);
// }

function getIndexItemDetailedContent(itemId) {
    const permanentsParams = {
        fields: '*'
    };

    return getDetailedContent(itemId, permanentsParams);
}



// Private functions:

function getRoot() {
    return generalService.requestSender(
        {
            'html_path': '/'
        }, `${generalConstants.API_PAGES_POSTFIX}/contentConstants.ROOT_ENDPOINT_POSTFIX`)
}

// Get Direct children
function getChildren(id, params) {
    return generalService.requestSender(
        {
            'child_of': id,
            ...params
        }, `${generalConstants.API_PAGES_POSTFIX}`
    )
}

// Get All Descendant of id, not only direct children
function getDescendant(id, params) {
    return generalService.requestSender(
        {
            'descendant_of': id,
            ...params
        }, `${generalConstants.API_PAGES_POSTFIX}`
    )
}

// Get Object Detailed fields.
function getDetailedContent(id, params) {
    return generalService.requestSender(
        {
            ...params
        }, `${generalConstants.API_PAGES_POSTFIX}/${id}`
    )
}
