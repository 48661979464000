export const generalHelpers = {
    print,
    goOneUp,
    checkLabelTitleLength,
    loadScript,
};

function print(title, log) {
    if (process.env.NODE_ENV === 'development') {
        console.log(title, log)
    }
}

function goOneUp(history) {
    const URI = history.location.pathname;
    const splitURI = URI.split('/');
    const oneUpArray = splitURI.splice(0, splitURI.length - 1);
    let oneUp = '';

    for (const uriPart of oneUpArray) {
        if (uriPart !== '') {
            oneUp += `/${uriPart}`;
        }
    }
    history.push(oneUp);
}

function checkLabelTitleLength(title) {
    let newTitle = title;

    if (newTitle && newTitle.length > 35) {
        newTitle = `${newTitle.slice(0, 34)}...`
    }

    return newTitle;
}

function loadScript(src, attributes = {}) {
    // Create a script tag
    let script = document.createElement("script");

    for (const key in attributes) {
        if (attributes.hasOwnProperty(key)) {
            script.setAttribute(key, attributes[key]);
        }
    }

    // Set the CDN for the simplex script
    script.src = src;
    script.async = true;
    // Append the script to the end of the HTML-document head
    document.getElementsByTagName("head")[0].appendChild(script);
}