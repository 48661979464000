export const contentConstants = {
    // ROOT_ENDPOINT_POSTFIX: 'find/',

    CMS_SITE_TYPE: 'containers.SiteContainer',
    // CMS_LANGUAGE_TYPE: 'containers.LanguageContainer',
    // CMS_PERMANENT_TYPE: 'containers.Permanent',
    // CMS_PAGE_TYPE: 'thewom.TheWomPage',
    // CMS_COMPONENT_TYPE: 'thewom.TheWomComponent',
    // CMS_COLLECTION_TYPE: 'collection.CollectionPage',
    // CMS_COLLECTION_INDEX_TYPE: 'collection.CollectionIndex',
    // CMS_COLLECTION_ITEM_TYPE: 'collection.CollectionItem',

    // CMS_HOTELS_COLLECTION_TYPE: 'collection.HotelsCollection',

    CMS_MENU_TYPE: 'containers.Navigation',
    CMS_TOP_NAV_TYPE: 'containers.TopBar',
    CMS_FOOTER_TYPE: 'containers.Footer',

    // CHANGE_CURRENT_LANGUAGE: 'CHANGE_CURRENT_LANGUAGE',

    // FETCH_COMPLETED: 'FETCH_COMPLETED',


    // GET_CONTENT_ROOT_REQUEST: 'GET_CONTENT_ROOT_REQUEST',
    // GET_CONTENT_ROOT_SUCCESS: 'GET_CONTENT_ROOT_SUCCESS',
    // GET_CONTENT_ROOT_FAILURE: 'GET_CONTENT_ROOT_FAILURE',

    GET_LANGUAGES_REQUEST: 'GET_LANGUAGES_REQUEST',
    GET_LANGUAGES_SUCCESS: 'GET_LANGUAGES_SUCCESS',
    GET_LANGUAGES_FAILURE: 'GET_LANGUAGES_FAILURE',

    GET_CONTENT_BY_LANGUAGE_REQUEST: 'GET_CONTENT_BY_LANGUAGE_REQUEST',
    GET_CONTENT_BY_LANGUAGE_SUCCESS: 'GET_CONTENT_BY_LANGUAGE_SUCCESS',
    GET_CONTENT_BY_LANGUAGE_FAILURE: 'GET_CONTENT_BY_LANGUAGE_FAILURE',

    FILL_LANGUAGE_CONTENT_REQUEST: 'FILL_LANGUAGE_CONTENT_REQUEST',
    FILL_LANGUAGE_CONTENT_SUCCESS: 'FILL_LANGUAGE_CONTENT_SUCCESS',
    FILL_LANGUAGE_CONTENT_FAILURE: 'FILL_LANGUAGE_CONTENT_FAILURE',

    GET_LANGUAGE_PERMANENTS_REQUEST: 'GET_LANGUAGE_PERMANENTS_REQUEST',
    GET_LANGUAGE_PERMANENTS_SUCCESS: 'GET_LANGUAGE_PERMANENTS_SUCCESS',
    GET_LANGUAGE_PERMANENTS_FAILURE: 'GET_LANGUAGE_PERMANENTS_FAILURE',

    GET_LANGUAGE_PAGES_REQUEST: 'GET_LANGUAGE_PAGES_REQUEST',
    GET_LANGUAGE_PAGES_SUCCESS: 'GET_LANGUAGE_PAGES_SUCCESS',
    GET_LANGUAGE_PAGES_FAILURE: 'GET_LANGUAGE_PAGES_FAILURE',

    GET_LANGUAGE_COLLECTIONS_REQUEST: 'GET_LANGUAGE_COLLECTIONS_REQUEST',
    GET_LANGUAGE_COLLECTIONS_SUCCESS: 'GET_LANGUAGE_COLLECTIONS_SUCCESS',
    GET_LANGUAGE_COLLECTIONS_FAILURE: 'GET_LANGUAGE_COLLECTIONS_FAILURE',

    GET_PERMANENT_CONTENT_REQUEST: 'GET_PERMANENT_CONTENT_REQUEST',
    GET_PERMANENT_CONTENT_SUCCESS: 'GET_PERMANENT_CONTENT_SUCCESS',
    GET_PERMANENT_CONTENT_FAILURE: 'GET_PERMANENT_CONTENT_FAILURE',

    GET_PAGE_CONTENT_REQUEST: 'GET_PAGE_CONTENT_REQUEST',
    GET_PAGE_CONTENT_SUCCESS: 'GET_PAGE_CONTENT_SUCCESS',
    GET_PAGE_CONTENT_FAILURE: 'GET_PAGE_CONTENT_FAILURE',

    GET_COLLECTION_CONTENT_REQUEST: 'GET_COLLECTION_CONTENT_REQUEST',
    GET_COLLECTION_CONTENT_SUCCESS: 'GET_COLLECTION_CONTENT_SUCCESS',
    GET_COLLECTION_CONTENT_FAILURE: 'GET_COLLECTION_CONTENT_FAILURE',

    GET_COLLECTION_PAGE_REQUEST: 'GET_COLLECTION_PAGE_REQUEST',
    GET_COLLECTION_PAGE_SUCCESS: 'GET_COLLECTION_PAGE_SUCCESS',
    GET_COLLECTION_PAGE_FAILURE: 'GET_COLLECTION_PAGE_FAILURE',

    GET_COLLECTION_INDEXES_REQUEST: 'GET_COLLECTION_INDEXES_REQUEST',
    GET_COLLECTION_INDEXES_SUCCESS: 'GET_COLLECTION_INDEXES_SUCCESS',
    GET_COLLECTION_INDEXES_FAILURE: 'GET_COLLECTION_INDEXES_FAILURE',

    GET_INDEX_CONTENT_REQUEST: 'GET_INDEX_CONTENT_REQUEST',
    GET_INDEX_CONTENT_SUCCESS: 'GET_INDEX_CONTENT_SUCCESS',
    GET_INDEX_CONTENT_FAILURE: 'GET_INDEX_CONTENT_FAILURE',

    GET_INDEX_ITEMS_REQUEST: 'GET_INDEX_ITEMS_REQUEST',
    GET_INDEX_ITEMS_SUCCESS: 'GET_INDEX_ITEMS_SUCCESS',
    GET_INDEX_ITEMS_FAILURE: 'GET_INDEX_ITEMS_FAILURE',

    GET_ITEM_CONTENT_REQUEST: 'GET_ITEM_CONTENT_REQUEST',
    GET_ITEM_CONTENT_SUCCESS: 'GET_ITEM_CONTENT_SUCCESS',
    GET_ITEM_CONTENT_FAILURE: 'GET_ITEM_CONTENT_FAILURE',

    GET_ITEM_PAGE_REQUEST: 'GET_ITEM_PAGE_REQUEST',
    GET_ITEM_PAGE_SUCCESS: 'GET_ITEM_PAGE_SUCCESS',
    GET_ITEM_PAGE_FAILURE: 'GET_ITEM_PAGE_FAILURE',

    GET_PAGE_COMPONENTS_REQUEST: 'GET_PAGE_COMPONENTS_REQUEST',
    GET_PAGE_COMPONENTS_SUCCESS: 'GET_PAGE_COMPONENTS_SUCCESS',
    GET_PAGE_COMPONENTS_FAILURE: 'GET_PAGE_COMPONENTS_FAILURE',

    GET_COMPONENT_CONTENT_REQUEST: 'GET_COMPONENT_CONTENT_REQUEST',
    GET_COMPONENT_CONTENT_SUCCESS: 'GET_COMPONENT_CONTENT_SUCCESS',
    GET_COMPONENT_CONTENT_FAILURE: 'GET_COMPONENT_CONTENT_FAILURE',

    GET_ALL_PAGE_CONTENT_REQUEST: 'GET_ALL_PAGE_CONTENT_REQUEST',
    GET_ALL_PAGE_CONTENT_SUCCESS: 'GET_ALL_PAGE_CONTENT_SUCCESS',
    GET_ALL_PAGE_CONTENT_FAILURE: 'GET_ALL_PAGE_CONTENT_FAILURE',

    GET_HOME_PAGE_COMPONENTS_SUM_REQUEST: 'GET_HOME_PAGE_COMPONENTS_SUM_REQUEST',
    GET_HOME_PAGE_COMPONENTS_SUM_SUCCESS: 'GET_HOME_PAGE_COMPONENTS_SUM_SUCCESS',
    GET_HOME_PAGE_COMPONENTS_SUM_FAILURE: 'GET_HOME_PAGE_COMPONENTS_SUM_FAILURE',


    SEND_LEAD_REQUEST: 'SEND_LEAD_REQUEST',
    SEND_LEAD_SUCCESS: 'SEND_LEAD_SUCCESS',
    SEND_LEAD_FAILURE: 'SEND_LEAD_FAILURE',

    SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
    SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
    SEND_EMAIL_FAILURE: 'SEND_EMAIL_FAILURE',

    NAVIGATION_PROPERTIES: {
        LOGO: 'logo',
        BACKGROUND_IMAGE: 'background_image',
        BACKGROUND_COLOR: 'background_color',
        NAVIGATION_LINKS: 'navigation_links',
        NAVIGATION_LINK_LINK: 'link',
        NAVIGATION_LINK_LABEL: 'headline',
        SOCIAL_LINKS: 'social_links',
        SOCIAL_LINK_ICON: 'icon',
        SOCIAL_LINK_LINK: 'url',
    },

    TOP_BAR_PROPERTIES: {
        LOGO: 'logo',
        BUTTON_LABEL: 'button_label'
    },

    EXPERIENCE_PAGE: 'collection.ExperiencesItem',
    EVENT_PAGE: 'collection.EventsItem',
    BLOG_PAGE: 'collection.BlogPost',

    SET_HOME_PAGE: 'SET_HOME_PAGE',
    SAVE_LANGUAGE_DETAILS: 'SAVE_LANGUAGE_DETAILS',
    SAVE_SITE_ROOT_DETAILS: 'SAVE_SITE_ROOT_DETAILS',


    // Refactor from TheWomApp

    ROOT_ENDPOINT_POSTFIX: '/find',
    CONTENT_BY_LANGUAGE_POSTFIX: 'custom',
    SITE_ROOT: 'siteRoot',
    CONTENT_LOADING_COMPLETED: 'CONTENT_LOADING_COMPLETED',

    CMS_DEFAULT_LANGUAGE_KEY: 'default_language',
    CMS_CURRENT_LANGUAGE: 'currentLanguage',
    CMS_AVAILABLE_LANGUAGES: 'CMS_AVAILABLE_LANGUAGES',

    // CMS_SITE_TYPE: 'containers.SiteContainer',
    CMS_LANGUAGE_TYPE: 'containers.LanguageContainer',
    CMS_PERMANENT_TYPE: 'containers.Permanent',
    CMS_PAGE_TYPE: 'thewom.TheWomPage',
    CMS_COMPONENT_TYPE: 'thewom.TheWomComponent',
    CMS_COLLECTION_TYPE: 'collection.CollectionPage',
    CMS_COLLECTION_INDEX_TYPE: 'collection.CollectionIndex',
    CMS_COLLECTION_ITEM_TYPE: 'collection.CollectionItem',

    CMS_BLOG_COLLECTION_TYPE: 'collection.BlogCollection',
    CMS_EVENTS_COLLECTION_TYPE: 'collection.EventsCollection',
    CMS_EXPERIENCES_COLLECTION_TYPE: 'collection.ExperiencesCollection',
    CMS_HOTELS_COLLECTION_TYPE: 'collection.HotelsCollection',
    CMS_PLACES_COLLECTION_TYPE: 'collection.PlacesLinks',

    CMS_BLOG_PAGE_TYPE: 'collection.BlogIntro',
    CMS_EVENTS_PAGE_TYPE: 'collection.EventsIntro',
    CMS_EXPERIENCES_PAGE_TYPE: 'collection.ExperiencesIntro',
    CMS_HOTELS_PAGE_TYPE: 'collection.HotelsIntro',

    CMS_BLOG_INDEX_TYPE: 'collection.BlogIndex',
    CMS_EVENTS_INDEX_TYPE: 'collection.EventsIndex',
    CMS_EXPERIENCES_INDEX_TYPE: 'collection.ExperiencesIndex',
    CMS_HOTELS_INDEX_TYPE: 'collection.HotelsIndex',
    CMS_PLACES_INDEX_TYPE: 'collection.PlacesLinksIndex',

    CMS_EXPERIENCES_ITEM_TYPE: 'collection.ExperiencesItem',
    CMS_EVENTS_ITEM_TYPE: 'collection.EventsItem',
    CMS_HOTELS_ITEM_TYPE: 'collection.HotelsItem',
    CMS_BLOG_ITEM_TYPE: 'collection.BlogPost',
    CMS_PLACES_ITEM_TYPE: 'collection.PlacesLinksItem',

    CMS_PERMANENTS_TYPES: [
        'containers.TopBar',
        'containers.Navigation',
        'containers.Footer',
    ],

    CMS_COLLECTION_TYPES: [
        'collection.BlogCollection',
        'collection.EventsCollection',
        'collection.ExperiencesCollection',
        'collection.HotelsCollection',
        'collection.RoomsCollection',
    ],

    CMS_PAGE_TYPES: [
        'thewom.TheWomPage',
        'collection.HotelPage',
        'collection.BlogIntro',
        'collection.EventsIntro',
        'collection.ExperiencesIntro',
        'collection.RoomsIntro',
        'collection.HotelsIntro',
    ],

    CMS_COMPONENT_TYPES: [
        'thewom.BackgroundWithButton',
        'thewom.DetailsByHoverContainer',
        'thewom.EmailSubscribe',
        'thewom.FAQ',
        'thewom.HeadlineWithText',
        'thewom.HeaderHome',
        'thewom.HeaderCenterText',
        'thewom.HeaderOneImageWithParagraph',
        'thewom.HeaderTwoParagraphsTwoImages',
        'thewom.HeaderTwoHeadlinesWithBackgroundImage',
        'thewom.HeaderHeadlinesWithImage',
        'thewom.HeaderTwoParagraphs',
        'thewom.ImageCarousel',
        'thewom.Instagram',
        // 'thewom.IFrameSection',
        'thewom.Locations',
        'thewom.TextParagraph',
        'thewom.TwoImagesWithLabelCarousel',
        'thewom.TermsAndConditions',
        'thewom.WOMs',
        'thewom.SimplexComp',
        'thewom.CollectionPage',
        'collection.ExperiencesGallery',
        'collection.EventsGallery',
        'collection.BlogGallery',
        'collection.RoomsGallery',
        'collection.RoomsNavigation',
        'collection.HotelsGallery',
        'collection.HotelDetails',
        'collection.HotelRooms',
        'collection.HotelLocation',
        'collection.HotelPublicPlacesCarousel',
    ],

    CMS_INDEX_TYPES: [
        'collection.BlogIndex',
        'collection.EventsIndex',
        'collection.ExperiencesIndex',
        'collection.HotelsIndex',
        'collection.RoomsIndex',
    ],

    CMS_ITEM_TYPES: [
        'collection.ExperiencesItem',
        'collection.EventsItem',
        'collection.BlogPost',
        'collection.RoomsItem',
        'collection.HotelsItem',
    ],

    CMS_FETCH_ON_START_TYPES: [
        'thewom.TheWomPage',
        'collection.HotelsItem',
        'collection.RoomsItem',
        'collection.EventsItem',
        'collection.ExperiencesItem',
        'collection.BlogPost',
        'collection.BlogIntro',
        'collection.EventsIntro',
        'collection.ExperiencesIntro',
        'collection.HotelsIntro',
        'collection.RoomsIntro',
    ],

    SORT_TO_TYPE: 'SORT_TO_TYPE',
    SORT_TO_PARENT: 'SORT_TO_PARENT',

    SORT_TYPE_LANGUAGES: 'languages',
    SORT_TYPE_PERMANENTS: 'permanents',
    SORT_TYPE_COLLECTIONS: 'collections',
    SORT_TYPE_INDEXES: 'collectionsIndexes',
    SORT_TYPE_PAGES: 'pages',
    SORT_TYPE_ITEMS: 'indexesItems',
    SORT_TYPE_ITEM_CONTENT: 'itemContent',
    SORT_TYPE_COMPONENTS: 'components',

    SORT_COMPLETED: 'SORT_COMPLETED',
    HOME_PAGE_FETCH_CONTENT_COMPLETED: 'HOME_PAGE_FETCH_CONTENT_COMPLETED',
    SORT_CONTENT_COMPLETED: 'SORT_CONTENT_COMPLETED',
    GET_CONTENT_COMPLETED: 'GET_CONTENT_COMPLETED',

    CONTENT_SORT_COMPLETED: 'CONTENT_SORT_COMPLETED',
    CONTENT_FETCH_COMPLETED: 'CONTENT_FETCH_COMPLETED',
    HOME_PAGE_COMPONENTS_SUM: 'HOME_PAGE_COMPONENTS_SUM',

    CHANGE_CURRENT_LANGUAGE: 'CHANGE_CURRENT_LANGUAGE',

    FETCH_COMPLETED: 'FETCH_COMPLETED',
    COMPONENT_CONTENT_READY: 'COMPONENT_CONTENT_READY',
    COMPONENT_CONTENT_UPDATED_AT: 'COMPONENT_CONTENT_UPDATED_AT',
    CMS_ITEM_TYPE: 'CMS_ITEM_TYPE',

    GET_CONTENT_ROOT_REQUEST: 'GET_CONTENT_ROOT_REQUEST',
    GET_CONTENT_ROOT_SUCCESS: 'GET_CONTENT_ROOT_SUCCESS',
    GET_CONTENT_ROOT_FAILURE: 'GET_CONTENT_ROOT_FAILURE',

    GET_LANGUAGE_DETAILS_REQUEST: 'GET_LANGUAGE_DETAILS_REQUEST',
    GET_LANGUAGE_DETAILS_SUCCESS: 'GET_LANGUAGE_DETAILS_SUCCESS',
    GET_LANGUAGE_DETAILS_FAILURE: 'GET_LANGUAGE_DETAILS_FAILURE',

    GET_LANGUAGE_CONTENT_REQUEST: 'GET_LANGUAGE_CONTENT_REQUEST',
    GET_LANGUAGE_CONTENT_SUCCESS: 'GET_LANGUAGE_CONTENT_SUCCESS',
    GET_LANGUAGE_CONTENT_FAILURE: 'GET_LANGUAGE_CONTENT_FAILURE',

    GET_INDEX_ITEM_CONTENT_REQUEST: 'GET_INDEX_ITEM_CONTENT_REQUEST',
    GET_INDEX_ITEM_CONTENT_SUCCESS: 'GET_INDEX_ITEM_CONTENT_SUCCESS',
    GET_INDEX_ITEM_CONTENT_FAILURE: 'GET_INDEX_ITEM_CONTENT_FAILURE',

    // CMS_ITEM_EVENTS_SLIDE_OBJECT_KEY: 'gallery_view',
    // CMS_ITEM_EVENTS_SLIDE_OBJECT_IMAGE_KEY: 'image',
    // CMS_ITEM_EVENTS_SLIDE_OBJECT_LABEL_KEY: 'label',
    //
    // CMS_ITEM_IMAGE_OVERLAY_TEXT_SLIDE_OBJECT_KEY: 'gallery_view',
    // CMS_ITEM_IMAGE_OVERLAY_TEXT_SLIDE_OBJECT_IMAGE_KEY: 'image',
    // CMS_ITEM_IMAGE_OVERLAY_TEXT_SLIDE_OBJECT_LABEL_KEY: 'label',
    //
    // CMS_ITEM_LOCATION_SLIDE_OBJECT_KEY: 'two_images_with_label',
    // CMS_ITEM_LOCATION_SLIDE_OBJECT_IMAGE_KEY: 'image_left',
    // CMS_ITEM_LOCATION_SLIDE_OBJECT_LABEL_KEY: 'label',
    //
    // CMS_ITEM_BLOG_SLIDE_OBJECT_KEY: 'gallery_view',
    // CMS_ITEM_BLOG_SLIDE_OBJECT_IMAGE_KEY: 'image',
    // CMS_ITEM_BLOG_SLIDE_OBJECT_LABEL_KEY: 'label',
    // CMS_ITEM_BLOG_SLIDE_OBJECT_LOCATION_KEY: 'location_label',
    //
    // CMS_PLACES_INDEX_TITLE: 'title',
    // CMS_ITEM_PLACES_SLIDE_OBJECT_KEY: 'itemContent',
    // CMS_ITEM_PLACES_SLIDE_OBJECT_TITLE: 'title',
    // CMS_ITEM_PLACES_SLIDE_OBJECT_HEADLINE: 'headline',
    // CMS_ITEM_PLACES_SLIDE_OBJECT_LINK: 'link',
    // CMS_ITEM_PLACES_SLIDE_OBJECT_RATING: 'rating',
    // CMS_ITEM_PLACES_SLIDE_OBJECT_ADDRESS: 'address',
    // CMS_ITEM_PLACES_SLIDE_OBJECT_IMAGE: 'image',
};
