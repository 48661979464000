import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";

import {converter} from '_helpers'
import {contentActions} from "../../_actions";
import {defaultConstants} from "../../_constants";

class BackgroundWithButton extends Component {
    componentDidMount() {
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if (!componentContentReady) {
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {

        const {pages, componentContent, componentContentReady, currentHomePage, locale} = this.props;

        if (componentContentReady && componentContent && componentContent.page_link && pages[componentContent.page_link.id]) {
            return (
                <section className={'background-with-button-section wide'}>
                    <Fragment>
                        <div className={'background-image'}
                             style={{backgroundImage: `url(${converter.getImageDownloadURL(componentContent.background_image)})`}}/>
                        {componentContent.page_link ?
                            <Link
                                to={
                                    componentContent.page_link.id === currentHomePage.id
                                        ?
                                        `/${locale}`
                                        :
                                        `/${locale}/${pages[componentContent.page_link.id].meta.slug}`}
                                className={'the-wom-btn dark-button book-btn'}>{componentContent.button_text}
                            </Link>
                            :
                            <a className={'the-wom-btn dark-button book-btn'}
                               href={componentContent.external_url} target={'_blank'}>{componentContent.button_text}</a>
                        }
                    </Fragment>
                </section>)
        } else {
            return '';
        }
    }
}

function mapStateToProps({content, settings, router}) {
    const {pages} = content;
    const {locale} = settings;
    const {homePage} = router;
    return {
        pages,
        locale,
        currentHomePage: (!!homePage && homePage[locale]) || defaultConstants.defaultHomeSlug,
    }
}

const connectedPage = withRouter(connect(mapStateToProps)(BackgroundWithButton));
export {connectedPage as BackgroundWithButton};




