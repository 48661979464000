import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';

import {SingleContactHotel} from "./SingleContactHotel";
import {ContactUsModal} from "./ContactUsModal";
import {contentActions} from "../../_actions";
import {contentConstants} from "../../_constants";

class ContactHotels extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent, collectionsIndexes, indexesItems} = this.props;

        let hotelCollection = [];

        if (
            componentContentReady
            && componentContent
            && collectionsIndexes
            && collectionsIndexes[componentContent.collection.id]
            && collectionsIndexes[componentContent.collection.id][contentConstants.SORT_TYPE_ITEMS]) {

            const componentCollectionIndex = collectionsIndexes[componentContent.collection.id][contentConstants.SORT_TYPE_ITEMS];

            hotelCollection = componentCollectionIndex.map(item => (
                indexesItems[item.id]
            ))
        }


        return (
            componentContentReady && hotelCollection ?
                <Fragment>
                    <section className={'contact-hotels-section wide'}>
                        {Array.isArray(hotelCollection) && hotelCollection.map((hotel, index, array) => {
                            if (hotel && hotel.hotel_contact_info[0]) {
                                return (
                                    <SingleContactHotel content={hotel.hotel_contact_info[0]} key={hotel.id} last={index === array.length - 1}/>
                                )
                            } else {
                                return '';
                            }

                        })}
                    </section>
                    <ContactUsModal content={componentContent}/>
                </Fragment>
                : ''
        );
    }
}

function mapStateToProps({content}) {
    const {collectionsIndexes, indexesItems} = content;
    return {
        collectionsIndexes,
        indexesItems,
    }
}

const connectedPage = connect(mapStateToProps)(ContactHotels);
export {connectedPage as ContactHotels};
