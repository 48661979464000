import React, {Component} from "react";
import {connect} from 'react-redux';

class HeadlineBig extends Component {
    render() {
        const {content} = this.props;

        return (
            content ?
                <section className={'article-headline-big collection-item-center'}>
                    <div className='section-title'>
                        {content}
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({content}) {
    const {components, collectionsIndexes, indexesItems} = content;
    return {
        components,
        collectionsIndexes,
        indexesItems,
    }
}

const connectedPage = connect(mapStateToProps)(HeadlineBig);
export {connectedPage as HeadlineBig};




