import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import {contentActions} from "../../_actions";

class HeadlineWithText extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady, content} = this.props;

        if(!componentContentReady && !content){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent, content} = this.props;

        const renderContent = componentContent || content;

        return (
            <section className={'headline-with-text center-narrow'}>
                {componentContentReady && renderContent ?
                    <Fragment>
                        <h3 className={'headline section-title'}>{renderContent.headline}</h3>
                        <p className={'text paragraph'} style={{textAlign: renderContent.text_align}}>{renderContent.paragraph || renderContent.text}</p>
                    </Fragment>
                    : ''
                }
            </section>
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = connect(mapStateToProps)(HeadlineWithText);
export {connectedPage as HeadlineWithText};
