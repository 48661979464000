import React, {Component} from "react";
import {connect} from 'react-redux';

class TwoImagesHorizontal extends Component {
    render() {
        const {content} = this.props;

        return (
            <div className={'header-images'}>
                <div className={'img img-left'}
                     style={{backgroundImage: `url(${content.image_left})`}}/>
                <div className={'img img-right'}
                     style={{backgroundImage: `url(${content.image_right})`}}/>
            </div>
        );
    }
}

function mapStateToProps() {
    return {

    }
}

const connectedPage = connect(mapStateToProps)(TwoImagesHorizontal);
export {connectedPage as TwoImagesHorizontal};




