import React, {Component} from "react";
import {connect} from 'react-redux';

class HeadlineSmall extends Component {
    render() {
        const {content} = this.props;

        return (
            content ?
                <section className='article-headline-small collection-item-center'>
                    <div className='section-subtitle'>
                        {content}
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({content}) {
    const {components, collectionsIndexes, indexesItems} = content;
    return {
        components,
        collectionsIndexes,
        indexesItems,
    }
}

const connectedPage = connect(mapStateToProps)(HeadlineSmall);
export {connectedPage as HeadlineSmall};




