import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import {IntlProvider} from "react-intl";

import AppLocale from "lang";
import MainRoute from "routes";

import "assets/css/vendor/bootstrap.min.css";
import "assets/css/sass/theme/thewom.scss";
import {store} from "_helpers";

import {contentActions} from "_actions";
import {contentConstants, defaultConstants} from "../_constants";
import {BlogPost, CollectionItem, TheWomLoader} from 'components';

class App extends Component {
    constructor(props){
        super(props);

        this.state = {
            renderAllApp: false,
            searchParamsFinish: false,
            type: '',
            id: ''
        }
    }

    componentDidMount() {
        // Get Query Params (in case app loaded from StayWOM Mobile app)
        const url = new URL(window.location.href);

        const type = url.searchParams.get('type');
        const id = url.searchParams.get('id');

        if(type && id){
            this.setState(
                {
                    searchParamsFinish: true,
                    id: id,
                    type: type
                }
            )
        } else {
            this.setState({searchParamsFinish: true, renderAllApp: true});
            // Collect all the content of the page with relations for each page to another.
            store.dispatch(contentActions.getContentByLanguage(defaultConstants.defaultLocale, this.props.history));
        }
    }

    renderMobilePage(){
        const {type, id} = this.state;

        switch (type) {
            case contentConstants.EXPERIENCE_PAGE:
                return (
                    <CollectionItem
                        componentId={id}
                    />
                );
            case contentConstants.EVENT_PAGE:
                return (
                    <CollectionItem
                        componentId={id}
                    />
                );
            case contentConstants.BLOG_PAGE:
                return (
                    <BlogPost
                        componentId={id}
                        compType={'Blog'}
                    />
                );
            default:
                return ''
        }
    }

    render() {
        const {match, locale, fetchCompleted} = this.props;
        const currentAppLocale = AppLocale[locale];

        const {searchParamsFinish, renderAllApp} = this.state;

        return (
            searchParamsFinish
                ?
                (renderAllApp && fetchCompleted)
                    ?
                    <Fragment>
                        <IntlProvider
                            locale={currentAppLocale.locale}
                            messages={currentAppLocale.messages}>
                            <Fragment>
                                {/*<Main application*/}
                                <Switch>
                                    <Route path={`${match.url}`} component={MainRoute}/>
                                </Switch>
                            </Fragment>
                        </IntlProvider>
                    </Fragment>
                    :
                    <div id="app-container">
                        <main style={{margin: '0 auto'}}>
                            {
                                this.renderMobilePage()
                            }
                        </main>
                    </div>
                :
                <TheWomLoader/>
        );
    }
}

const mapStateToProps = ({settings, content}) => {
    const {locale} = settings;

    return {
        locale,
        fetchCompleted: content[contentConstants.SITE_ROOT] &&
            content[contentConstants.SITE_ROOT][contentConstants.CONTENT_SORT_COMPLETED]
    };
};

export default withRouter(connect(
    mapStateToProps
)(App));
