import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import {store} from '_helpers';

import App from 'containers/App';

const MainApp = () => {
    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route path="/" component={App}/>
                </Switch>
            </Router>
        </Provider>
    );
};


export default MainApp;