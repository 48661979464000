import React, {Component} from "react";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";


class CollectionDetails extends Component {
    render() {
        const {componentContent} = this.props;

        return (
            componentContent ?
                <section className={'collection-details collection-item-center'}>
                    <div className='collection-details-headline'>
                        <div className={'section-title'}>{componentContent.details_headline}</div>
                    </div>
                    <div className='paragraph-and-details d-flex flex-wrap justify-content-between align-items-start'>
                        <p className={'paragraph'} dangerouslySetInnerHTML={{__html: componentContent.details_text}}>
                            {/*{componentContent.details_text}*/}
                        </p>
                        {componentContent.details_list && componentContent.details_list.length > 0 ?
                            <div className={'details-list right d-flex flex-wrap align-items-center'}
                                 style={{backgroundColor: componentContent.background_color}}>
                                {
                                    componentContent.details_list && componentContent.details_list.map(item => (
                                        <div key={item.id} className='details-list-item d-flex align-items-baseline'>
                                            <div className='title section-subtitle'>{item.title}</div>
                                            <div className='description' dangerouslySetInnerHTML={{__html: item.info}}/>
                                        </div>
                                    ))
                                }
                            </div>
                            : ''
                        }

                    </div>
                    {componentContent.details_under_text ?
                        <div className={'paragraph-wide paragraph'}
                             dangerouslySetInnerHTML={{__html: componentContent.details_under_text}}/>
                        : ''
                    }
                </section>
                : ''
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = withRouter(connect(mapStateToProps)(CollectionDetails));
export {connectedPage as CollectionDetails};
