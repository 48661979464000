import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';

import {
    BackgroundWithButton,
    HeadlineWithText,
    HeaderTwoParagraphs,
    GalleryCarousel,
    DetailsHoverGrid,
    InstagramPhotoGrid} from 'components'

class AboutPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

            HeaderTwoParagraphs: {
                type: 'HeaderTwoParagraphs',
                order: 1,
                content: {
                    headline: 'About',
                    paragraph_left: 'Created by Architects, Entrepreneurs and backed by\n' +
                        'the people behind the Brown Hotel Collection. Wom is\n' +
                        'inspired by the functional perfection of Nordic design,\n' +
                        'Japanese bento boxes, and the inherent of mountain\n' +
                        'lodges. Wom offers private, efficient, minimal rooms,\n' +
                        'in TLV, next to the sea, in the hippest neighborhood\n' +
                        'in town. WOM’s philosophy is based on - All we need\n' +
                        'is Less. When the city is our Lobby and the hotel is\n' +
                        'our base nest.\n' +
                        '\n',
                    paragraph_right: 'We know what is necessary for today’s traveler, and we\n' +
                        'eliminated all the nonessential, taking in consideration\n' +
                        'the human connection we all need and of course a pure\n' +
                        'design aesthetic. We built a new fashion for essential\n' +
                        'sensitive hospitality where efficiency and beauty\n' +
                        'are aligned. Wom enables an efficient base for our\n' +
                        'essential elements, and enables variety of possibilities\n' +
                        'for new experiences the city .',
                    image_left: 'https://picsum.photos/266/325/?random',
                    image_right: 'https://picsum.photos/267/322/?random',
                    background_color: '#f9c0b7'
                }
            },

            HeadlineWithText: {
                type: 'HeadlineWithText',
                order: 2,
                content: {
                    headline: 'The fundamental gratification of having just what we need.',
                    text: 'WOM spaces are essential, compact, modern and safe. The rooms are private up to 2 womers designed with state of the art, facilities and eco-conscious materials. For every 3 rooms there are shared luxurious private bathrooms with toilet, sink and a shower. We encourage interaction in all shared spaces'
                }
            },

            ImagesCarousel: {
                type: 'GalleryCarousel',
                order: 7,
                content: {
                    title: '',
                    type: 'multi-slide',
                    backgroundColor: null,
                    titleType: 'section-title',
                    slides: [
                        'https://picsum.photos/799/599/?random',
                        'https://picsum.photos/802/600/?random',
                        'https://picsum.photos/795/605/?random',
                    ],
                    speed: 500,
                }
            },
            DetailsHoverGrid: {
                type: 'DetailsHoverGrid',
                order: 5,
                content: {
                    title: 'Core Values',
                    DetailsByHover: [
                        {
                            headline: 'Essential',
                            details_text: 'All private luxurious bathrooms units (with toilet & shower) are used' +
                                'separately by each guest. The bathrooms are designed to be shred' +
                                'with 1-4 guests giving you the feeling of a hotel privacy in a hostel price.',
                            image: 'https://picsum.photos/643/313/?random'
                        },
                        {
                            headline: 'Urban',
                            details_text: 'All private luxurious bathrooms units (with toilet & shower) are used' +
                                'separately by each guest. The bathrooms are designed to be shred' +
                                'with 1-4 guests giving you the feeling of a hotel privacy in a hostel price.',
                            image: 'https://picsum.photos/642/311/?random'
                        },
                        {
                            headline: 'Sharing',
                            details_text: 'All private luxurious bathrooms units (with toilet & shower) are used' +
                                'separately by each guest. The bathrooms are designed to be shred' +
                                'with 1-4 guests giving you the feeling of a hotel privacy in a hostel price.',
                            image: 'https://picsum.photos/647/317/?random'
                        },
                        {
                            headline: 'Space',
                            details_text: 'All private luxurious bathrooms units (with toilet & shower) are used' +
                                'separately by each guest. The bathrooms are designed to be shred' +
                                'with 1-4 guests giving you the feeling of a hotel privacy in a hostel price.',
                            image: 'https://picsum.photos/646/316/?random'
                        }
                    ]
                }
                },
            BackgroundWithButton: {
                type: 'HeaderTwoParagraphs',
                order: 1,
                content: {
                    button_text: 'BOOK NOW',
                    page_link: '',
                    external_url: '',
                    background_image: 'https://picsum.photos/1300/406/?random'
                }
            },

            Instagram: {
                type: 'InstagramPhotoGrid',
                order: 9,
                content: {
                    title: 'Instagram',
                    btnText: 'DISCOVER',
                    photos: [
                        'https://picsum.photos/405/406/?random',
                        'https://picsum.photos/412/409/?random',
                        'https://picsum.photos/g/410/406/?random',
                        'https://picsum.photos/422/415/?random',
                        'https://picsum.photos/g/421/418/?random',
                        'https://picsum.photos/418/418/?random',
                    ]
                }
            }
        };
    }

    render() {
        return (
            <Fragment>
                <HeaderTwoParagraphs content={this.state.HeaderTwoParagraphs.content}/>
                <HeadlineWithText content={this.state.HeadlineWithText.content}/>
                <GalleryCarousel content={this.state.ImagesCarousel.content}/>
                <DetailsHoverGrid content={this.state.DetailsHoverGrid.content}/>
                <BackgroundWithButton content={this.state.BackgroundWithButton.content}/>
                <InstagramPhotoGrid content={this.state.Instagram.content}/>
            </Fragment>
        );
    }
}

function mapStateToProps() {
    return {}
}

const connectedPage = connect(mapStateToProps)(AboutPage);
export {connectedPage as AboutPage};
