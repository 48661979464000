import React, {Component} from "react";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {contentActions} from "../../_actions";

class TermsAndConditions extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        return (
            componentContentReady && componentContent ?
                <section className='terms-document center'>
                    <h2 className='terms-title section-title'>
                        {componentContent.headline}
                    </h2>
                    <div className='terms-document-container' dangerouslySetInnerHTML={{__html: componentContent.document}}/>
                </section>
                : ''
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = withRouter(connect(mapStateToProps)(TermsAndConditions));
export {connectedPage as TermsAndConditions};
