import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from "react-router-dom";
import {scrollSpy} from 'react-scroll';

import {BlogPost, CollectionGallery, CollectionItem, GalleryPageNavigationPanel, HeaderTwoParagraphs} from 'components';
import {contentConstants} from "_constants";
import {contentActions} from "../../_actions";

class CollectionGalleryRouter extends Component {
    componentDidMount() {
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        scrollSpy.update();

        if (!componentContentReady) {
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    detectPageType = (post, backButtonText) => {
        switch (post.meta.type) {
            case contentConstants.EXPERIENCE_PAGE:
                return (<CollectionItem content={post} compType={'Experience'} backButtonText={backButtonText}/>);
            case contentConstants.EVENT_PAGE:
                return (<CollectionItem content={post} compType={'Events'} backButtonText={backButtonText}/>);
            case contentConstants.BLOG_PAGE:
                return (<BlogPost content={post} compType={'Blog'} backButtonText={backButtonText}/>);
            default:
                return (<h2>{post.headline}</h2>)
        }

    };

    render() {
        const {componentContent, componentContentReady, collectionsIndexes, indexesItems} = this.props;

        const {match} = this.props;

        let collectionItems = [], linkedPosts = [], linkedPostsList = [];

        if (componentContent
            && componentContent.linked_indexes
            && collectionsIndexes
        ) {
            // If we have an array of collections we add all the items of each collection to
            // 'linkedPostsList' in order to handle the routing for each post.
            for (const collectionIndex of componentContent.linked_indexes) {
                if (collectionsIndexes
                    && collectionsIndexes[collectionIndex.collection.id]
                    && collectionsIndexes[collectionIndex.collection.id][contentConstants.SORT_TYPE_ITEMS]) {
                    collectionItems = collectionsIndexes[collectionIndex.collection.id][contentConstants.SORT_TYPE_ITEMS];
                    linkedPosts = [
                        ...linkedPosts,
                        ...collectionItems
                    ];

                    linkedPostsList = linkedPosts.map(link => (
                        indexesItems[link.id]
                    ));
                }
            }
        } else if (
            componentContent &&
            componentContent.collection &&
            collectionsIndexes &&
            collectionsIndexes[componentContent.collection.id] &&
            Array.isArray(collectionsIndexes[componentContent.collection.id][contentConstants.SORT_TYPE_ITEMS])
        ) {
            // If we get only one collection of posts (Like in Blog) simply add the collection
            // items to 'linkedPostsList'. This will create the correct routing for the posts.
            linkedPostsList = collectionsIndexes[componentContent.collection.id][contentConstants.SORT_TYPE_ITEMS].map(link => (
                indexesItems[link.id]
            ));
        }

        return (
            <Switch>
                <Route exact path={`${match.url}`} render={() => (
                    <Fragment>
                        <HeaderTwoParagraphs
                            componentContent={componentContent}
                            contentFromParent={true}
                        />
                        {componentContentReady && componentContent && componentContent.linked_indexes && componentContent.linked_indexes.length > 1 ?
                            <GalleryPageNavigationPanel componentContent={componentContent.linked_indexes}/>
                            : ''
                        }
                        {
                            componentContentReady && componentContent && componentContent.linked_indexes && componentContent.linked_indexes.map((collectionIndex, index) => {
                                if (!collectionIndex) return '';

                                return (
                                    <Fragment key={collectionIndex.id}>
                                        <CollectionGallery
                                            navAnchor={index}
                                            componentContent={collectionIndex}/>
                                    </Fragment>
                                )
                            })
                        }
                        {
                            componentContentReady && componentContent && componentContent.collection && (
                                <CollectionGallery
                                    componentContent={componentContent}/>
                            )
                        }
                    </Fragment>)}/>

                {Array.isArray(linkedPostsList) && linkedPostsList.map(post => {
                    if (post) {
                        return (
                            <Route key={post.meta.slug || post.slug}
                                   path={`${match.url}/${post.meta.slug || post.slug}`} render={() => {
                                return (
                                    this.detectPageType(post, componentContent.headline)
                                )
                            }}/>
                        )
                    } else {
                        return '';
                    }

                })}
            </Switch>
        );
    }
}

function mapStateToProps({content}) {
    const {collectionsIndexes, indexesItems} = content;
    return {
        collectionsIndexes,
        indexesItems,
    }
}

const connectedPage = withRouter(connect(mapStateToProps)(CollectionGalleryRouter));
export {connectedPage as CollectionGalleryRouter};




