import React, {Component} from "react";
import {connect} from 'react-redux';
import {converter, generalHelpers} from "_helpers";
import {withRouter} from "react-router-dom";

import locationIcon from "assets/img/location-icon.png";
import {generalConstants, uiConstants} from "_constants";

class CollectionItemHeader extends Component {

    backHandler = () => {
        generalHelpers.goOneUp(this.props.history);
    };

    render() {
        const {componentContent, backButtonText} = this.props;

        const userAgent = navigator.userAgent;
        const isMobileAppAgent = userAgent === generalConstants.USER_AGENT;

        return (
            componentContent ?
                <section className={'collection-item-header header-wide header'} style={
                    isMobileAppAgent ? {marginTop: 0} : {marginTop: 60}
                }>
                    <div className={'collection-item-header-content d-flex flex-wrap flex-column align-items-center'}
                         style={
                             window.outerWidth > uiConstants.BREAKPOINT_SM ?
                                 {
                                     backgroundImage: `linear-gradient(
                        ${componentContent.header_background_color} 0%,
                        ${componentContent.header_background_color} 70%,
                        transparent 70%,
                        transparent 100%
                        )`
                                     // this is a fallback for if the browser is not support linear-gradient
                                     // backgroundColor: componentContent.header_background_color
                                 }
                                 :
                                 {backgroundColor: componentContent.header_background_color}
                         }>
                        <div className='back-btn' onClick={this.backHandler} style={
                            isMobileAppAgent ? {display: 'none'} : {display: 'block'}
                        }>
                            <i className="arrow-left"/>
                            <span className={'back-btn-label'}>{`${backButtonText}`}</span>
                        </div>
                        <h2 className={'section-title headline'}>
                            {componentContent.headline}
                        </h2>
                        <div className={'intro-text-container d-flex flex-wrap justify-content-center'}>
                            <div className={'intro-text paragraph'}>{componentContent.header_text}</div>
                            {componentContent.header_location ?
                                <div className={'intro-location d-flex align-item-center'}>
                                    <div className={'intro-location-icon'}
                                         style={componentContent.location_icon ?
                                             {backgroundImage: `url(${converter.getImageDownloadURL(componentContent.location_icon)}`}
                                             :
                                             {backgroundImage: `url(${locationIcon}`}
                                         }/>
                                    <div
                                        className={'intro-location-label'}>{componentContent.header_location}</div>
                                </div>
                                : ''
                            }
                        </div>


                        <div className={'intro-image-container'}>
                            <div className={'intro-image'}
                                 style={{backgroundImage: `url(${converter.getImageDownloadURL(componentContent.header_image)})`}}/>
                        </div>
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({}) {
    return {}
}

const connectedPage = withRouter(connect(mapStateToProps)(CollectionItemHeader));
export {connectedPage as CollectionItemHeader};
