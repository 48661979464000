export const uiConstants = {
    CHANGE_CLASS_NAMES: 'CHANGE_CLASS_NAMES',
    SEND_TO_MAIL: 'SEND_TO_MAIL',

    SHOW_MENU: 'SHOW_MENU',
    HIDE_MENU: 'HIDE_MENU',
    UPDATE_LOGO_STYLE: 'UPDATE_LOGO_STYLE',
    UPDATE_TOP_NAV_STYLE: 'UPDATE_TOP_NAV_STYLE',
    UPDATE_TOP_NAV_TEXT_COLOR: 'UPDATE_TOP_NAV_TEXT_COLOR',

    SHOW_CONTACT_MODAL: 'SHOW_CONTACT_MODAL',
    HIDE_CONTACT_MODAL: 'HIDE_CONTACT_MODAL',

    PAST_SCROLL_BREAK_POINT: 'PAST_SCROLL_BREAK_POINT',
    BEFORE_SCROLL_BREAK_POINT: 'BEFORE_SCROLL_BREAK_POINT',

    ALERT_SUCCESS: 'ALERT_SUCCESS',
    ALERT_ERROR: 'ALERT_ERROR',
    ALERT_CLEAR: 'ALERT_CLEAR',

    ALERT_LOADER: 'ALERT_LOADER',

    ALERT_SUBSCRIBE: 'ALERT_SUBSCRIBE',

    SHOW_ELEMENT: 'SHOW_ELEMENT',
    HIDE_ELEMENT: 'HIDE_ELEMENT',


    SUBSCRIBE_SUCCESS_MSG: 'Completed',
    SUBSCRIBE_FAILURE_MSG: 'Try Again',

    SUBSCRIBE_LOADER: 'SUBSCRIBE_LOADER',
    SUBSCRIBE_SUCCESS: 'SUBSCRIBE_SUCCESS',
    SUBSCRIBE_SUCCESS_ANIMATION: 'SUBSCRIBE_SUCCESS_ANIMATION',
    SUBSCRIBE_FAILURE: 'SUBSCRIBE_FAILURE',

    CONTACT_US_LOADER: 'CONTACT_US_LOADER',
    CONTACT_US_SUCCESS: 'CONTACT_US_SUCCESS',
    CONTACT_US_FAILURE: 'CONTACT_US_FAILURE',



    // =========================
    //      ChangeByScroll
    // =========================

    //ratio between current scrolling position on Y axis and view height
    SCROLL_HEIGHT_MAX_RATIO: 0,
    SCROLL_HEIGHT_MIN_RATIO: 0.33,

    // =========================
    //   Logo Size and Position
    // =========================

    // unit format: %
    LOGO_MAX_MARGIN: 31.4,
    LOGO_MIN_MARGIN: 1.6,
    LOGO_MAX_MARGIN_SM: 27.3,
    LOGO_MIN_MARGIN_SM: 1.45,

    // unit format: %
    LOGO_MAX_HEIGHT: 25,
    LOGO_MIN_HEIGHT: 3.1,
    LOGO_MAX_HEIGHT_SM: 20.4,
    LOGO_MIN_HEIGHT_SM: 4.1,

    // unit format: %
    LOGO_MAX_WIDTH: 57.4,
    LOGO_MIN_WIDTH: 12.8,
    LOGO_MAX_WIDTH_SM: 87.2,
    LOGO_MIN_WIDTH_SM: 33.7,

    // =========================
    //   Logo Color and Opacity
    // =========================

    // unit format: %
    LOGO_MAX_FILTER_INVERT: 100,
    LOGO_MIN_FILTER_INVERT: 0,

    LOGO_FILTER_DROP_SHADOW: `6px 11px 9px`,
    LOGO_MAX_SHADOW_ALPHA: 0.5,
    LOGO_MIN_SHADOW_ALPHA: 0,

    OPACITY_MIN: 1,
    OPACITY_MAX: 0,

    // ===============================
    //   TopNav Height and TextColor
    // ===============================

    // unit format: px
    TOP_NAV_MAX_HEIGHT: 120,
    TOP_NAV_MIN_HEIGHT: 60,
    TOP_NAV_MAX_HEIGHT_SM: 60,
    TOP_NAV_MIN_HEIGHT_SM: 40,

    TOP_NAV_MAX_BACKGROUND_ALPHA: 0,
    TOP_NAV_MIN_BACKGROUND_ALPHA: 1,

    // unit format: single (r,g,b)
    TOP_NAV_MIN_TEXT_COLOR: 0,
    TOP_NAV_MAX_TEXT_COLOR: 255,

    //Breakpoints by view width
    BREAKPOINT_XXS: 460,
    BREAKPOINT_XS: 576,
    BREAKPOINT_SM: 780,
    BREAKPOINT_MD: 992,
    BREAKPOINT_LG: 1200,
    BREAKPOINT_XL: 1440,
};
