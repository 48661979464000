import React, {Component} from "react";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";

import {converter} from "_helpers";

class SingleImageWide extends Component {
    render() {
        const {image} = this.props;

        return (
            image ?
                <section className={'single-image-wide wide'}>
                    <div className={'single-image-wide-container'}>
                        <div className='single-image' style={{
                            backgroundImage: `url(${converter.getImageDownloadURL(image)})`,
                            backgroundColor: '#a4a4a4'
                        }}/>
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({content}) {
    const {components} = content;
    return {
        components
    }
}

const connectedPage = withRouter(connect(mapStateToProps)(SingleImageWide));
export {connectedPage as SingleImageWide};
