import React, {Component} from "react";
import {connect} from 'react-redux';

import {converter} from "_helpers";
import {contentActions} from "../../_actions";

class HeaderTwoParagraphsTwoImages extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        return (
            componentContentReady && componentContent
                ? <section
                    className={'header-two-paragraphs-two-images-section d-flex flex-column justify-content-start align-items-center header-wide header '}>
                    <div className={'header-content d-flex flex-wrap justify-content-center align-items-start'}
                         style={{backgroundColor: componentContent.background_color}}>
                        <div className={'two-paragraphs-header-headline d-flex align-items-center justify-content-center'}>
                            <h2 className={'section-title'}>{componentContent.headline}</h2>
                        </div>
                        <div className={'header-paragraph-container d-flex flex-wrap justify-content-between'}>
                            <div className={'header-paragraph paragraph'}
                                 dangerouslySetInnerHTML={
                                     {
                                         __html: componentContent.header_text
                                             || (componentContent.paragraph_left + componentContent.paragraph_right)
                                     }}/>
                        </div>
                    </div>
                    <div className={'bottom-images d-flex'}>
                        <div className={'bottom-image-container left'}>
                            <div className={'bottom-image'}
                                 style={{backgroundImage: `url(${converter.getImageDownloadURL(componentContent.image_left)})`}}/>
                        </div>
                        <div className={'bottom-image-container right'}>
                            <div className={'bottom-image'}
                                 style={{backgroundImage: `url(${converter.getImageDownloadURL(componentContent.image_right)})`}}/>
                        </div>
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = connect(mapStateToProps)(HeaderTwoParagraphsTwoImages);
export {connectedPage as HeaderTwoParagraphsTwoImages};




