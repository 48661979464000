import {combineReducers} from 'redux';

import {userConstants} from "_constants";

import {ui} from './ui';
import {content} from './content';
import {router} from './router';
import {settings} from './settings';
import {general} from './general';
import {temp} from './temp';

const initialState = JSON.parse(localStorage.getItem('state')) || {};

function rootReducer(state = initialState, action) {
    if (action.type === userConstants.LOGOUT)
        state = undefined;

    return appReducer(state, action)
}

const appReducer = combineReducers({
    content,
    ui,
    general,
    settings,
    router,
    temp
});

export default rootReducer;