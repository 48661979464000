import React, {Component} from "react";
import {connect} from 'react-redux';
import {componentsConstants} from "_constants";

class BlogArticle extends Component {
    mapToComp = (comp, index) => {
        const RenderComp = componentsConstants.COMPONENTS_LIBRARY[comp.type] || '';

        if (componentsConstants.COMPONENTS_LIBRARY.hasOwnProperty(comp.type)) {
            return (<RenderComp key={index} content={comp.value}/>)
        } else {
            return '';
        }
    };

    render() {
        const {content} = this.props;

        return (
            content ?
                <section>
                    {
                        content.map((articlePart, index) => {
                                return this.mapToComp(articlePart, index)
                            }
                        )
                    }
                </section>
                : ''
        );
    }
}

function mapStateToProps({content}) {
    const {components, collectionsIndexes, indexesItems} = content;
    return {
        components,
        collectionsIndexes,
        indexesItems,
    }
}

const connectedPage = connect(mapStateToProps)(BlogArticle);
export {connectedPage as BlogArticle};




