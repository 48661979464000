import React, {Component} from "react";
import {connect} from 'react-redux';
import {scrollSpy} from 'react-scroll';
import {generalHelpers} from '_helpers';

import {WOMDescription} from "../WOMDescription";
import {contentActions} from "../../_actions";
import {contentConstants} from "../../_constants";

class RoomsGallery extends Component {
    componentDidMount() {
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        scrollSpy.update();

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent, collectionsIndexes, indexesItems} = this.props;

        let hotelCollection = [];

        if (
            componentContentReady
            && componentContent
            && collectionsIndexes
            && collectionsIndexes[componentContent.collection.id]
            && collectionsIndexes[componentContent.collection.id][contentConstants.SORT_TYPE_ITEMS]) {
            const componentCollectionIndex = collectionsIndexes[componentContent.collection.id][contentConstants.SORT_TYPE_ITEMS];

            hotelCollection = Array.isArray(componentCollectionIndex) &&
                componentCollectionIndex.map(item => {
                    return indexesItems[item.id]
                })
        }

        return (
            componentContentReady && hotelCollection ?
                <section className={'contact-hotels-section wide'}>
                    {Array.isArray(hotelCollection) &&
                    hotelCollection.map((room, index) => {
                        generalHelpers.print("RoomsGallery", room);
                        if (
                            room &&
                            room.room_essentials &&
                            room.room_essentials[0]) {
                            return (
                                <WOMDescription
                                    navAnchor={index}
                                    content={room}
                                    key={room.id}/>
                            )
                        } else {
                            return '';
                        }

                    })}
                </section>
                : ''
        );
    }
}

function mapStateToProps({content}) {
    const {collectionsIndexes, indexesItems} = content;
    return {
        collectionsIndexes,
        indexesItems,
    }
}

const connectedPage = connect(mapStateToProps)(RoomsGallery);
export {connectedPage as RoomsGallery};
