import React, {Component} from "react";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {contentActions} from "../../_actions";

class HeaderCenterText extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        return (
            componentContentReady && componentContent ?
                <section className='header-center-text header-wide header'>
                    <div className='header-center-text-content d-flex flex-wrap flex-column align-items-center' style={{
                        backgroundColor: componentContent.header_background_color
                    }}>
                        <h2 className={'section-title headline'}>
                            {componentContent.headline}
                        </h2>
                        <p className={'intro-text paragraph'}>
                            {componentContent.header_text}
                        </p>
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = withRouter(connect(mapStateToProps)(HeaderCenterText));
export {connectedPage as HeaderCenterText};
