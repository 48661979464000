import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';

import {TwoImagesWithLabel} from "components/TwoImagesWithLabel";
import {contentActions} from "../../_actions";
import {contentConstants} from "../../_constants";

class Locations extends Component {
    componentDidMount() {
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if (!componentContentReady) {
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    getCollectionIndexByID = (collectionIndexID) => {
        const {collectionsIndexes} = this.props;

        return collectionsIndexes[collectionIndexID]
    };

    getCollectionPageByID = (collectionID) => {
        const {collections, pages} = this.props;

        if (
            collections &&
            collections[collectionID]
        ) {
            const collectionPageId = collections[collectionID] &&
                collections[collectionID]['pages'] &&
                Array.isArray(collections[collectionID]['pages']) &&
                collections[collectionID]['pages'][0] &&
                collections[collectionID]['pages'][0]['id'];

            return pages && pages[collectionPageId];
        }
    };

    getHotelPageContent = (hotelPageID) => {
        const {indexesItems} = this.props;

        return indexesItems[hotelPageID];
    };

    render() {
        const {componentContentReady, componentContent} = this.props;

        let collectionIndex, collectionPage;

        if (componentContentReady && componentContent) {
            collectionIndex = this.getCollectionIndexByID(componentContent.collection_index.id);
        }

        if (collectionIndex) {
            // Retrieve collection page for routing url to location page
            collectionPage = this.getCollectionPageByID(collectionIndex.meta.parent.id);
        }


        return (

            <section className={'locations-section center d-flex flex-column justify-content-start align-items-center'}>
                {
                    componentContentReady && componentContent
                        ? (<Fragment>
                                <h1 className={'section-title'}>{componentContent.headline}</h1>
                                <div className={'locations d-flex flex-column align-items-center'}>
                                    {collectionIndex &&
                                    collectionIndex[contentConstants.SORT_TYPE_ITEMS] &&
                                    Array.isArray(collectionIndex[contentConstants.SORT_TYPE_ITEMS]) &&
                                    collectionIndex[contentConstants.SORT_TYPE_ITEMS].map(hotel => {
                                        const hotelPage = this.getHotelPageContent(hotel.id);

                                        if (hotelPage && collectionPage) {
                                            return (<TwoImagesWithLabel key={hotel.id}
                                                                        content={hotelPage}
                                                                        parentSlug={collectionPage.meta.slug}
                                                                        locationSlug={hotelPage.meta.slug}
                                                                        parentComponent={'locations'}/>)
                                        } else {
                                            return '';
                                        }
                                    })}
                                </div>
                            </Fragment>
                        )
                        : ''
                }
            </section>

        );
    }
}

function mapStateToProps({content}) {
    const {collectionsIndexes, indexesItems, collections, pages} = content;
    return {
        collectionsIndexes,
        indexesItems,
        collections,
        pages
    }
}

const connectedPage = connect(mapStateToProps)(Locations);
export {connectedPage as Locations};
