import React, {Component} from "react";
import {connect} from 'react-redux';

import {GoogleMapsContainer} from './GoogleMaps'

class MapsContainer extends Component {


    render() {
        const {className, iconURL, location, centerOffset} = this.props;

        return (
            <div className={`${className}`}>
                {/*<h1>{match.params.id}</h1>*/}
                <GoogleMapsContainer
                    isMarkerShown={true}
                    isPolylineShown={false}
                    mapTypeId={1}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDR8SL3V9sNSmS7J61qapziy15sQFFPLC4"
                    loadingElement={<div style={{height: `100%`}}/>}
                    containerElement={<div style={{height: `100%`, width: '100%'}}/>}
                    mapElement={<div style={{height: `100%`}}/>}
                    hotelMarker={{iconURL: iconURL, location: {lat: location.latitude, lng: location.longitude}}}
                    centerOffset={centerOffset}
                />
            </div>
        )
    }
}

function mapStateToProps() {
    return {}
}

const connectedPage = connect(mapStateToProps)(MapsContainer);
export {connectedPage as MapsContainer};