import React, { Component } from "react";
import { connect } from 'react-redux';

import { converter } from '_helpers'

class BackgroundSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Start value random index from content array
            currentSlide: !!this.props.content && Math.floor(Math.random() * Math.floor(this.props.content.length)),
            intervalTimeout: this.props.transitionTimeout * 1000 || 3000,
            slideInterval: null
        }
    }

    componentDidMount() {
        const maxIndex = this.props.content.length - 1;

        const slideInterval = setInterval(() => {
            if (this.state.currentSlide === maxIndex) {
                this.setState({ currentSlide: 0 })
            } else {
                this.setState({ currentSlide: this.state.currentSlide + 1 })
            }
        }, this.state.intervalTimeout);

        this.setState({ slideInterval })
    }

    componentWillUnmount() {
        clearInterval(this.state.slideInterval)
    }

    render() {
        const { content } = this.props;
        const {currentSlide} = this.state

        return (
            content ?
                <div className={'background-slider-container'}>
                    {
                        content.map((slide, index) => {
                            return (
                                    <div
                                        key={index}
                                        id={`home-header-slide-${index}`}
                                        className={`background-slide ${currentSlide === index ? 'show' : 'hide'}`}
                                        style={
                                            {
                                                backgroundImage:
                                                    `url(${converter.getImageDownloadURL(slide.image)})`
                                            }
                                        }/>
                            )

                        })
                    }
                </div>
                : ''
        );
    }
}

function mapStateToProps() {
    return {}
}

const connectedPage = connect(mapStateToProps)(BackgroundSlider);
export { connectedPage as BackgroundSlider };
