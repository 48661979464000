import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import {generalActions, uiActions} from "_actions";
import {TheWomLoader} from "../TheWomLoader";
import {contentConstants} from "_constants";
import { contentService } from "_services";

class ParticipateForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            phone: '',
            participants: '',
            date: '',
            hotel: '',
            massage_body: '',
        }
    }

    componentDidMount(){
        const {collectionsIndexes, indexesItems, content} = this.props;
        const participate_form = content.participate_form[0];
        let hotels_list;

        if (participate_form && participate_form.hotel_options) {
            if(collectionsIndexes){
                const hotels_index = collectionsIndexes[participate_form.hotel_options.id];
                hotels_list = hotels_index && Array.isArray(hotels_index[contentConstants.SORT_TYPE_ITEMS]) &&
                    hotels_index[contentConstants.SORT_TYPE_ITEMS].map(
                    hotel => (indexesItems[hotel.id])
                );
                this.setState({hotels_list})
            } else {
                contentService.getDescendant(participate_form.hotel_options.id, {type: contentConstants.CMS_HOTELS_ITEM_TYPE})
                    .then(hotels => {
                        hotels_list = Array.isArray(hotels.items) && hotels.items.map(hotel => {
                            return {
                                id: hotel.id,
                                title: hotel.title
                            }
                        });
                        this.setState({hotels_list})
                    })
                    .catch(error => {
                        console.log("getDescendant error", error);
                    })
            }

        }
    }

    handleSubmit = (event, participate_form, headline) => {
        event.preventDefault();

        const {dispatch} = this.props;


        if (this.state.email &&
            this.state.name &&
            this.state.hotel &&
            (this.state.phone || this.state.email) &&
            this.state.date
        ) {
            this.setState({missing: false});

            dispatch(generalActions.sendMail(
                headline,
                participate_form.send_email_to,
                this.state.phone,
                this.state.email,
                this.state.massage_body,
                this.state.name,
                this.state.hotel,
                this.state.date,
                this.state.participants,
            ));

            this.setState(
                {
                    name: '',
                    email: '',
                    phone: '',
                    participants: '',
                    date: '',
                    hotel: '',
                    massage_body: '',
                    missing: false,
                    hotels_list: []
                }
            );
        } else {
            this.setState({missing: true})
        }

    };

    handleSelectChange = (event, id, item) => {
        // change text color in selected box after one option selected
        document.getElementById(id).classList.add('selected');

        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleTextChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    };

    createOptionsNumbersRange = (start, end, selected) => {
        let result = [];

        start = parseInt(start);
        end = parseInt(end);

        for (let i = start; i <= end && i <= 30; i++) {
            result.push(
                <option key={i}
                        className={'participate-select-option'}
                        selected={selected === i}
                        value={i}>
                    {i}
                </option>
            )
        }

        return <Fragment>
            {result}
        </Fragment>
    };

    createOptionsDateRange = (min, max, selected) => {
        const result = [];
        let today = new Date();

        min = parseInt(min);
        max = parseInt(max);

        if (min > max) {
            max = min
        }

        for (let i = min; i <= max && i <= 30; i++) {
            let tomorrow = new Date();
            tomorrow.setDate(today.getDate() + i);
            const value = `${tomorrow.getMonth() + 1}/${tomorrow.getDate()}/${tomorrow.getFullYear()}`;
            result.push(
                <option key={i} className={'participate-select-option'}
                        selected={selected === value}
                        value={value}>
                    {value}
                </option>
            );
        }
        return <Fragment>
            {result}
        </Fragment>
    };

    render() {
        const {alertLoader, alertSuccess, content} = this.props;
        const {hotels_list} = this.state;
        let participateFormValid = true;

        const participate_form = content.participate_form[0];


        if(participate_form){
            // Validate all keys of form not null
            Object.keys(participate_form).forEach(key => {
                if (key !== 'meta' && key !== 'id') {
                    if (participate_form.hasOwnProperty(key)) {
                        if (!participate_form[key]) {
                            participateFormValid = false
                        }
                    }
                }
            });
        }

        return (
            (participate_form && participateFormValid) ?
                <section className='participate-form-section collection-item-center'>
                    {participate_form.headline &&
                    <h3 className={'headline section-title'}>{participate_form.headline}</h3>}
                    <form onSubmit={event => this.handleSubmit(event, participate_form, content.headline)}
                          className={'participate-form d-flex flex-wrap'}>
                        <div className={'participate-form-row d-flex flex-wrap justify-content-between'}>
                            <input className={'participate-form-col-50 participate-input'}
                                   value={this.state.name}
                                   type="text"
                                   id="name"
                                   onChange={this.handleTextChange}
                                   placeholder={participate_form.name_placeholder}/>
                            <input className={'participate-form-col-50 participate-input'}
                                   value={this.state.email}
                                   type="email"
                                   id="email"
                                   onChange={this.handleTextChange}
                                   placeholder={participate_form.email_placeholder}/>
                        </div>
                        <div className={'participate-form-row d-flex flex-wrap justify-content-between'}>
                            <input className={'participate-form-col-50 participate-input'}
                                   type="tel"
                                   value={this.state.phone}
                                   pattern="[0-9,-]{3,20}"
                                   id="phone"
                                   onChange={this.handleTextChange}
                                   placeholder={participate_form.phone_placeholder}/>
                            <div className={'participate-form-col-50 participate-select-container'}>
                                <select className={'participate-select'}
                                        onChange={event => this.handleSelectChange(event, 'participants')}
                                        id='participants'
                                        name="participants">
                                    <option className={'participate-select-option'} disabled
                                            selected={!this.state.participants} hidden>
                                        {participate_form.participants_placeholder}
                                    </option>
                                    {
                                        this.createOptionsNumbersRange(1, participate_form.participants_max_number, this.state.participants)
                                    }
                                </select>
                                <div className='arrow-down'/>
                            </div>
                        </div>
                        <div className={'participate-form-row d-flex flex-wrap justify-content-between'}>
                            <div className={'participate-form-col-50 participate-select-container'}>
                                <select className={'participate-select'}
                                        id='date'
                                        onChange={event => this.handleSelectChange(event, 'date')}
                                        name="date">
                                    <option className={'participate-select-option'} disabled selected={!this.state.date}
                                            hidden>
                                        {participate_form.when_placeholder}
                                    </option>
                                    {
                                        this.createOptionsDateRange(
                                            participate_form.when_min_days,
                                            participate_form.when_max_days,
                                            this.state.date
                                        )
                                    }
                                </select>
                                <div className='arrow-down'/>
                            </div>
                            <div className={'participate-form-col-50 participate-select-container'}>
                                <select className={'participate-select'}
                                        id='hotel'
                                        onChange={event => this.handleSelectChange(event, 'hotel')}
                                        name="hotel">
                                    <option className={'participate-select-option'} disabled
                                            selected={!this.state.hotel} hidden>
                                        {participate_form.hotel_placeholder}
                                    </option>
                                    {
                                        hotels_list && hotels_list.map(hotel => {
                                            if (hotel) {
                                                return (
                                                    <option key={hotel.title}
                                                            selected={this.state.hotel === hotel.title}
                                                            className={'participate-select-option'}
                                                            value={hotel.title}>
                                                        {hotel.title}
                                                    </option>)
                                            } else {
                                                return '';
                                            }
                                        })
                                    }
                                </select>
                                <div className='arrow-down'/>
                            </div>
                        </div>
                        <div className={'participate-form-row d-flex flex-wrap justify-content-between'}>
                            <textarea
                                className={'participate-form-col-100 participate-textarea'}
                                value={this.state.massage_body}
                                id="massage_body"
                                onChange={this.handleTextChange}
                                placeholder={participate_form.message_placeholder}/>
                        </div>
                        {this.state.missing && <div className='missing-fields-message'>
                            Please fill in all of the form fields to participate.
                        </div>}
                        <div className={'participate-form-row d-flex justify-content-center'}>
                            {alertLoader
                                ?
                                alertSuccess
                                    ? <button className={'success-button'} onClick={event => {
                                        event.preventDefault();
                                        this.props.dispatch(uiActions.alertClear())
                                    }}>
                                        {'SUCCESS'}
                                    </button>
                                    : <div className={'load-button'}>
                                        <TheWomLoader/>
                                    </div>
                                :
                                <button className='the-wom-btn dark-button'>{participate_form.button_text}</button>}
                        </div>
                    </form>
                </section>
                : ''
        );
    }
}

function mapStateToProps({content, ui}) {
    const {components, collectionsIndexes, indexesItems} = content;
    const {alertLoader, alertSuccess} = ui;
    return {
        components,
        indexesItems,
        alertLoader,
        alertSuccess,
        collectionsIndexes,
    }
}

const connectedPage = connect(mapStateToProps)(ParticipateForm);
export {connectedPage as ParticipateForm};
