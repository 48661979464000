// import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MainApp from './MainApp';
import * as serviceWorker from './serviceWorker';

import TagManager from 'react-gtm-module';
 
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTAG_ID,
    events: {
        sendUserInfo: 'userInfo'
    }
}
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(<MainApp/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
