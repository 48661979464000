import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";

import {converter as converters} from "_helpers";
import {contentActions} from "../../_actions";
import {defaultConstants} from "../../_constants";

class Footer extends Component {
    componentDidMount() {
        const {dispatch, permanentId, componentType, componentContentReady} = this.props;

        if (!componentContentReady) {
            dispatch(contentActions.getItemDetailedContent(componentType, permanentId))
        }
    }

    render() {
        const {footerContent, match, pages, componentContentReady, currentHomePage} = this.props;

        return (
            componentContentReady && footerContent ?
                <div className={'footer d-flex justify-content-center'}>
                    <div className={'footer-image'} style={{
                        backgroundImage:
                            `linear-gradient(
                                     rgba(255, 255, 255, 0.3) 0%,
                                     rgba(255, 255, 255, 0.3) 100%
                                 ),
                                 url(${converters.getImageDownloadURL(footerContent.background_image)})`
                    }}/>
                    <div className={'footer-content d-flex flex-column justify-content-between align-items-start'}>
                        <div className={'footer-content-row d-flex justify-content-between top'}>
                            <div className={'footer-links footer-content-col d-flex flex-wrap'}>
                                {footerContent.hasOwnProperty('footer_internal_links') && footerContent.footer_internal_links.map((link, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <Link
                                                to={
                                                    link.link.id === currentHomePage.id
                                                        ? match.url
                                                        : `${match.url}/${pages[link.link.id] ? pages[link.link.id].meta.slug : ''}`
                                                }
                                                className={`footer-link`}>
                                                {link.label}
                                            </Link>
                                        </Fragment>
                                    )
                                })}
                                {footerContent.hasOwnProperty('footer_external_links') && footerContent.footer_external_links.map((link, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <a
                                                href={link.link}
                                                target={'_blank'}
                                                className={`footer-link`}>
                                                {link.label}
                                            </a>
                                        </Fragment>
                                    )
                                })}
                                <div className='btn-book d-flex justify-content-center'>
                                    {
                                        (pages && footerContent.button_link && pages[footerContent.button_link.id])
                                        && <Link
                                            to={`${match.url}/${pages[footerContent.button_link.id].meta.slug}`}
                                            className={`footer-link`}>
                                            {footerContent.button_text}
                                        </Link>
                                    }
                                </div>
                            </div>
                            <div className={'social-links footer-content-col d-flex justify-content-start'}>
                                {footerContent.hasOwnProperty('footer_social_links') && footerContent.footer_social_links.map((link, index) => {
                                    return (
                                        <a
                                            className={'footer-link'}
                                            href={link.social_url}
                                            target={'_blank'}
                                            key={index}>
                                            <div
                                                className={'social-icon'}
                                                style={{backgroundImage: `url(${converters.getImageDownloadURL(link.icon)})`}}/>
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                        <div className={'footer-content-row d-flex justify-content-between bottom'}>
                            <div className={'footer-copyrights footer-content-col'}>{footerContent.rights}</div>
                            <div className={'footer-logos d-flex footer-content-col'}>
                                <a href={footerContent.brown_link}
                                    // This will open a new tab for this link
                                   target='_blank'
                                   rel='noopener noreferrer'
                                >
                                    <div
                                        className={'footer-logo brown'}
                                        style={{backgroundImage: `url(${converters.getImageDownloadURL(footerContent.brown_logo)})`}}/>
                                </a>
                                <div
                                    className={'footer-logo wom'}
                                    style={{backgroundImage: `url(${converters.getImageDownloadURL(footerContent.logo)})`}}/>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
        );
    }
}

function mapStateToProps({content, router, settings}) {
    const {pages} = content;
    const {locale} = settings;
    const {homePage} = router;
    return {
        pages,
        currentHomePage: (!!homePage && homePage[locale]) || defaultConstants.defaultHomeSlug,
    }
}

const connectedPage = withRouter(connect(mapStateToProps)(Footer));
export {connectedPage as Footer};
