/* Thewom Language Texts

Table of Contents

01.General
02.Error Page
03.Nav-Bars
04.Forms

*/

module.exports = {
    /* 01.General */
    "general.copyright": "© WOM 2019. All rights Reserved",

    /* 02. Error Page */

    /* 03. Top Nav Bar */
    "top-nav.book-a-room": "BOOK A ROOM",

    /* 03. Header */
    "header.book-a-room": "BOOK A ROOM",

    "subscribe.invalid-email": "Please fill a valid email and try again"
};
