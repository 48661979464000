import {contentConstants} from "_constants";

const initial_state = {};

export const content = (state = initial_state, action) => {
    switch (action.type) {
        case contentConstants.GET_CONTENT_ROOT_REQUEST:
            return {
                ...state,
                appLoadingStartedAt: new Date()
            };
        case contentConstants.GET_CONTENT_ROOT_SUCCESS:
            return {
                ...state,
                appLoadingEndedAt: new Date(),
                [contentConstants.SITE_ROOT]: {
                    root_id: action.root.id,
                    title: action.root.title,
                    slug: action.root.meta.slug,
                }
            };
        case contentConstants.SORT_COMPLETED:
            return {
                ...state,
                [contentConstants.SITE_ROOT]: {
                    ...state[contentConstants.SITE_ROOT],
                    [contentConstants.CONTENT_SORT_COMPLETED]: true
                }
            };
        case contentConstants.GET_HOME_PAGE_COMPONENTS_SUM_SUCCESS:
            return {
                ...state,
                [contentConstants.HOME_PAGE_COMPONENTS_SUM]: true
            };
        case contentConstants.HOME_PAGE_FETCH_CONTENT_COMPLETED:
            return {
                ...state,
                [contentConstants.SITE_ROOT]: {
                    ...state[contentConstants.SITE_ROOT],
                    [contentConstants.CONTENT_FETCH_COMPLETED]: true
                }
            };
        case contentConstants.GET_LANGUAGE_CONTENT_SUCCESS:
            return {
                ...state,
                [contentConstants.SORT_TYPE_LANGUAGES]: {
                    ...state[contentConstants.SORT_TYPE_LANGUAGES],
                },
            };
        case contentConstants.SAVE_LANGUAGE_DETAILS:
            if (state.hasOwnProperty(contentConstants.SORT_TYPE_LANGUAGES) && state[contentConstants.SORT_TYPE_LANGUAGES].hasOwnProperty(action.languageDetails['id'])) {
                return {
                    ...state,
                    [contentConstants.SORT_TYPE_LANGUAGES]: {
                        ...state[contentConstants.SORT_TYPE_LANGUAGES],
                        [action.languageDetails['id']]: {
                            ...state[contentConstants.SORT_TYPE_LANGUAGES][action.languageDetails['id']],
                            ...action.languageDetails
                        },
                        [contentConstants.CMS_CURRENT_LANGUAGE]: action.languageDetails['id'],
                        [contentConstants.CMS_AVAILABLE_LANGUAGES]: {
                            ...state[contentConstants.CMS_AVAILABLE_LANGUAGES],
                            [action.languageDetails['meta']['slug']]: action.languageDetails['id']
                        }
                    }
                };
            } else {
                return {
                    ...state,
                    [contentConstants.SORT_TYPE_LANGUAGES]: {
                        ...state[contentConstants.SORT_TYPE_LANGUAGES],
                        [action.languageDetails['id']]: {
                            ...action.languageDetails
                        },
                        [contentConstants.CMS_CURRENT_LANGUAGE]: action.languageDetails['id'],
                        [contentConstants.CMS_AVAILABLE_LANGUAGES]: {
                            [action.languageDetails['meta']['slug']]: action.languageDetails['id']
                        },
                    },
                };
            }
        case contentConstants.SAVE_SITE_ROOT_DETAILS:
            return {
                ...state,
                siteRoot: {
                    ...state.siteRoot,
                    ...action.siteRootDetails
                }
            };
        case contentConstants.GET_ITEM_CONTENT_SUCCESS:
            return {
                ...state,
                [action.itemGeneralType]: {
                    ...state[action.itemGeneralType],
                    [action.itemContent['id']]: {
                        ...state[action.itemGeneralType][action.itemContent['id']],
                        ...action.itemContent,
                        [contentConstants.COMPONENT_CONTENT_UPDATED_AT]: action.timestamp,
                        [contentConstants.COMPONENT_CONTENT_READY]: true,
                    }
                }
            };
        case contentConstants.SORT_TO_TYPE:
            return {
                ...state,
                [action.itemType]: {
                    ...state[action.itemType],
                    [action.item['id']]: {
                        ...action.item,
                        [contentConstants.CMS_ITEM_TYPE]: action.itemType
                    }
                }
            };
        case contentConstants.SORT_TO_PARENT:
            const parentType = action.parentType,
                parentId = action.parentId,
                itemGeneralType = action.itemGeneralType;

            if (
                state.hasOwnProperty(parentType) &&
                state[parentType].hasOwnProperty(parentId) &&
                state[parentType][parentId].hasOwnProperty(itemGeneralType)
            ) {
                return {
                    ...state,
                    [parentType]: {
                        ...state[parentType],
                        [parentId]: {
                            ...state[parentType][parentId],
                            [itemGeneralType]: [...state[parentType][parentId][itemGeneralType], action.item]
                        }
                    }
                };
            } else {
                return {
                    ...state,
                    [parentType]: {
                        ...state[parentType],
                        [parentId]: {
                            ...state[parentType][parentId],
                            [itemGeneralType]: [action.item]
                        }
                    }
                }
            }
        default:
            return state
    }
};
