import React, {Component} from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import Thewom from "./thewom";
import {defaultConstants} from "_constants";

class MainRoute extends Component {
    render() {
        const {containerClassnames} = this.props;

        return (
            <div id="app-container" className={containerClassnames}>
                <Switch>
                    <Route path={`${defaultConstants.defaultStartPath}:lang/`} component={Thewom}/>
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = ({ui}) => {
    const {containerClassnames} = ui;

    return {
        containerClassnames,
    };
};

export default withRouter(
    connect(mapStateToProps)(MainRoute)
);
