import React, {Component} from "react";
import {connect} from 'react-redux';

import {converter} from "_helpers";
import {contentActions} from "../../_actions";

class HotelDetails extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        return (
            componentContentReady && componentContent
                ? <section
                    className='hotel-details-section d-flex flex-column justify-content-start align-items-center center'>
                    <div className={'hotel-details-content d-flex flex-wrap justify-content-center align-items-start'}
                         style={{backgroundColor: componentContent.background_color}}>
                        <div className={'hotel-details-hide-background'}/>
                        <div className={'hotel-details-headline d-flex align-items-center justify-content-center'}>
                            <h2 className={'section-title'}>{componentContent.headline}</h2>
                        </div>
                        {componentContent.intro_text
                            ?
                            <div className={'paragraph-container d-flex flex-wrap justify-content-between'}>
                                <div className={'paragraph'}>
                                    {componentContent.intro_text}
                                </div>
                            </div>
                            :
                            ''
                        }

                        <div className='facility-icons d-flex flex-wrap justify-content-center align-items-start'>
                            <div className={'facility-headline'}>
                                <h4 className={'section-subtitle'}>
                                    {componentContent.features_headline}
                                </h4>
                            </div>
                            <div className='icons-container d-flex flex-wrap justify-content-center align-items-start'>
                                {
                                    Array.isArray(componentContent.hotel_facilities) &&
                                    componentContent.hotel_facilities.map(feature => (
                                        <div
                                            className='feature-icon-container d-flex flex-wrap justify-content-center align-items-start'
                                            key={feature.id}>
                                            <div className={'icon-container d-flex justify-content-center'}>
                                                <div className='icon'
                                                     style={{backgroundImage: `url(${converter.getImageDownloadURL(feature.icon)})`}}/>
                                            </div>
                                            <div
                                                className={'label-container d-flex align-items-start justify-content-center'}>
                                                <div className='label'>
                                                    {!!feature.label && feature.label}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'bottom-images d-flex'}>
                        <div className={'image left'}
                             style={{backgroundImage: `url(${converter.getImageDownloadURL(componentContent.image_left)})`}}/>
                        <div className={'image right'}
                             style={{backgroundImage: `url(${converter.getImageDownloadURL(componentContent.image_right)})`}}/>
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = connect(mapStateToProps)(HotelDetails);
export {connectedPage as HotelDetails};




