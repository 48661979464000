import React from "react";
import ReactPlayer from 'react-player'

export const VideoPlayer = ({ url }) => {
    return (
        <ReactPlayer url={url}
            config={{
                youtube: {
                    playerVars: {
                        controls: 0,
                        disablekb: 1,
                        modestbranding: 1,
                        loop: 1,
                        enablejsapi: 0
                    },
                },
            }}
            className='header-video-player'
            playing
            loop
            volume={0}
            muted
        />
    )
}
