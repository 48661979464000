import appLocaleData from 'react-intl/locale-data/en';
import enMessages from '../locales/en_US';

const HeLang = {
    messages: {
        ...enMessages
    },
    locale: 'he-IL',
    data: appLocaleData
};
export default HeLang;