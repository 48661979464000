export const userConstants = {
    USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    USER_REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    UPDATE_USER_INFO_REQUEST: 'UPDATE_USER_INFO_REQUEST',
    UPDATE_USER_INFO_SUCCESS: 'UPDATE_USER_INFO_SUCCESS',
    UPDATE_USER_INFO_FAILURE: 'UPDATE_USER_INFO_FAILURE',

    PASSWORD_RECOVERY_REQUEST: 'PASSWORD_RECOVERY_REQUEST',
    PASSWORD_RECOVERY_SUCCESS: 'PASSWORD_RECOVERY_SUCCESS',
    PASSWORD_RECOVERY_FAILURE: 'PASSWORD_RECOVERY_FAILURE',

    LOGOUT: 'USERS_LOGOUT',
};