import { addLocaleData } from 'react-intl';
import enLang from './entries/en-US';
import heLang from './entries/he-IL';

const AppLocale = {
    en: enLang,
    he: heLang,
};

addLocaleData(AppLocale.en.data);

export default AppLocale;
