import {defaultConstants} from "_constants";
import {store} from '_helpers';

export const uiHelpers = {
    scrollNavBarChange
};

function scrollNavBarChange(location, currentLanguage) {
    let isInHomePage = false;

    const state = store.getState();
    const {content, router} = state;
    const {pages} = content;
    const currentHomePage = (!!router.homePage && router.homePage[currentLanguage]) || defaultConstants.defaultHomeSlug;

    const navContainer = document.querySelector('#thewom-top-nav');

    if (!!pages && pages[currentHomePage.id]) {
        isInHomePage = (location.pathname === `${defaultConstants.defaultStartPath}${currentLanguage}`)
            ||(location.pathname === `${defaultConstants.defaultStartPath}${currentLanguage}/`)
            || (location.pathname === `${defaultConstants.defaultStartPath}${currentLanguage}/${pages[currentHomePage.id].meta.slug}`);
    }
    const pastScrollBreakPoint = window.pageYOffset / window.innerHeight >= 0.355;

    if (navContainer) {
        (isInHomePage && !pastScrollBreakPoint)
            ? navContainer.className = ''
            : navContainer.className = 'regular-nav';
    }
}