import {contentConstants, routerConstants} from "_constants";


const initial_state = {
};

export const router = (state = initial_state, action) => {
    switch (action.type) {
        case routerConstants.UPDATE_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.pathname
            };
        case contentConstants.SET_HOME_PAGE:
            return {
                ...state,
                homePage: {
                    [action.content.meta.slug]: action.content.home_page
                }
            };
        default:
            return state;
    }
};
