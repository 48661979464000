import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';

// TODO - remove in production
import logo from 'assets/img/wom_logo_4.png'

import {HeaderHome} from "components";

class TestPage extends Component {
    state = {
      HeaderHome: {
          background: "https://picsum.photos/1000/800",
          logo: logo,
          sub_headline: 'A new accommodation concept filtered\n' +
              'to its most essential & pure parts.\n' +
              '\n'
      }
    };

    render() {
        return (
            <Fragment>
                <HeaderHome testContent={this.state.HeaderHome}/>
            </Fragment>
        );
    }
}

function mapStateToProps() {
    return {}
}

const connectedPage = connect(mapStateToProps)(TestPage);
export {connectedPage as TestPage};
