import React, {Component} from "react";
import {connect} from 'react-redux';
import {contentActions, tempActions} from '_actions'
import {tempConstants, uiConstants} from '_constants';


class InstagramPhotoGrid extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    getImages = (apiKey) => {
        const {instagram, dispatch} = this.props;
        const numOfPhotos = tempConstants.INSTAGRAM_NUM_TO_FETCH;

        // get photos only if there not in redux store already
        if(!instagram.hasOwnProperty(apiKey)){
            dispatch(tempActions.getInstagramPhotos(apiKey, numOfPhotos))
        }
    };

    render() {
        const {componentContentReady, componentContent, instagram} = this.props;

        componentContentReady && componentContent && componentContent.hasOwnProperty('instagram_app_key') && this.getImages(componentContent['instagram_app_key']);

        return (
            componentContentReady && componentContent && instagram.hasOwnProperty(componentContent['instagram_app_key']) ?
                <section className={'instagram-photo-grid-section center d-flex'}>
                    <h1 className={'section-title'}>{componentContent.title}</h1>
                    <a
                        className={'the-wom-btn dark-button instagram-button'}
                        href={componentContent['instagram_account']} target={"_blank"}>
                        {componentContent.button_text}
                    </a>

                    <div className={'instagram-photo-grid d-flex flex-wrap justify-content-between'}>
                        {window.outerWidth > uiConstants.BREAKPOINT_SM ?
                            instagram[componentContent['instagram_app_key']].hasOwnProperty('highResolution') &&
                            instagram[componentContent['instagram_app_key']].highResolution.map((photo, index) => {
                                return (
                                    <div key={index} className={'instagram-photo-container'}>
                                        <div className={`instagram-photo`}
                                            style={{backgroundImage: `url(${photo})`}}
                                        />
                                    </div>
                                )
                            })
                            :
                            instagram[componentContent['instagram_app_key']].hasOwnProperty('lowResolution') &&
                            instagram[componentContent['instagram_app_key']].lowResolution.map((photo, index) => {
                                return (
                                    <div key={index} className={'instagram-photo-container'}>
                                        <div className={`instagram-photo`}
                                            style={{backgroundImage: `url(${photo})`}}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({temp}) {
    const {instagram} = temp;
    return {
        instagram
    }
}

const connectedPage = connect(mapStateToProps)(InstagramPhotoGrid);
export {connectedPage as InstagramPhotoGrid};
