import {contentService} from "_services";
import {contentConstants, defaultConstants, uiConstants} from "_constants";
import {contentHelpers} from "../_helpers/content";
import {generalHelpers} from "../_helpers";
import {uiActions} from '../_actions';

export const contentActions = {
    fetchCompleted,
    sendLead,

    // getContent,
    getLanguageDetails,
    getLanguageContent,
    parseLanguageContent,
    getAvailableLanguages,
    getItemDetailedContent,

    getContentByLanguage
};

function fetchCompleted() {
    return dispatch => {
        dispatch(fetchCompletedAction());
    };

    function fetchCompletedAction() {
        return {
            type: contentConstants.FETCH_COMPLETED
        }
    }
}

function sendLead(email) {
    return dispatch => {
        dispatch(request(email));

        contentService.sendLead(email)
            .then(data => {
                dispatch(uiActions.hideElement(uiConstants.SUBSCRIBE_LOADER))
                dispatch(uiActions.showElement(uiConstants.SUBSCRIBE_SUCCESS_ANIMATION))

                dispatch(success(data))
            })
            .catch(error => {
                dispatch(uiActions.hideElement(uiConstants.SUBSCRIBE_LOADER))
                dispatch(uiActions.showElement(uiConstants.SUBSCRIBE_FAILURE))

                dispatch(failure(error))
            })
    };

    function request() {
        return {type: contentConstants.SEND_LEAD_REQUEST}
    }

    function success(data) {
        return {type: contentConstants.SEND_LEAD_SUCCESS, data}
    }

    function failure(error) {
        return {type: contentConstants.SEND_LEAD_FAILURE, error}
    }
}

// Refactor from TheWomApp

function getContentByLanguage(language ,routeHistory) {
    return (dispatch) => {
        dispatch(request());

        const currentPathName = routeHistory["location"]['pathname'];
        const languageSlug = currentPathName.split('/')[1];

        if (languageSlug === "he") {
            language = languageSlug
        }

        contentService.getContentByLanguage(language)
            .then(response => {
                dispatch(success(response));

                const siteRootDetails = response['langContent'] && response['site_root'];
                const languageDetails = response['langContent'] && response['langContent'];
                const languageContent = response['items'];

                // Language Details Handler
                const defaultLanguageSlug = languageDetails['meta'] && languageDetails['meta']['slug'];

                // Sends client to default language URL
                if (routeHistory.location.pathname === defaultConstants.defaultStartPath) {
                    routeHistory.push(`${defaultConstants.defaultStartPath}${defaultLanguageSlug}`);
                }

                const homePageId = languageDetails['home_page'] && languageDetails['home_page']['id'];

                dispatch(setHomePage(languageDetails));
                dispatch(saveLanguageDetails(languageDetails));
                dispatch(saveSiteRootDetails(siteRootDetails));

                // languageContent Handler

                dispatch(contentActions.parseLanguageContent(languageContent, homePageId));
            })
            .catch(error => {
                dispatch(failure(error))
            })
    };

    function request() {
        return {type: contentConstants.GET_CONTENT_BY_LANGUAGE_REQUEST}
    }

    function success(response) {
        return {type: contentConstants.GET_CONTENT_BY_LANGUAGE_SUCCESS, response}
    }

    function failure(error) {
        return {type: contentConstants.GET_CONTENT_BY_LANGUAGE_FAILURE, error}
    }
}

// function getContent(routeHistory) {
//     return (dispatch) => {
//         dispatch(request());

//         contentService.getContentRoot()
//             .then(root => {
//                 const defaultLanguage = root[contentConstants.CMS_DEFAULT_LANGUAGE_KEY];

//                 dispatch(success(root));
//                 dispatch(contentActions.getLanguageDetails(defaultLanguage.id, routeHistory));
//             })
//             .catch(error => {
//                 dispatch(failure(error))
//             })
//     };

//     function request() {
//         return {type: contentConstants.GET_CONTENT_ROOT_REQUEST}
//     }

//     function success(root) {
//         return {type: contentConstants.GET_CONTENT_ROOT_SUCCESS, root}
//     }

//     function failure(error) {
//         return {type: contentConstants.GET_CONTENT_ROOT_FAILURE, error}
//     }
// }

function getLanguageDetails(languageId, routeHistory) {
    return (dispatch) => {
        dispatch(request());

        contentService.getLanguageDetails(languageId)
            .then(languageDetails => {
                const defaultLanguageSlug = languageDetails['meta'] && languageDetails['meta']['slug'];

                // Sends client to default language URL
                if (routeHistory.location.pathname === defaultConstants.defaultStartPath) {
                    routeHistory.push(`${defaultConstants.defaultStartPath}${defaultLanguageSlug}`);
                }

                const homePageId = languageDetails['home_page'] && languageDetails['home_page']['id'];
                dispatch(contentActions.getLanguageContent(languageId, homePageId));

                generalHelpers.print("languageDetails", languageDetails);
                dispatch(setHomePage(languageDetails));
                dispatch(success(languageDetails));
            })
            .catch(error => {
                dispatch(failure(error))
            })

    };

    function setHomePage(content) {
        return {
            type: contentConstants.SET_HOME_PAGE,
            content,
        }
    }

    function request() {
        return {type: contentConstants.GET_LANGUAGE_DETAILS_REQUEST}
    }

    function success(languageDetails) {
        return {type: contentConstants.GET_LANGUAGE_DETAILS_SUCCESS, languageDetails}
    }

    function failure(error) {
        return {type: contentConstants.GET_LANGUAGE_DETAILS_FAILURE, error}
    }
}

function getLanguageContent(languageId, homePageId) {

    return dispatch => {
        dispatch(request());

        contentService.getLanguageContent(languageId)
            .then(content => {
                dispatch(success(content));
                dispatch(contentActions.parseLanguageContent(content, homePageId));
            })
            .catch(error => {
                dispatch(failure(error));
            });

    };

    function request() {
        return {type: contentConstants.GET_LANGUAGE_CONTENT_REQUEST}
    }

    function success(content) {
        return {type: contentConstants.GET_LANGUAGE_CONTENT_SUCCESS, content}
    }

    function failure(error) {
        return {type: contentConstants.GET_LANGUAGE_CONTENT_FAILURE, error}
    }
}

function getAvailableLanguages(languageId, homePageId) {

    return dispatch => {
        dispatch(request());

        contentService.getAvailableLanguages(languageId)
            .then(content => {
                dispatch(success(content));
                dispatch(contentActions.parseLanguageContent(content, homePageId));
            })
            .catch(error => {
                dispatch(failure(error));
            });

    };

    function request() {
        return {type: contentConstants.GET_LANGUAGE_CONTENT_REQUEST}
    }

    function success(content) {
        return {type: contentConstants.GET_LANGUAGE_CONTENT_SUCCESS, content}
    }

    function failure(error) {
        return {type: contentConstants.GET_LANGUAGE_CONTENT_FAILURE, error}
    }
}

function parseLanguageContent(items, homePageId) {
    return (dispatch) => {
        items.forEach((item, index, array) => {
            const itemType = getContentClassType(contentHelpers.getItemType(item));
            const itemParentId = contentHelpers.getParentId(item);
            const itemParentType = getContentClassType(contentHelpers.getParentType(item));

            if (itemType) {
                dispatch(sortToType(itemType, item));
                if (itemParentId) {
                    const parsedItem = parseItemCoreData(item);

                    dispatch(sortToParent(itemParentType, itemParentId, itemType, parsedItem))
                }

                // Get HomePage components content at first start
                if (
                    (itemParentId === homePageId) ||
                    itemType === contentConstants.SORT_TYPE_PERMANENTS ||
                    contentConstants.CMS_FETCH_ON_START_TYPES.includes(item['meta']['type'])
                ) {
                    dispatch(contentActions.getItemDetailedContent(itemType, item['id']));
                    generalHelpers.print("homePage child", item)
                }
            }

            if (index === array.length - 1) {
                dispatch(sortCompleted())
            }
        });
    };

    function parseItemCoreData(item) {
        return {
            type: item['meta'] && item['meta']['type'],
            id: item['id'],
            slug: item['meta'] && item['meta']['slug']
        }
    }

    function sortToType(itemType, item) {
        return {
            type: contentConstants.SORT_TO_TYPE,
            itemType,
            item
        }
    }

    function sortToParent(parentType, parentId, itemGeneralType, item) {
        return {
            type: contentConstants.SORT_TO_PARENT,
            parentType,
            parentId,
            itemGeneralType,
            item
        }
    }
}

function getItemDetailedContent(itemType, itemId) {
    return (dispatch) => {
        dispatch(request());
        contentService.getIndexItemDetailedContent(itemId)
            .then(itemContent => {
                dispatch(success(itemType, itemContent));
            })
            .catch(error => {
                dispatch(failure(error, itemType, itemId))
            })

    };

    function request() {
        return {type: contentConstants.GET_ITEM_CONTENT_REQUEST}
    }

    function success(itemGeneralType, itemContent) {
        const timestamp = new Date();

        return {
            type: contentConstants.GET_ITEM_CONTENT_SUCCESS,
            itemGeneralType,
            itemContent,
            timestamp
        }
    }

    function failure(error) {
        return {
            type: contentConstants.GET_ITEM_CONTENT_FAILURE,
            error,
            itemType,
            itemId
        }
    }
}

function sortCompleted() {
    return {
        type: contentConstants.SORT_COMPLETED
    }
}

function getContentClassType(type) {
    switch (true) {
        case (contentConstants.CMS_PERMANENTS_TYPES.includes(type)):
            return contentConstants.SORT_TYPE_PERMANENTS;
        case (contentConstants.CMS_COLLECTION_TYPES.includes(type)):
            return contentConstants.SORT_TYPE_COLLECTIONS;
        case (contentConstants.CMS_PAGE_TYPES.includes(type)):
            return contentConstants.SORT_TYPE_PAGES;
        case (contentConstants.CMS_INDEX_TYPES.includes(type)):
            return contentConstants.SORT_TYPE_INDEXES;
        case (contentConstants.CMS_ITEM_TYPES.includes(type)):
            return contentConstants.SORT_TYPE_ITEMS;
        case (contentConstants.CMS_COMPONENT_TYPES.includes(type)):
            return contentConstants.SORT_TYPE_COMPONENTS;
        case (type === contentConstants.CMS_LANGUAGE_TYPE):
            return contentConstants.SORT_TYPE_LANGUAGES;
        default:
            return null
    }
}

function setHomePage(content) {
    return {
        type: contentConstants.SET_HOME_PAGE,
        content,
    }
}

function saveLanguageDetails(languageDetails){
    return {
        type: contentConstants.SAVE_LANGUAGE_DETAILS,
        languageDetails
    }
}

function saveSiteRootDetails(siteRootDetails){
    return {
        type: contentConstants.SAVE_SITE_ROOT_DETAILS,
        siteRootDetails
    }
}
