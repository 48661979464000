import React, {Component} from "react";
import {connect} from 'react-redux';

import {converter} from "_helpers";
import {contentActions} from "../../_actions";
import {contentConstants} from "../../_constants";

class HotelRooms extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent, collectionsIndexes, indexesItems} = this.props;

        let hotelRooms;

        if (collectionsIndexes && componentContent && componentContent['rooms_collection_Index']) {
            const collectionIndex = collectionsIndexes[componentContent.rooms_collection_Index.id]
            const compIndexItems = collectionIndex
                ? collectionIndex[contentConstants.SORT_TYPE_ITEMS]
                : [];
            hotelRooms = compIndexItems.map(item => (
                indexesItems[item.id]
            ));
        }

        return (
            componentContentReady && componentContent
                ? <section
                    className='hotel-rooms-section d-flex flex-column justify-content-start align-items-center center'>
                    <div className={'hotel-rooms-content d-flex flex-wrap justify-content-center align-items-start'}
                         style={{backgroundColor: componentContent.background_color}}>
                        <div className={'hotel-rooms-hide-background'}/>
                        <div className={'hotel-rooms-hide-background-mobile'}/>
                        <div className='hotel-rooms-headline d-flex align-items-center justify-content-center'>
                            <h2 className={'section-title'}>
                                {componentContent.headline}
                            </h2>
                        </div>
                        {componentContent.intro_text ?
                            <div className={'paragraph-container d-flex flex-wrap justify-content-between'}>
                                <div className={'paragraph'}>
                                    {componentContent.intro_text}
                                </div>
                            </div>
                            :
                            ''
                        }

                        <div className='hotel-rooms-collection d-flex flex-wrap align-items-start justify-content-between'>
                            <div className={'collection-headline'}>
                                <h4 className={'section-subtitle'}>
                                    {componentContent.sub_headline}
                                </h4>
                            </div>
                            <div className={'rooms-container d-flex justify-content-between'}>
                                {
                                    Array.isArray(hotelRooms) &&
                                    hotelRooms.map((room, index) => {
                                        if (room && room.room_hotel_view) {
                                            room = room.room_hotel_view[0];

                                            if (!room) return '';

                                            return (
                                                <div
                                                    className='room-container'
                                                    key={room.id}>
                                                    <div className={'image-container desktop'}>
                                                        {/*<div className={'image-number'}>{index + 1}</div>*/}
                                                        <div className='image'
                                                             style={{
                                                                 backgroundImage:
                                                                     `linear-gradient(
                                                                    rgba(255, 255, 255, 0.2) 0%,
                                                                    rgba(255, 255, 255, 0.2) 100%),
                                                                    url(${converter.getImageDownloadURL(room.image)})`
                                                             }}/>
                                                    </div>
                                                    <div id={`room-details-${index + 1}`} className='room-details'>
                                                        <div className='headline'>
                                                            {room.sub_headline}
                                                        </div>

                                                        <div className='details-list'
                                                             dangerouslySetInnerHTML={{__html: room.details}}/>
                                                    </div>
                                                    <div className={'image-container mobile'}>
                                                        <div className='image'
                                                             style={{backgroundImage: `url(${converter.getImageDownloadURL(room.image)})`}}/>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return '';
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className='amenities-icons d-flex flex-wrap justify-content-center align-items-start'
                         style={{backgroundColor: componentContent.background_color_amenities}}>
                        <div className={'amenities-headline'}>
                            <h4 className={'section-subtitle'}>
                                {componentContent.amenities_headline}
                            </h4>
                        </div>
                        <div
                            className='amenities-icons-container d-flex flex-wrap justify-content-center align-items-start'>
                            {
                                Array.isArray(componentContent.hotel_rooms_amenities) &&
                                componentContent.hotel_rooms_amenities.map(feature => (
                                    <div
                                        className='amenities-icon-container d-flex flex-wrap justify-content-center align-items-start'
                                        key={feature.id}>
                                        <div className={'icon-container d-flex justify-content-center'}>
                                            <div className='icon'
                                                 style={{backgroundImage: `url(${converter.getImageDownloadURL(feature.icon)})`}}/>
                                        </div>
                                        <div className={'label-container d-flex align-items-start justify-content-center'}>
                                            <div className='label'>
                                                {!!feature.label && feature.label}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({content}) {
    const {collectionsIndexes, indexesItems} = content;
    return {
        collectionsIndexes,
        indexesItems,
    }
}

const connectedPage = connect(mapStateToProps)(HotelRooms);
export {connectedPage as HotelRooms};




