import {routerConstants} from "_constants";

export const routerActions = {
    updateCurrentPage
};

function updateCurrentPage(pathname) {
    // Scroll to top on route change
    window.scrollTo(0, 0);
    return {
        type: routerConstants.UPDATE_CURRENT_PAGE,
        pathname
    }
}