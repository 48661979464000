import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import isEmail from 'validator/lib/isEmail';

import {Modal, ModalBody} from 'reactstrap';
import {generalActions, uiActions} from "_actions";
import {uiConstants} from "_constants";
import {TheWomLoader} from "components";

class ContactUsModal extends Component {
    state = {
        options: {
            contentClassName: 'd-flex align-items-center',
        },
        error: false,
        email: '',
        name: '',
        message: ''
    };

    componentWillUnmount() {
        const {dispatch} = this.props;

        dispatch(uiActions.hideElement(uiConstants.CONTACT_US_LOADER));
        dispatch(uiActions.hideElement(uiConstants.CONTACT_US_FAILURE));
        dispatch(uiActions.hideElement(uiConstants.CONTACT_US_SUCCESS));
    }

    handleHideModal = () => {
        const {dispatch} = this.props;

        dispatch(uiActions.hideElement(uiConstants.CONTACT_US_LOADER));
        dispatch(uiActions.hideElement(uiConstants.CONTACT_US_FAILURE));
        dispatch(uiActions.hideElement(uiConstants.CONTACT_US_SUCCESS));

        this.props.dispatch(uiActions.hideContactModal());
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    handleSubmit = (event) => {
        const {email, name, message} = this.state;
        const {dispatch, sendToMail} = this.props;

        this.setState({error: false});
        dispatch(uiActions.hideElement(uiConstants.CONTACT_US_FAILURE));

        event.preventDefault();

        if(!isEmail(email)){
            this.setState({error: true});
            return
        }

        dispatch(uiActions.showElement(uiConstants.CONTACT_US_LOADER));
        dispatch(generalActions.sendContactUsMail(name, email, message, sendToMail, uiConstants.CONTACT_US_LOADER));
    };

    render() {
        const {isContactModalOpen, alertFailure, alertSuccess, showLoader} = this.props;

        const componentContent = this.props.content;

        return (
            componentContent ?
                <Fragment>
                    <Modal
                        {...this.state.options}
                        isOpen={isContactModalOpen}
                        toggle={this.handleHideModal}
                        className={'contact-us-modal'}>
                        <ModalBody className={'contact-us-body d-flex flex-wrap justify-content-center'}>
                            <div className={'contact-us-headline'}>
                                <h3 className={'section-title'}>{componentContent.modal_headline}</h3>
                            </div>
                            <form className={'contact-us-form d-flex flex-wrap'} onSubmit={this.handleSubmit}>
                                <div className={'contact-us-form-row d-flex flex-wrap justify-content-between'}>
                                    <input
                                        onChange={this.handleChange}
                                        className={'contact-us-input'}
                                        name="name"
                                        type="text"
                                        placeholder={componentContent.input_name_placeholder}/>
                                    <input
                                        onChange={this.handleChange}
                                        className={'contact-us-input'}
                                        name="email"
                                        type="email"
                                        placeholder={componentContent.input_email_placeholder}/>
                                </div>
                                <div className={'contact-us-form-row'}>
                                <textarea className={'contact-us-text-area'}
                                          name="message"
                                          onChange={this.handleChange}
                                          placeholder={componentContent.input_text_area_placeholder}/>
                                </div>
                                <div className={'contact-us-form-row d-flex flex-column align-items-center justify-content-center'}>
                                    {
                                        this.state.error &&
                                        <small style={{color: 'crimson'}}>Please enter a valid email</small>
                                    }
                                    {
                                        alertFailure &&
                                        <small style={{color: 'crimson'}}>Connection error. please try again.</small>
                                    }
                                    {
                                        showLoader
                                        ?
                                        alertSuccess
                                            ? <button
                                                className={'success-button'}>{uiConstants.SUBSCRIBE_SUCCESS_MSG}</button>
                                            : <div className={'load-button'}>
                                                <TheWomLoader/>
                                            </div>
                                        :
                                        <button
                                            className={'the-wom-btn dark-button'}>{componentContent.button_text}</button>}
                                </div>
                            </form>
                        </ModalBody>
                    </Modal>
                </Fragment>
                : ''
        );
    }
}

function mapStateToProps({ui}) {
    const {isContactModalOpen} = ui;
    return {
        isContactModalOpen,
        alertFailure: ui[uiConstants.CONTACT_US_FAILURE],
        alertSuccess: ui[uiConstants.CONTACT_US_SUCCESS],
        showLoader: ui[uiConstants.CONTACT_US_LOADER],
        sendToMail: ui[uiConstants.SEND_TO_MAIL]
    }
}

const connectedPage = connect(mapStateToProps)(ContactUsModal);
export {connectedPage as ContactUsModal};
