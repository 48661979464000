import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';

import {HeaderTwoHeadlinesWithBackgroundImage, ContactHotels} from 'components'
import {ContactUsModal} from "../../ContactHotels/ContactUsModal";

class ContactPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            HeaderOneImage: {
                type: 'HeaderOneImage',
                order: 1,
                content: {
                    headline: 'Contact',
                    sub_title: 'Get in touch with us',
                    image: 'https://picsum.photos/800/600/?random',
                    background_color: '#f9e15d'
                }
            },
        };
    }

    render() {
        return (
            <Fragment>
                <HeaderTwoHeadlinesWithBackgroundImage content={this.state.HeaderOneImage.content}/>
                <ContactHotels/>
                <ContactUsModal/>
            </Fragment>
        )
    }
}

function mapStateToProps() {
    return {}
}

const connectedPage = connect(mapStateToProps)(ContactPage);
export {connectedPage as ContactPage};
