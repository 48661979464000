import React, {Component} from "react";
import {connect} from 'react-redux';
import {converter} from "_helpers";
import {uiConstants} from "_constants";
import {contentActions} from "../../_actions";


class Index extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;


        return (
            componentContentReady && componentContent ?
                <section className={'header-woms header-wide header'}>
                    <div className={'header-woms-content d-flex flex-wrap flex-column align-items-center'} style={
                        window.outerWidth > uiConstants.BREAKPOINT_SM ?
                            {
                                backgroundImage: `linear-gradient(
                                    ${componentContent.background_color} 0%,
                                    ${componentContent.background_color} 79%,
                                    transparent 79%,
                                    transparent 100%
                                    )`
                            }
                            :
                            {
                                backgroundImage: `linear-gradient(
                                    ${componentContent.background_color} 0%,
                                    ${componentContent.background_color} 56%,
                                    transparent 56%,
                                    transparent 100%
                        )`
                            }

                    }>
                        <div className={'header-woms-hide-background'}/>
                        <h2 className={'section-title headline'}>
                            {componentContent.headline}
                        </h2>
                        <p className={'intro-text paragraph'}>
                            {componentContent.intro_text}
                        </p>
                        <div className={'intro-image-container'}>
                            <div className={'intro-image'}
                                 style={{backgroundImage: `url(${converter.getImageDownloadURL(componentContent.image)})`}}/>
                        </div>
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = connect(mapStateToProps)(Index);
export {connectedPage as HeaderWOMs};
