import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';

import {CollectionItemHeader, BlogArticle} from 'components'
import {contentService} from '_services';
import {generalHelpers} from "_helpers";

class BlogPost extends Component {
    constructor(props){
        super(props)

        this.state = {
            componentContent: {},
        }
    }
    componentDidMount(){
        const {componentId} = this.props;

        if(componentId){
            contentService.getIndexItemDetailedContent(componentId)
                .then(content => {
                    this.setState({componentContent: content})
                })
                .catch(error => {
                })
        }
    }

    render() {
        const {content, backButtonText} = this.props;
        const {componentContent} = this.state;

        const renderContent = content || componentContent

        return (
            renderContent
                ? <Fragment>
                    <CollectionItemHeader componentContent={renderContent} backButtonText={backButtonText}/>
                    <BlogArticle content={renderContent.body}/>
                </Fragment>
                : ''
        );
    }
}

function mapStateToProps({content}) {
    const {components, collectionsIndexes, indexesItems} = content;
    return {
        components,
        collectionsIndexes,
        indexesItems,
    }
}

const connectedPage = connect(mapStateToProps)(BlogPost);
export {connectedPage as BlogPost};




