import React, {Component} from "react";
import {connect} from 'react-redux';

import Carousel from 'nuka-carousel';

import {OneImageSlide} from "components";
import {uiConstants} from "_constants";
import {generalHelpers} from "_helpers";

class SingleWOMCarousel extends Component {
    render() {
        const {imagesArray} = this.props;

        const defaultSpeed = 600;

        const options = {
            //Margin Between Slides. Converts to 'px'
            cellSpacing: 5,
            // Enable Prev and Next Slide to be shown
            frameOverflow: 'hidden',
            // Sets width of Center Slide
            width: window.outerWidth > uiConstants.BREAKPOINT_SM ? '100%' : '89%',
            // Transition Speed:
            speed: imagesArray && Number.isInteger(imagesArray.speed) ? imagesArray.speed : defaultSpeed,
            // Transition between slides. Choose from here - https://github.com/d3/d3-ease
            easing: 'easeQuadInOut',
            // Slide to show - will be middle of images array
            // slideIndex: componentContent && componentContent.hasOwnProperty('hotel_public_places') && Math.ceil(componentContent.hotel_public_places.length / 2),
            slideIndex: 0,
            // Infinite Loop through slides
            wrapAround: true,
            autoplay: false,
            // disable swiping when only one slide
            swiping: imagesArray.length !== 1,
            dragging: imagesArray.length !== 1
        };

        generalHelpers.print('imagesArray', imagesArray);
        return (
            imagesArray ?
                <div className='single-wom-carousel-container'>
                    <Carousel {...options} className='single-wom-carousel'
                        /* Disable Paging Dots on Slide Bottom */
                              renderBottomCenterControls={() => null}
                        /* Change to custom arrow buttons */
                              renderCenterLeftControls={({previousSlide}) => (
                                  imagesArray.length === 1
                                      ?
                                      ''
                                      :
                                      <i onClick={previousSlide} className={'arrow left'}/>
                              )}
                              renderCenterRightControls={({nextSlide}) => (
                                  imagesArray.length === 1
                                      ?
                                      ''
                                      :
                                      <i onClick={nextSlide} className={'arrow right'}/>
                              )}
                    >
                        {Array.isArray(imagesArray) && imagesArray.map((slide, index) => {
                            return (
                                <OneImageSlide image={slide.image}
                                               key={index}/>
                            )
                        })}
                    </Carousel>
                </div>
                : ''
        )
    }
}

function mapStateToProps({content}) {
    const {components} = content;
    return {
        components
    }
}

const connectedPage = connect(mapStateToProps)(SingleWOMCarousel);
export {connectedPage as SingleWOMCarousel};
