import React, {Component} from "react";
import {connect} from 'react-redux';

import {converter} from '_helpers'
import {contentActions} from "../../_actions";

class HeaderTwoHeadlinesWithBackgroundImage extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        return (
            componentContentReady && componentContent ?
                <section className={'header-one-image-section d-flex flex-column align-items-center header-wide header'}>
                    <div className={'header-one-image-background'}
                         style={{backgroundColor: componentContent.background_color}}>
                    </div>
                    <div className={'header-one-image-content d-flex flex-wrap justify-content-center'}>
                        <div className={'headline'}>
                            <h2 className={'section-title'}>{componentContent.headline}</h2>
                            <h4 className={'section-subtitle'}>{componentContent.sub_headline}</h4>
                        </div>
                        <div className={'image'}
                             style={{backgroundImage: `url(${converter.getImageDownloadURL(componentContent.background_image)})`}}/>
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = connect(mapStateToProps)(HeaderTwoHeadlinesWithBackgroundImage);
export {connectedPage as HeaderTwoHeadlinesWithBackgroundImage};
