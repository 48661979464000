import {contentConstants, generalConstants} from "_constants";

export const contentHelpers = {
    getParentId,
    getParentType,
    getItemType,
    getCollectionData,
    getCollectionIndexesItems,
    getIndexesItemsContent,
    // getItemWebURL,
    checkAvailableCollection,
    getPlacesCollectionData,
    getCollectionIndexByID,
    getCollectionPageByID,
    getHotelPageContent
};

function getParentId(item) {
    if (item['meta'].hasOwnProperty('parent') && item['meta']['parent']) {
        return item['meta']['parent']['id'];
    }
}

function getCollectionPageId(content, collectionId) {
    if (content && content[contentConstants.SORT_TYPE_COLLECTIONS].hasOwnProperty(collectionId)) {
        if (content[contentConstants.SORT_TYPE_COLLECTIONS][collectionId].hasOwnProperty(contentConstants.SORT_TYPE_PAGES)) {
            return content[contentConstants.SORT_TYPE_COLLECTIONS][collectionId][contentConstants.SORT_TYPE_PAGES][0]
        }
    }
}

function getParentType(item) {
    if (item['meta'].hasOwnProperty('parent') && item['meta']['parent']) {
        return item['meta']['parent']['meta']['type'];
    }
}

function getItemType(item) {
    return item['meta']['type'];
}

// function getItemWebURL(content, item) {
//     if (item && item['link_to_external_link']) {
//         return item['external_link'];
//     }
//
//     // Get all parents Id's of item
//     const currentLanguageId = content &&
//         content[contentConstants.SORT_TYPE_LANGUAGES] &&
//         content[contentConstants.SORT_TYPE_LANGUAGES][contentConstants.CMS_CURRENT_LANGUAGE];
//     const collectionIndexId = getParentId(item);
//     const collectionId = getParentId(content[contentConstants.SORT_TYPE_INDEXES][collectionIndexId]);
//     const collectionPageId = getCollectionPageId(content, collectionId);
//
//     // Get related Slugs
//     const languageSlug = content &&
//         content[contentConstants.SORT_TYPE_LANGUAGES] &&
//         content[contentConstants.SORT_TYPE_LANGUAGES][currentLanguageId] &&
//         content[contentConstants.SORT_TYPE_LANGUAGES][currentLanguageId]['meta']['slug'];
//     const collectionPageSlug = content &&
//         content[contentConstants.SORT_TYPE_PAGES] &&
//         content[contentConstants.SORT_TYPE_PAGES][collectionPageId] &&
//         content[contentConstants.SORT_TYPE_PAGES][collectionPageId]['meta']['slug'];
//     const itemSlug = item['meta']['slug'];
//
//     if(languageSlug && collectionPageSlug && itemSlug){
//         // itemURL = DOMAIN/LANGUAGE/PAGE/ITEM
//         return `${generalConstants.THE_WOM_SITE_DOMAIN}/${languageSlug}/${collectionPageSlug}/${itemSlug}`
//     }
//
//     return null
// }

function checkAvailableCollection(content, collectionType) {
    let available = false;
    if (content && content.hasOwnProperty(contentConstants.SORT_TYPE_COLLECTIONS)) {
        Object.values(content[contentConstants.SORT_TYPE_COLLECTIONS]).forEach(collection => {
            if (collection['meta']['type'] === collectionType) {
                available = true
            }
        })
    }
    return available
}

function getPlacesCollectionData(content) {
    const currentLanguageId = content &&
        content[contentConstants.SORT_TYPE_LANGUAGES] &&
        content[contentConstants.SORT_TYPE_LANGUAGES][contentConstants.CMS_CURRENT_LANGUAGE],
        language = content &&
            content[contentConstants.SORT_TYPE_LANGUAGES] &&
            content[contentConstants.SORT_TYPE_LANGUAGES][currentLanguageId],
        collections = content && content[contentConstants.SORT_TYPE_COLLECTIONS],
        indexes = content && content[contentConstants.SORT_TYPE_INDEXES],
        collectionType = contentConstants.CMS_PLACES_COLLECTION_TYPE;

    let placesIndexesArray = [], placesIndexLabel, placesIndexContent, placesIndexItems;

    if (language
        && collections
        && indexes
        && language[contentConstants.SORT_TYPE_COLLECTIONS]
        && Array.isArray(language[contentConstants.SORT_TYPE_COLLECTIONS])
    ) {
        language[contentConstants.SORT_TYPE_COLLECTIONS].forEach(collectionId => {
            const type = collections[collectionId]['meta']['type'];
            if (type === collectionType) {
                const collectionIndexes = collections[collectionId][contentConstants.SORT_TYPE_INDEXES];
                if(collectionIndexes && Array.isArray(collectionIndexes)){
                    collectionIndexes.forEach(indexId => {
                        placesIndexItems = indexes[indexId][contentConstants.SORT_TYPE_ITEMS];

                        if (placesIndexItems) {
                            placesIndexLabel = indexes[indexId][contentConstants.CMS_PLACES_INDEX_TITLE];
                            placesIndexContent = getIndexesItemsContent(content, collectionType, placesIndexItems);

                            placesIndexesArray.push({
                                navLabel: placesIndexLabel,
                                carouselContent: placesIndexContent
                            })
                        }
                    })
                }
            }
        });
    }

    return placesIndexesArray;

}

function getCollectionData(content, collectionType) {
    const indexesItems = contentHelpers.getCollectionIndexesItems(content, collectionType);

    return contentHelpers.getIndexesItemsContent(content, collectionType, indexesItems);
}

function getCollectionIndexesItems(content, collectionType) {
    const currentLanguageId = content && content[contentConstants.SORT_TYPE_LANGUAGES] &&
        content[contentConstants.SORT_TYPE_LANGUAGES][contentConstants.CMS_CURRENT_LANGUAGE],
        language = content &&
            content[contentConstants.SORT_TYPE_LANGUAGES] &&
            content[contentConstants.SORT_TYPE_LANGUAGES][currentLanguageId],
        collections = content && content[contentConstants.SORT_TYPE_COLLECTIONS],
        indexes = content && content[contentConstants.SORT_TYPE_INDEXES];

    let indexesItems = [];

    if (
        language &&
        collections &&
        indexes &&
        language[contentConstants.SORT_TYPE_COLLECTIONS] &&
        Array.isArray(language[contentConstants.SORT_TYPE_COLLECTIONS])
    ) {
        language[contentConstants.SORT_TYPE_COLLECTIONS].forEach(collectionId => {
            const type = collections[collectionId]['meta']['type'];
            if (type === collectionType) {
                collections[collectionId][contentConstants.SORT_TYPE_INDEXES].forEach(indexId => {
                    indexesItems.push(...indexes[indexId][contentConstants.SORT_TYPE_ITEMS])
                })
            }
        });
    }

    return indexesItems;
}

function getIndexesItemsContent(content, collectionType, indexesItems) {
    const items = content && content[contentConstants.SORT_TYPE_ITEMS];

    let itemsContent = [];

    if(indexesItems && Array.isArray(indexesItems)){
        indexesItems.forEach(indexItemId => {
            const indexItem = items[indexItemId];
            if (itemValid(indexItem, collectionType)) {
                itemsContent.push(indexItem[contentConstants.SORT_TYPE_ITEM_CONTENT])
            }
        });
    }

    return itemsContent;
}

function itemValid(item, collectionType) {
    let slideObjectKey, imageObjectKey;
    switch (true) {
        case (collectionType === contentConstants.CMS_EVENTS_COLLECTION_TYPE ||
            collectionType === contentConstants.CMS_EXPERIENCES_COLLECTION_TYPE):
            slideObjectKey = contentConstants.CMS_ITEM_EVENTS_SLIDE_OBJECT_KEY;
            imageObjectKey = contentConstants.CMS_ITEM_EVENTS_SLIDE_OBJECT_IMAGE_KEY;

            return (
                item &&
                item.hasOwnProperty(contentConstants.SORT_TYPE_ITEM_CONTENT) &&
                item[contentConstants.SORT_TYPE_ITEM_CONTENT].hasOwnProperty(slideObjectKey) &&
                item[contentConstants.SORT_TYPE_ITEM_CONTENT][slideObjectKey].length > 0 &&
                item[contentConstants.SORT_TYPE_ITEM_CONTENT][slideObjectKey][0].hasOwnProperty(imageObjectKey)
            );
        case collectionType === contentConstants.CMS_PLACES_COLLECTION_TYPE:
            imageObjectKey = contentConstants.CMS_ITEM_PLACES_SLIDE_OBJECT_IMAGE;

            return (
                item &&
                item.hasOwnProperty(contentConstants.SORT_TYPE_ITEM_CONTENT) &&
                item[contentConstants.SORT_TYPE_ITEM_CONTENT].hasOwnProperty(imageObjectKey)
            );
        case (collectionType === contentConstants.CMS_BLOG_COLLECTION_TYPE):
            return true;
        case (collectionType === contentConstants.CMS_HOTELS_COLLECTION_TYPE):
            slideObjectKey = contentConstants.CMS_ITEM_LOCATION_SLIDE_OBJECT_KEY;
            imageObjectKey = contentConstants.CMS_ITEM_LOCATION_SLIDE_OBJECT_IMAGE_KEY;

            return (
                item &&
                item.hasOwnProperty(contentConstants.SORT_TYPE_ITEM_CONTENT) &&
                item[contentConstants.SORT_TYPE_ITEM_CONTENT].hasOwnProperty(slideObjectKey) &&
                item[contentConstants.SORT_TYPE_ITEM_CONTENT][slideObjectKey].length > 0 &&
                item[contentConstants.SORT_TYPE_ITEM_CONTENT][slideObjectKey][0].hasOwnProperty(imageObjectKey)
            );

    }
}

function getCollectionIndexByID(collectionsIndexes, collectionIndexID) {

    return collectionsIndexes[collectionIndexID]
}

function getCollectionPageByID(collections, pages, collectionID){
    if(
        collections[collectionID] &&
        collections[collectionID]['pages'] &&
        Array.isArray(collections[collectionID]['pages'])
    )

        return pages[collections[collectionID].pages[0].id];
}

function getHotelPageContent(pages, hotelPageID){
    return pages[hotelPageID];
}

//
// function getUserForums(username, appToken){
//     return generalServices.getApiRequest(generalConstants.GUEST_FORUMS_ENDPOINT)
// }
