import {generalService} from "_services";
import {tempConstants} from "_constants";

export const instagramService = {
    getInstagramPhotos
};

function getInstagramPhotos(accessToken, numOfPhotos){
    const requestParams = {
        access_token: accessToken,
        count: numOfPhotos,
    };

    return generalService.requestExternalAPISender(tempConstants.INSTAGRAM_API_ENDPOINT, requestParams);
}