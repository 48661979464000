import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';

import {HeaderTwoParagraphs, CollectionGallery} from 'components'

class BlogPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            BlogIntro: {
                type: 'CollectionIntro',
                order: 1,
                content: {
                    headline: 'Blog',
                    intro_text: 'Welcome to WOM Explore, a new service connecting our guests with our friends. Every exploration provides a fresh perspective on the cities where we\'re located: Amsterdam, Berlin, Hamburg and Ibiza. We want to show you a unique side of our cities, help you learn something new and, of course, make sure you have a ton of fun. \nOur hosts are people we trust, that we\'ve met out on the own and hang out with on weekends. They have that special magic that turns a trip into an adventure and makes a stranger feel like a long-lost friend. Browse the experiences below and let us open the door to your next adventure.',
                    background_color: '#f8e05d'
                }
            },
            BlogGallery: {
                type: 'CollectionGallery',
                order: 1,
                content: {
                    CollectionGalleryItems: [
                        {
                            id: 1,
                            title: 'Very long name of the article',
                            image: 'https://picsum.photos/350/355/?random',
                            linkText: 'DISCOVER'
                        },
                        {
                            id: 2,
                            title: 'Very long name of the article',
                            image: 'https://picsum.photos/351/355/?random',
                            linkText: 'DISCOVER'
                        },
                        {
                            id: 3,
                            title: 'Very long name of the article',
                            image: 'https://picsum.photos/352/355/?random',
                            linkText: 'DISCOVER'
                        },
                        {
                            id: 4,
                            title: 'Very long name of the article',
                            image: 'https://picsum.photos/353/355/?random',
                            linkText: 'DISCOVER'
                        },
                        {
                            id: 5,
                            title: 'Very long name of the article',
                            image: 'https://picsum.photos/354/355/?random',
                            linkText: 'DISCOVER'
                        },
                        {
                            id: 6,
                            title: 'Very long name of the article',
                            image: 'https://picsum.photos/355/355/?random',
                            linkText: 'DISCOVER'
                        }
                    ]
                }
            }
        };
    }

    render() {
        return (
            <Fragment>
                <HeaderTwoParagraphs content={this.state.BlogIntro.content}/>
                <CollectionGallery content={this.state.BlogGallery.content}/>
            </Fragment>
        );
    }
}

function mapStateToProps() {
    return {}
}

const connectedPage = connect(mapStateToProps)(BlogPage);
export {connectedPage as BlogPage};
