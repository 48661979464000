import React, {Component} from "react";
import {connect} from 'react-redux';
import {Link} from 'react-scroll'
import {contentActions} from "../../_actions";
import { contentConstants } from "../../_constants";


class PageNavigationPanel extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent, collectionsIndexes, indexesItems} = this.props;

        let indexItems;

        if (
            componentContentReady
            && componentContent
            && collectionsIndexes
            && collectionsIndexes[componentContent.linked_index.id]
            && collectionsIndexes[componentContent.linked_index.id][contentConstants.SORT_TYPE_ITEMS]) {
            const compIndexItems = collectionsIndexes[componentContent.linked_index.id][contentConstants.SORT_TYPE_ITEMS];

            indexItems = compIndexItems.map(item => {
                return (
                    indexesItems[item.id]
                )
            });
        }
        return (
            componentContentReady && indexItems ?
                <section className={'page-navigation-panel center'}>
                    <div className={'page-navigation-panel-content d-flex justify-content-between'}>
                        {
                            Array.isArray(indexItems) && indexItems.map((navItem, index, array) => {
                                if (navItem) {
                                    // This is a multi type component
                                    if (componentContent.linked_index.type === 'collection.RoomsIndex') {
                                        navItem = Array.isArray(navItem.room_essentials) && navItem.room_essentials[0];
                                    } else {
                                        navItem = Array.isArray(navItem.room_essentials) && navItem.room_essentials[0];
                                    }
                                    return (
                                        <div className={'nav-item d-flex'} key={index} style={
                                            {
                                                flexBasis: `${100 / array.length}%`,
                                                justifyContent: index === 0
                                                    ? 'flex-start'
                                                    :
                                                    index === array.length - 1 ? 'flex-end'
                                                        : 'center'
                                            }
                                        }>
                                            <Link
                                                // to={`page-item-${navItem.slug || navItem.meta.slug || navItem.id}`}
                                                to={`page-item-${index}`}
                                                spy={true}
                                                smooth={true}
                                                offset={-100}
                                                duration={500}>
                                                {navItem.headline}
                                            </Link>
                                        </div>
                                    )
                                } else {
                                    return '';
                                }
                            })
                        }
                    </div>
                </section>

                : ''
        );
    }
}

function mapStateToProps({content}) {
    const {collectionsIndexes, indexesItems} = content;
    return {
        collectionsIndexes,
        indexesItems,
    }
}

const connectedPage = connect(mapStateToProps)(PageNavigationPanel);
export {connectedPage as PageNavigationPanel};
