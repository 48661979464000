import React, {Component} from "react";
import {connect} from 'react-redux';
import {Link} from 'react-scroll'
import {generalHelpers} from "../../_helpers";


class GalleryPageNavigationPanel extends Component {
    render() {
        const indexItems = this.props.componentContent;

        return (
            indexItems ?
                <section className={'gallery-page-navigation-panel center'}>
                    <div className={'gallery-page-navigation-panel-content d-flex justify-content-between'}>
                        {
                            Array.isArray(indexItems) && indexItems.map((navItem, index, array) => {
                                    return (
                                        <div className={'nav-item d-flex'} key={index} style={
                                            {
                                                flexBasis: `${100 / array.length}%`,
                                                justifyContent:
                                                array.length <=2 
                                                ?
                                                'center'
                                                :
                                                index === 0
                                                    ? 
                                                        'flex-end'
                                                    :
                                                    index === array.length - 1 
                                                    ? 
                                                        'flex-end'
                                                    : 
                                                        'center'
                                            }
                                        }>
                                            <Link
                                                to={`page-item-${index}`}
                                                spy={true}
                                                smooth={true}
                                                offset={-100}
                                                duration={500}>
                                                {navItem.gallery_headline}
                                            </Link>
                                        </div>
                                    )
                            })
                        }
                    </div>
                </section>

                : ''
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = connect(mapStateToProps)(GalleryPageNavigationPanel);
export {connectedPage as GalleryPageNavigationPanel};
