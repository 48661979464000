import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';

import Carousel from 'nuka-carousel';

import {OneImageSlide} from "components/OneImageSlide";
import {uiConstants} from "_constants";
import {contentActions} from "../../_actions";

class ImageCarousel extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        const defaultSpeed = 600;

        const options = {
            //Margin Between Slides. Converts to 'px'
            cellSpacing: window.outerWidth > uiConstants.BREAKPOINT_SM ? 59 : 25,
            // Enable Prev and Next Slide to be shown
            frameOverflow: 'block',
            // Sets width of Center Slide
            width: window.outerWidth > uiConstants.BREAKPOINT_SM ? '86%' : '85%',
            // Transition Speed:
            speed: componentContent && Number.isInteger(componentContent.speed) ? componentContent.speed : defaultSpeed,
            // Transition between slides. Choose from here - https://github.com/d3/d3-ease
            easing: 'easeQuadInOut',
            // First Slide to show will br Middle of array
            slideIndex: componentContent && componentContent.image_carousel_image && Math.ceil(componentContent.image_carousel_image.length / 2),
            // Infinite Loop through slides
            wrapAround: true,
            autoplay: true,
            disableEdgeSwiping: true
        };

        return (

            <section className={`multi-slide-carousel center`}>
                {componentContentReady && componentContent && componentContent.hasOwnProperty('image_carousel_image') ?
                    <Fragment>
                        {componentContent.headline && <h3 className={'section-title'}>{componentContent.headline}</h3>}
                        <Carousel {...options} className={'multi-slide'}
                            /* Disable Paging Dots on Slide Bottom */
                                  renderBottomCenterControls={() => null}
                            /* Change to custom arrow buttons */
                                  renderCenterLeftControls={({previousSlide}) => (
                                      <i onClick={previousSlide} className={'arrow left'}/>
                                  )}
                                  renderCenterRightControls={({nextSlide}) => (
                                      <i onClick={nextSlide} className={'arrow right'}/>
                                  )}
                        >
                            {componentContent.image_carousel_image &&
                            componentContent.image_carousel_image.map((slide, index) => {
                                return (
                                    <OneImageSlide image={slide.image}
                                                   key={index}
                                                   parentComponent={'carousel'}/>
                                )
                            })}
                        </Carousel>
                    </Fragment>
                    :
                    ''
                }
            </section>
        )
    }
}

function mapStateToProps({}) {
    return {}
}

const connectedPage = connect(mapStateToProps)(ImageCarousel);
export {connectedPage as ImageCarousel};
