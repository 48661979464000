import {instagramService} from "_services";
import {tempConstants} from "_constants";

export const tempActions = {
    getInstagramPhotos,
    clearInstagramPhotosPerKey,
};

function getInstagramPhotos(accessToken, numOfPhotos) {
    return (dispatch) => {
        dispatch(request(accessToken));

        let instagramPhotos;

        instagramService.getInstagramPhotos(accessToken, numOfPhotos)
            .then(photos => {
                instagramPhotos = {
                    lowResolution: photos.data.map(item => item.images.low_resolution.url),
                    highResolution: photos.data.map(item => item.images.standard_resolution.url)
                };

                dispatch(success(accessToken ,instagramPhotos))
            })
            .catch(error => {
                dispatch(failure(error))
            })

    };

    function request(accessToken) {
        return {
            type: tempConstants.GET_INSTAGRAM_PHOTOS_REQUEST,
            accessToken
        }
    }

    function success(accessToken, instagramPhotos) {
        return {
            type: tempConstants.GET_INSTAGRAM_PHOTOS_SUCCESS,
            accessToken,
            instagramPhotos
        }
    }

    function failure(error) {
        return {type: tempConstants.GET_INSTAGRAM_PHOTOS_FAILURE, error}
    }
}

function clearInstagramPhotosPerKey(key){
    return {
        type: tempConstants.CLEAR_INSTAGRAM_PHOTOS_PER_KEY, key
    }
}