import React, {Component} from "react";
import {connect} from 'react-redux';
import {converter, generalHelpers} from "_helpers";
import {generalConstants} from "_constants";

class ArticleImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageURL: ''
        }
    }

    componentDidMount() {
        const imageId = this.props.content;

        fetch(`${generalConstants.API_IMAGES_ENDPOINT}${imageId}`)
            .then(response => (
                // Convert the response to a JSON format data
                response.json()
            ))
            .then(data => {
                // Use the JSON format data and set the download_url value into component state
                this.setState({
                    imageURL: converter.getImageDownloadURL(data)
                })
            })
            .catch(error => {
                generalHelpers.print("ArticleImage - Failed Fetch Image:", error)
            })
    }

    render() {
        const {content} = this.props;

        return (
            content ?
                <section className={'article-image-section'}>
                    <div className={'article-image-container'}>
                        <div className='article-image' style={
                            {
                                backgroundImage: `url(${this.state.imageURL})`,
                                // Have a fallback color if the image is not available
                                backgroundColor: '#a4a4a4'
                            }
                        }/>
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({content}) {
    const {components, collectionsIndexes, indexesItems} = content;
    return {
        components,
        collectionsIndexes,
        indexesItems,
    }
}

const connectedPage = connect(mapStateToProps)(ArticleImage);
export {connectedPage as ArticleImage};




