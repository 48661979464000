import React, {Component} from "react";
import {connect} from 'react-redux';

import Carousel from 'nuka-carousel';

import {TwoImagesWithLabel} from "components/TwoImagesWithLabel";
import {OneImageSlide} from "components/OneImageSlide";
import {contentActions} from "../../_actions";

class GalleryCarousel extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        const defaultSpeed = 600;
        let options, SlideComponent;

        if (!componentContent.slides) return '';

        switch (componentContent.type) {
            case 'multi-slide':
                options = {
                    cellSpacing: 50,
                    frameOverflow: 'block',
                    width: '80%',
                    speed: Number.isInteger(componentContent.speed) ? componentContent.speed : defaultSpeed,
                    easing: 'easeQuadInOut',
                    // First Slide to show will br Middle of array
                    slideIndex: Array.isArray(componentContent.slides) && Math.ceil(componentContent.slides.length / 2),
                    wrapAround: true,
                    autoplay: false, //CHANGE to true
                };
                SlideComponent = OneImageSlide;
                break;
            case 'single-slide':
                options = {
                    cellSpacing: 20,
                    frameOverflow: 'hidden',
                    width: '90%',
                    speed: Number.isInteger(componentContent.speed) ? componentContent.speed : defaultSpeed,
                    easing: 'easeQuadInOut',
                    slideIndex: Array.isArray(componentContent.slides) && Math.ceil(componentContent.slides.length / 2),
                    wrapAround: true,
                    autoplay: false,
                    disableEdgeSwiping: true
                };
                SlideComponent = TwoImagesWithLabel;
                break;
            default:
                options = {
                    //Margin Between Slides. Converts to 'px'
                    cellSpacing: 10,
                    // Enable Prev and Next Slide to be shown
                    frameOverflow: 'none',
                    // Sets width of Center Slide
                    width: '100%',
                    // Transition Speed:
                    speed: defaultSpeed,
                    // Transition between slides. Choose from here - https://github.com/d3/d3-ease
                    easing: 'easeLinear',
                    // Slide to show - will be middle of images array
                    slideIndex: 0,
                    // Infinite Loop through slides
                    wrapAround: false,
                    autoplay: false,
                };
        }

        return (
            componentContentReady && componentContent ?
            <section className={`${componentContent.type}-carousel 
                                ${componentContent.type === 'multi-slide' ? 'wide' : 'center'}
                                ${componentContent.backgroundColor ? 'on-background' : 'not-on-background'}`}>
                <div className={'background-color'} style={{backgroundColor: componentContent.backgroundColor}}/>
                <h3 className={componentContent.titleType}>{componentContent.title}</h3>
                <Carousel {...options} className={componentContent.type}
                    /* Disable Paging Dots on Slide Bottom */
                          renderBottomCenterControls={() => null}
                    /* Change to custom arrow buttons */
                          renderCenterLeftControls={({previousSlide}) => (
                              <i onClick={previousSlide} className={'arrow left'}/>
                          )}
                          renderCenterRightControls={({nextSlide}) => (
                              <i onClick={nextSlide} className={'arrow right'}/>
                          )}
                >
                    {Array.isArray(componentContent.image_carousel_image) &&
                        componentContent.image_carousel_image.map((slide, index) => {
                        return (
                            <SlideComponent content={slide}
                                            key={index}
                                            parentComponent={'carousel'}/>
                        )
                    })}
                </Carousel>

                {componentContent.linkText &&
                <div className={'carousel-link'}>
                    <a href={componentContent.link}>{componentContent.linkText}</a>
                </div>}

            </section>
                : ''
        )
    }
}

function mapStateToProps({}) {
    return {

    }
}

const connectedPage = connect(mapStateToProps)(GalleryCarousel);
export {connectedPage as GalleryCarousel};
