import {settingsConstants, defaultConstants, localeOptions} from '_constants'

const INIT_STATE = {
    locale: (localStorage.getItem('currentLanguage') &&
        localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0)
        ? localStorage.getItem('currentLanguage')
        : defaultConstants.defaultLocale,
    defaultLanguage: 'en'
};

export const settings = (state = INIT_STATE, action) => {
    switch (action.type) {
        case settingsConstants.CHANGE_LOCALE:
            return {
                ...state,
                locale: action.languageCode
            };
        default:
            return state;
    }
};