import React, {Component} from "react";
import {connect} from 'react-redux';

import {converter} from "_helpers";

class OneImageSlide extends Component {
    render() {
        const {image} = this.props;
        return (
            <div
                className={'one-image-slide'}
                style={{backgroundImage: `url(${converter.getImageDownloadURL(image)})`}}
            />
        );
    }
}

function mapStateToProps() {
    return {}
}

const connectedPage = connect(mapStateToProps)(OneImageSlide);
export {connectedPage as OneImageSlide};
