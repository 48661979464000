import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import {contentActions} from "../../_actions";

class Paragraph extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        return (
            componentContentReady && componentContent ?
                <Fragment>
                    <section className={'paragraph-section center-narrow'}>
                        <p>{componentContent.text}</p>
                    </section>
                </Fragment>
                : ''
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = connect(mapStateToProps)(Paragraph);
export {connectedPage as Paragraph};
