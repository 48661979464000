import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";

import {Navbar} from "reactstrap";

import {converter as converters} from "_helpers";

import {contentActions, uiActions} from "_actions";
import {contentConstants, defaultConstants} from "_constants";


class Menu extends Component {
    targetElement = null;

    componentDidMount() {
        const {dispatch, permanentId, componentType, componentContentReady} = this.props;

        if (!componentContentReady) {
            dispatch(contentActions.getItemDetailedContent(componentType, permanentId))
        }

        window.addEventListener('keydown', (event) => this.handleKeyEvents(event));

        this.targetElement = document.querySelector('#the-wom-menu')
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', (event) => this.handleKeyEvents(event));
    }

    handleHideMenu = () => {
        this.props.dispatch(uiActions.hideMenu());
    };

    handleKeyEvents = (event) => {
        const {isMenuOpen} = this.props;

        if (isMenuOpen && event.key === 'Escape') {
            this.props.dispatch(uiActions.hideMenu());
        }

    };

    render() {
        const {
            menuContent,
            componentContentReady,
            isMenuOpen,
            match,
            pages,
            collectionsIndexes,
            indexesItems
        } = this.props;

        let hotelIndexesContent = [];

        if (
            componentContentReady
            && menuContent
            && menuContent['hotels_indexes']
            && Array.isArray(menuContent['hotels_indexes'])
            && collectionsIndexes) {
            hotelIndexesContent = menuContent['hotels_indexes'].map(item => {
                    return (pages[item.hotel_index.id])
                }
            );
        }


        return (
            componentContentReady && menuContent ?
                <div id={'the-wom-menu'}
                     className={`menu-container d-flex justify-content-center ${isMenuOpen || 'hidden'}`}>
                    <div className={'menu-background color'}
                         style={{backgroundColor: menuContent[contentConstants.NAVIGATION_PROPERTIES.BACKGROUND_COLOR]}}/>
                    <div className={'menu-background image'} style={{
                        backgroundImage: `linear-gradient(
                                     rgba(0, 0, 0, 0.2) 0%,
                                     rgba(0, 0, 0, 0.2) 100%
                                 ), url(${converters.getImageDownloadURL(menuContent[contentConstants.NAVIGATION_PROPERTIES.BACKGROUND_IMAGE])})`

                    }}/>
                    <div className={`menu-logo`}
                         style={{backgroundImage: `url(${converters.getImageDownloadURL(menuContent[contentConstants.NAVIGATION_PROPERTIES.LOGO])})`}}/>
                    <div className={'menu-content-container'}>
                        <div className={`menu-header d-flex justify-content-between`}>
                            <Navbar className={'menu-navbar'}>
                                <div className={'close-btn'} onClick={this.handleHideMenu}>X</div>
                                {/*Dummy div to keep alignment with flexbox*/}
                                <div/>
                            </Navbar>
                        </div>

                        <div className={'menu-content d-flex justify-content-center align-items-start'}>
                            <div id={'the-wom-menu-pages-links'}
                                 className={'menu-links d-flex flex-column the-wom-scrollbar'}>
                                {menuContent.hasOwnProperty('navigation_links') && menuContent.navigation_links.map((link, index) => {
                                    return (
                                        pages.hasOwnProperty(link[contentConstants.NAVIGATION_PROPERTIES.NAVIGATION_LINK_LINK].id) ?
                                            <Fragment key={index}>
                                                <Link
                                                    to={`${match.url}/${pages[link[contentConstants.NAVIGATION_PROPERTIES.NAVIGATION_LINK_LINK].id].meta.slug}`}
                                                    className={'route-link'}
                                                    onClick={this.handleHideMenu}>
                                                    {link.headline}
                                                </Link>
                                            </Fragment>
                                            : ''
                                    )
                                })}
                                <div className={'social-links d-flex justify-content-center align-items-center'}>
                                    {menuContent.hasOwnProperty('social_links') && menuContent.social_links.map((link, index) => {
                                        return (
                                            <a
                                                href={link[contentConstants.NAVIGATION_PROPERTIES.SOCIAL_LINK_LINK]}
                                                target={'_blank'}
                                                key={index}>
                                                <div
                                                    className={'social-icon'}
                                                    style={{
                                                        backgroundImage: `url(${converters.getImageDownloadURL(link[contentConstants.NAVIGATION_PROPERTIES.SOCIAL_LINK_ICON])})`
                                                    }}/>
                                            </a>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={'locations d-flex flex-column justify-content-start the-wom-scrollbar'}>
                                <h4 className={'locations-title'}>Locations</h4>
                                {(hotelIndexesContent) && hotelIndexesContent.map(hotelsIndexPage => {
                                    if (!hotelsIndexPage) return '';

                                    const hotelsIndex = hotelsIndexPage.pages_index && collectionsIndexes[hotelsIndexPage.pages_index.id];

                                    if (hotelsIndex && hotelsIndex[contentConstants.SORT_TYPE_ITEMS]) {
                                        return hotelsIndex[contentConstants.SORT_TYPE_ITEMS].map(link => {
                                            if (indexesItems[link.id]) {
                                                return (<Link
                                                    to={`${defaultConstants.defaultStartPath}${match.params.lang}/${hotelsIndexPage.meta.slug}/${indexesItems[link.id].meta.slug}`}
                                                    key={indexesItems[link.id].meta.slug}
                                                    className={'location-link'}
                                                    onClick={this.handleHideMenu}>{indexesItems[link.id].link_title || indexesItems[link.id].title}</Link>)
                                            } else {
                                                return '';
                                            }

                                        })
                                    } else {
                                        return '';
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                : ''
        )
    }
}

const mapStateToProps = ({ui, content}) => {
    const {isMenuOpen} = ui;
    const {pages, collectionsIndexes, indexesItems} = content;
    return {
        isMenuOpen,
        pages,
        collectionsIndexes,
        indexesItems,
    };
};

const connectedPage = withRouter(connect(mapStateToProps)(Menu));
export {connectedPage as Menu};
