import React, {Component} from "react";
import {connect} from 'react-redux';
import {contentActions} from "../../_actions";

class HeaderTwoParagraphs extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady, contentFromParent} = this.props;

        if(!componentContentReady && !contentFromParent){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent, contentFromParent} = this.props;

        return (
            (componentContentReady || contentFromParent) && componentContent ?
                <section
                    className={'header-two-paragraphs-section header-wide header d-flex flex-column justify-content-start align-items-center'}>
                    <div className={'header-content d-flex flex-wrap justify-content-center align-items-start'}
                         style={{backgroundColor: componentContent.background_color}}>
                        <div className={'two-paragraphs-header-headline d-flex align-items-center justify-content-center'}>
                            <h2 className={'section-title'}>{componentContent.headline}</h2>
                        </div>
                        <div className={'header-paragraph-container d-flex flex-wrap justify-content-between'}>
                            <div className={'header-paragraph paragraph'}>
                                {componentContent.header_text}
                            </div>
                        </div>
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({}) {

    return {

    }
}

const connectedPage = connect(mapStateToProps)(HeaderTwoParagraphs);
export {connectedPage as HeaderTwoParagraphs};




