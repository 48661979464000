export const converter = {
    toRGBColor,
    getImageDownloadURL,
    getImageDownloadURLTest,
    getVideoURL
};

function toRGBColor(colorString) {
    const matchColors = /(\d{1,3}), (\d{1,3}), (\d{1,3})/;

    if (matchColors.exec(colorString)) {
        return colorString;
    } else {
        return hexToRGBColor(colorString)
    }
}

function hexToRGBColor(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    if (hex) {
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
            : null;
    } else {
        // If there is no color value transferred return this grey color
        return '150, 150, 150';
    }
}

function getImageDownloadURL(CMSImageObject) {
    switch (process.env.NODE_ENV) {
        case 'development':
            if (CMSImageObject) {
                return `${process.env.REACT_APP_THEWOM_API_ENDPOINT}${CMSImageObject.meta.download_url}`;
            } else {
                return 'unavailable';
            }
        default:
            if (CMSImageObject) {
                return `${process.env.REACT_APP_THEWOM_API_ENDPOINT}${CMSImageObject.meta.download_url}`;
            } else {
                return 'unavailable';
            }
    }
}

function getImageDownloadURLTest(CMSImageObject) {
    switch (process.env.NODE_ENV) {
        case 'development':
            return `${process.env.REACT_APP_THEWOM_API_ENDPOINT}${CMSImageObject.url}`;
        default:
            return CMSImageObject.url;

    }
}

// Parser for streaming sites URLs
function getVideoURL(url) {
    let videoId;

    switch (true) {
        case url.includes('youtube'):
            videoId = parseVideoId(url, 'watch?v=');
            return `https://www.youtube.com/embed/${videoId}?autoplay=1&loop=1&fs=0&disablekb=1&controls=0&iv_load_policy=3&modestbranding=1`;
        case url.includes('vimeo'):
            videoId = parseVideoId(url, '.com/');
            return `https://player.vimeo.com/video/${videoId}`;
        case url.includes('dai.ly'):
            videoId = parseVideoId(url, '.ly/');
            return `https://www.dailymotion.com/embed/video/${videoId}?autoplay=1&mute=1&controls=0&queue-autoplay-next=0&queue-enable=0&sharing-enable=0&ui-highlight=f8e05d&ui-logo=0&ui-start-screen-info=0`;
        default:
            return ''
    }
}

function parseVideoId(url, lookBeforeIdPrefix) {
    // get only video id from url
    return url.slice(url.indexOf(lookBeforeIdPrefix) + lookBeforeIdPrefix.length);

}