import * as noScroll from "no-scroll";

import {uiConstants} from '_constants';

export const uiActions = {
    showMenu,
    hideMenu,
    showContactModal,
    hideContactModal,
    updateLogoStyle,
    updateTopNavStyle,
    updateTopNavTextColor,
    alertSuccess,
    alertError,
    alertClear,
    alertLoader,
    pastScrollBreakPoint,
    beforeScrollBreakPoint,
    showElement,
    hideElement
};


function showMenu() {
    noScroll.on();
    document.getElementById('the-wom-menu-pages-links').scrollTop = 0;
    return {
        type: uiConstants.SHOW_MENU
    }
}

function hideMenu() {
    noScroll.off();
    return {
        type: uiConstants.HIDE_MENU
    }
}

function updateLogoStyle(logoStyleHeaderHome){
    return {
        type: uiConstants.UPDATE_LOGO_STYLE,
        logoStyleHeaderHome
    }
}

function updateTopNavStyle(topNavStyle){
    return {
        type: uiConstants.UPDATE_TOP_NAV_STYLE,
        topNavStyle
    }
}

function updateTopNavTextColor(rgb){
    return {
        type: uiConstants.UPDATE_TOP_NAV_TEXT_COLOR,
        rgb
    }
}

function showContactModal(sendToMail) {
    noScroll.on();
    return {
        type: uiConstants.SHOW_CONTACT_MODAL,
        sendToMail
    }
}

function hideContactModal() {
    return dispatch => {
        dispatch(hideElement(uiConstants.CONTACT_US_LOADER));
        dispatch(hideElement(uiConstants.CONTACT_US_FAILURE));
        dispatch(hideElement(uiConstants.CONTACT_US_SUCCESS));

        noScroll.off();

        dispatch(hideModal())
    };

    function hideModal(){
        return {
            type: uiConstants.HIDE_CONTACT_MODAL
        }
    }
}

function alertSuccess(message, category='') {
    return {type: uiConstants.ALERT_SUCCESS, message, category};
}

function alertError(message, category='') {
    return {type: uiConstants.ALERT_ERROR, message, category};
}

function alertClear(category='') {
    return {type: uiConstants.ALERT_CLEAR, category};
}

function alertLoader(category='') {
    return {type: uiConstants.ALERT_LOADER, category};
}

function pastScrollBreakPoint(){
    return {type: uiConstants.PAST_SCROLL_BREAK_POINT};
}

function beforeScrollBreakPoint(){
    return {type: uiConstants.BEFORE_SCROLL_BREAK_POINT};
}

function showElement(elementId){
    return {
        type: uiConstants.SHOW_ELEMENT,
        elementId
    }
}

function hideElement(elementId){
    return {
        type: uiConstants.HIDE_ELEMENT,
        elementId
    }
}
