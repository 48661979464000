import React, {Component} from "react";
import {connect} from 'react-redux';
import {MapsContainer} from "components";
import {converter} from "_helpers";
import {uiConstants} from "_constants";
import {contentActions} from "../../_actions";

class MapWithLabelWide extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        return (
            componentContentReady && componentContent ?
                <section className={'map-with-label-wide d-flex justify-content-center align-items-center wide'}>
                    <div className='map-container'>
                        <MapsContainer
                            className={'map'}
                            iconURL={componentContent.iconURL}
                            centerOffset={window.outerWidth >= uiConstants.BREAKPOINT_SM ? 0.00015 : 0.00030}
                            location={{latitude: componentContent.lat, longitude: componentContent.lng}}/>
                    </div>

                    <div className={'label-container'}>
                        <div className={'label d-flex flex-column justify-content-around align-items-center'}>
                            <div className='logo'
                                 style={{backgroundImage: `url(${converter.getImageDownloadURL(componentContent.logo)})`}}/>
                            {
                                componentContent.address_link
                                    ? <a className={'address'} href={componentContent.address_link}
                                         target={'_blank'}
                                         dangerouslySetInnerHTML={{__html: componentContent.address}}/>
                                    : <div className={'address'} dangerouslySetInnerHTML={{__html: componentContent.address}}/>
                            }
                            <div className={'phone-mail'}>
                                <p><a href={`tel:${componentContent.phone_number}`}>{componentContent.phone_number}</a></p>
                                <p><a href={`mailto: ${componentContent.email_address}`}>{componentContent.email_address}</a></p>
                            </div>
                        </div>
                    </div>
                </section>
                : ''
        );
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = connect(mapStateToProps)(MapWithLabelWide);
export {connectedPage as MapWithLabelWide};
