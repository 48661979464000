import React, {Component} from "react";
import {connect} from 'react-redux';

import Carousel from 'nuka-carousel';

import {HotelPublicPlaces} from "components";
import {contentActions} from "../../_actions";

class HotelPublicPlacesCarousel extends Component {
    componentDidMount(){
        const {dispatch, componentId, componentType, componentContentReady} = this.props;

        if(!componentContentReady){
            dispatch(contentActions.getItemDetailedContent(componentType, componentId))
        }
    }

    render() {
        const {componentContentReady, componentContent} = this.props;

        const defaultSpeed = 600;

        const options = {
            //Margin Between Slides. Converts to 'px'
            cellSpacing: 5,
            // Enable Prev and Next Slide to be shown
            frameOverflow: 'hidden',
            // Sets width of Center Slide
            width: '86%',
            // Transition Speed:
            speed: componentContent && Number.isInteger(componentContent.speed) ? componentContent.speed : defaultSpeed,
            // Transition between slides. Choose from here - https://github.com/d3/d3-ease
            easing: 'easeQuadInOut',
            // Slide to show - will be middle of images array
            slideIndex:
                componentContent &&
                componentContent.hasOwnProperty('hotel_public_places') &&
                Array.isArray(componentContent['hotel_public_places']) &&
                Math.ceil(componentContent['hotel_public_places'].length / 2),
            // Infinite Loop through slides
            wrapAround: true,
            autoplay: false,
        };


        return (
            componentContentReady && componentContent ?
                <section id={componentContent.id} className='hotel-public-places-carousel-container center'>
                    <Carousel {...options} className='hotel-public-places-carousel'
                        /* Disable Paging Dots on Slide Bottom */
                              renderBottomCenterControls={() => null}
                        /* Change to custom arrow buttons */
                              renderCenterLeftControls={({previousSlide}) => (
                                  <i onClick={previousSlide} className={'arrow left'}/>
                              )}
                              renderCenterRightControls={({nextSlide}) => (
                                  <i onClick={nextSlide} className={'arrow right'}/>
                              )}
                    >
                        {
                            componentContent.hasOwnProperty('hotel_public_places') &&
                            Array.isArray(componentContent['hotel_public_places']) &&
                            componentContent['hotel_public_places'].map( slide => {
                            return (
                                <HotelPublicPlaces content={slide} key={slide.id}/>
                            )
                        })}
                    </Carousel>
                </section>
                : ''
        )
    }
}

function mapStateToProps({}) {
    return {
    }
}

const connectedPage = connect(mapStateToProps)(HotelPublicPlacesCarousel);
export {connectedPage as HotelPublicPlacesCarousel};
