import React, {Component} from "react";
import {connect} from 'react-redux';

class IFrameSection extends Component {
    render() {
        const {componentId, components} = this.props;

        const componentContent = components[componentId];


        return (
            componentContent
                ? <section>
                    {/*<iframe src={componentContent.url} style={{width: '100%', height: '100%'}}/>*/}
                </section>
                : ''
        );
    }
}

function mapStateToProps({content, instagram}) {
    const {components} = content;
    const {lowResolution, highResolution} = instagram;
    return {
        components,
        lowResolution,
        highResolution
    }
}

const connectedPage = connect(mapStateToProps)(IFrameSection);
export {connectedPage as IFrameSection};
