import React, {Component} from "react";
import {connect} from 'react-redux';

import {converter as converters} from "_helpers";

class HotelPublicPlaces extends Component {
    render() {
        const {content} = this.props;

        return (
            content ?
                <div className='hotel-public-places'>
                    <div className='background-image'
                         style={{backgroundImage: `url(${converters.getImageDownloadURL(content.background_image)})`}}/>
                    <div className={'slide-button d-flex justify-content-center'}>
                        {content['button_label'] && content['button_label']
                            ?
                            <a href={content['url']}
                               target='_blank'
                               rel='noopener noreferrer'
                               className={'the-wom-btn dark-button book-btn'}>
                                {content['button_label']}
                            </a>
                            :
                            ''
                        }
                    </div>
                </div>
                :
                ''
        );
    }
}

function mapStateToProps() {
    return {}
}

const connectedPage = connect(mapStateToProps)(HotelPublicPlaces);
export {connectedPage as HotelPublicPlaces};
